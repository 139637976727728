import { NavLink, useLocation } from "react-router-dom";
import "../../css/footer.css";
import React from "react";
import DownloadPanelBook from "../popupbox/DownloadPanelBook";

const Footer = () => {
  const [data, setData] = React.useState("");
  const { pathname } = useLocation();

  return (
    <>
      {!(pathname.includes("page1") || pathname.includes("page2")) && (
        <footer id="pxl-footer-elementor">
          <div className="footer-elementor-inner">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="2782"
                    className="elementor elementor-2782"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-2e2859d elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                      data-id="2e2859d"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched","background_background":"classNameic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e73c4 pxl-column-none"
                          data-id="82e73c4"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-5fdb8f7 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                              data-id="5fdb8f7"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f3a7dbc pxl-column-none"
                                  data-id="f3a7dbc"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-812e97f elementor-widget elementor-widget-pxl_logo"
                                      data-id="812e97f"
                                      data-element_type="widget"
                                      data-widget_type="pxl_logo.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          className="pxl-logo"
                                          data-wow-delay="ms"
                                        >
                                          <a href="/">
                                            {/* <img
                                              width="666"
                                              height="114"
                                              src="assets/wp-content/uploads/2023/03/h2-logo.png"
                                              className="attachment-full"
                                              alt=""
                                            /> */}
                                            <p>
                                              <span className="footer-logo-first-text">
                                                CALC
                                              </span>
                                              <span className="footer-logo-last-text">
                                                INSIGHTS
                                              </span>
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-b826937 elementor-widget elementor-widget-pxl_text_editor"
                                      data-id="b826937"
                                      data-element_type="widget"
                                      data-widget_type="pxl_text_editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="pxl-text-editor">
                                          <div
                                            className="pxl-item--inner"
                                            data-wow-delay="ms"
                                          >
                                            Calc Insights assists you in hiring
                                            Expert as a Service if you are a
                                            funded Startup or Company wanting to
                                            establish or grow your
                                            Product/Service.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-82627b7 elementor-widget elementor-widget-pxl_mailchimp"
                                      data-id="82627b7"
                                      data-element_type="widget"
                                      data-widget_type="pxl_mailchimp.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="pxl-mailchimp pxl-mailchimp-l1 style-icon">
                                          <DownloadPanelBook />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-07ef676 pxl-column-none"
                                  data-id="07ef676"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-c8b4af8 elementor-widget elementor-widget-pxl_heading"
                                      data-id="c8b4af8"
                                      data-element_type="widget"
                                      data-widget_type="pxl_heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="pxl-pxl_heading-c8b4af8-9827"
                                          className="pxl-heading px-sub-title-default-style"
                                        >
                                          <div className="pxl-heading--inner">
                                            <h3
                                              className="pxl-item--title"
                                              data-wow-delay="ms"
                                            >
                                              Useful Links
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-17968be elementor-widget__width-initial elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                        data-id="17968be"
                                        data-element_type="widget"
                                        data-widget_type="divider.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            {/* <span className="elementor-divider-separator"></span> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-0d63430 elementor-widget elementor-widget-pxl_link"
                                        data-id="0d63430"
                                        data-element_type="widget"
                                        data-widget_type="pxl_link.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <ul
                                            id="pxl-link-pxl_link-0d63430-4720"
                                            className="pxl-link pxl-link-l1 style-default type-vertical"
                                            data-wow-delay="ms"
                                          >
                                            <li>
                                              <NavLink to="/about-us">
                                                <span>About Us</span>
                                              </NavLink>
                                            </li>
                                            <li>
                                              <NavLink to="/service">
                                                <span>Services</span>
                                              </NavLink>
                                            </li>
                                            <li>
                                              <NavLink to="/contact-us">
                                                <span>Contact Us</span>
                                              </NavLink>
                                            </li>
                                            <li></li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-ea1c2df pxl-column-none"
                                  data-id="ea1c2df"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-572f32b elementor-widget elementor-widget-pxl_heading"
                                      data-id="572f32b"
                                      data-element_type="widget"
                                      data-widget_type="pxl_heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="pxl-pxl_heading-572f32b-4479"
                                          className="pxl-heading px-sub-title-default-style"
                                        >
                                          <div className="pxl-heading--inner">
                                            <h3
                                              className="pxl-item--title"
                                              data-wow-delay="ms"
                                            >
                                              Sub Pages
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-67c5b6f elementor-widget__width-initial elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="67c5b6f"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          {/* <span className="elementor-divider-separator"></span> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-77bd5f1 elementor-widget elementor-widget-pxl_link"
                                      data-id="77bd5f1"
                                      data-element_type="widget"
                                      data-widget_type="pxl_link.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <ul
                                          id="pxl-link-pxl_link-77bd5f1-8225"
                                          className="pxl-link pxl-link-l1 style-default type-vertical"
                                          data-wow-delay="ms"
                                        >
                                          <li>
                                            <a href="#">
                                              <span>Conditions</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/gdpr">
                                              <span>GDPR Policy </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/cookie">
                                              <span>Cookie Policy</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/privacy">
                                              <span>Privacy & Policy</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/terms">
                                              <span>Terms of Use</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-8bb83f1 pxl-column-none"
                                  data-id="8bb83f1"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-8ac25d3 elementor-widget elementor-widget-pxl_heading"
                                      data-id="8ac25d3"
                                      data-element_type="widget"
                                      data-widget_type="pxl_heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="pxl-pxl_heading-8ac25d3-6182"
                                          className="pxl-heading px-sub-title-default-style"
                                        >
                                          <div className="pxl-heading--inner">
                                            <h3
                                              className="pxl-item--title"
                                              data-wow-delay="ms"
                                            >
                                              Contact Us
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-71d1c0c elementor-widget__width-initial elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="71d1c0c"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          {/* <span className="elementor-divider-separator"></span> */}
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div
                                      className="elementor-element elementor-element-8e28388 elementor-widget elementor-widget-pxl_link"
                                      data-id="8e28388"
                                      data-element_type="widget"
                                      data-widget_type="pxl_link.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <ul
                                          id="pxl-link-pxl_link-8e28388-2022"
                                          className="pxl-link pxl-link-l1 style-default type-vertical"
                                          data-wow-delay="ms"
                                        >
                                          <li>
                                            <a href="tel:+98765432122811">
                                              <i
                                                aria-hidden="true"
                                                className="flaticon flaticon-phone-call-button"
                                              ></i>
                                              <span>(+91) 701 481 5105</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div> */}
                                    <div
                                      className="elementor-element elementor-element-8e2a7b7 elementor-widget elementor-widget-pxl_link"
                                      data-id="8e2a7b7"
                                      data-element_type="widget"
                                      data-widget_type="pxl_link.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <ul
                                          id="pxl-link-pxl_link-8e2a7b7-7367"
                                          className="pxl-link pxl-link-l1 style-default type-vertical"
                                          data-wow-delay="ms"
                                        >
                                          <li>
                                            <a href="mailto:contact@jackcerra.com">
                                              <i
                                                aria-hidden="true"
                                                className="flaticon flaticon-mail"
                                              ></i>
                                              <span>info@calcinsights.com</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6876156 elementor-widget elementor-widget-pxl_link"
                                      data-id="6876156"
                                      data-element_type="widget"
                                      data-widget_type="pxl_link.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <ul
                                          id="pxl-link-pxl_link-6876156-5442"
                                          className="pxl-link pxl-link-l1 style-default type-vertical"
                                          data-wow-delay="ms"
                                        >
                                          <li>
                                            <a href="tel:+98765432122811">
                                              <i
                                                aria-hidden="true"
                                                className="flaticon flaticon-maps-and-flags"
                                              ></i>
                                              <span>New Delhi</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <div
                              className="elementor-element elementor-element-21fc49f elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                              data-id="21fc49f"
                              data-element_type="widget"
                              data-widget_type="divider.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-divider">
                                  {/* <span className="elementor-divider-separator"></span> */}
                                </div>
                              </div>
                            </div>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-8f2a171 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                              data-id="8f2a171"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-74a0937 pxl-column-none"
                                  data-id="74a0937"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-7c51a12 elementor-widget elementor-widget-pxl_text_editor"
                                      data-id="7c51a12"
                                      data-element_type="widget"
                                      data-widget_type="pxl_text_editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="pxl-text-editor">
                                          <div
                                            className="pxl-item--inner"
                                            data-wow-delay="ms"
                                          >
                                            <span>2023</span>
                                            <a
                                              className="elementor-repeater-item-a3d1055 ps-top"
                                              href="https://www.facebook.com/profile.php?id=100093279026469"
                                            >
                                              {" "}
                                              Calc Insights
                                            </a>
                                            <span>. All Rights Reserved</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-ab30e2d elementor-widget__width-auto elementor-widget elementor-widget-pxl_language_switcher"
                                        data-id="ab30e2d"
                                        data-element_type="widget"
                                        data-widget_type="pxl_language_switcher.default"
                                      >
                                        {/* <div className="elementor-widget-container">
                                        <div className="pxl-language-switcher1 dr-top-right">
                                          <div className="current--item">
                                            <label>English (US)</label>
                                          </div>
                                          <ul>
                                            <li>
                                              <a href="#"> French (FR) </a>
                                            </li>
                                            <li>
                                              <a href="#"> German (DE) </a>
                                            </li>
                                            <li>
                                              <a href="#"> Spanish (ES) </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c58613b pxl-column-none"
                                  data-id="c58613b"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-39b9304 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-pxl_icon"
                                      data-id="39b9304"
                                      data-element_type="widget"
                                      data-widget_type="pxl_icon.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          className="pxl-icon-list pxl-icon1 style-box1"
                                          data-wow-delay="ms"
                                        >
                                          <NavLink
                                            className="elementor-repeater-item-a3d1055 ps-top"
                                            to="https://www.facebook.com/profile.php?id=100093279026469"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="fab fa-facebook-f"
                                            ></i>
                                            <span>Facebook</span>
                                          </NavLink>
                                          <NavLink
                                            className="elementor-repeater-item-66113f6 ps-top"
                                            to="https://www.instagram.com/calcinsights/"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="fab fa-instagram"
                                            ></i>
                                            <span>instagram</span>
                                          </NavLink>
                                          <NavLink
                                            className="elementor-repeater-item-331ef8e ps-top"
                                            to="https://twitter.com/CalcInsights"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="fab fa-twitter"
                                            ></i>
                                            <span>Twitter</span>
                                          </NavLink>
                                          <NavLink
                                            className="elementor-repeater-item-a083f14 ps-top"
                                            to="https://www.linkedin.com/company/calc-insights/"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="fab fa-linkedin-in"
                                            ></i>
                                            <span>Linkedin</span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ab30e2d elementor-widget__width-auto elementor-widget elementor-widget-pxl_language_switcher"
                                      data-id="ab30e2d"
                                      data-element_type="widget"
                                      data-widget_type="pxl_language_switcher.default"
                                    >
                                      {/* <div className="elementor-widget-container">
                                      <div className="pxl-language-switcher1 dr-top-right">
                                        <div className="current--item">
                                          <label>English (US)</label>
                                        </div>
                                        <ul>
                                          <li>
                                            <a href="#"> French (FR) </a>
                                          </li>
                                          <li>
                                            <a href="#"> German (DE) </a>
                                          </li>
                                          <li>
                                            <a href="#"> Spanish (ES) </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
