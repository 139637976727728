
import React from 'react'

function Service() {
  return (
    <div
      className="page-template-default page page-id-81 theme-jackcerra woocommerce-no-js pxl-redux-page body-default-font heading-default-font bd-px-header--default site-color-gradient woocommerce-layout-grid elementor-default elementor-kit-6 elementor-page elementor-page-81"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-dark-grayscale">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 0.49803921568627" />
              <feFuncG type="table" tableValues="0 0.49803921568627" />
              <feFuncB type="table" tableValues="0 0.49803921568627" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-grayscale">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 1" />
              <feFuncG type="table" tableValues="0 1" />
              <feFuncB type="table" tableValues="0 1" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-purple-yellow">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR
                type="table"
                tableValues="0.54901960784314 0.98823529411765"
              />
              <feFuncG type="table" tableValues="0 1" />
              <feFuncB
                type="table"
                tableValues="0.71764705882353 0.25490196078431"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-blue-red">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 1" />
              <feFuncG type="table" tableValues="0 0.27843137254902" />
              <feFuncB
                type="table"
                tableValues="0.5921568627451 0.27843137254902"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-midnight">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 0" />
              <feFuncG type="table" tableValues="0 0.64705882352941" />
              <feFuncB type="table" tableValues="0 1" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-magenta-yellow">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0.78039215686275 1" />
              <feFuncG type="table" tableValues="0 0.94901960784314" />
              <feFuncB
                type="table"
                tableValues="0.35294117647059 0.47058823529412"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-purple-green">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR
                type="table"
                tableValues="0.65098039215686 0.40392156862745"
              />
              <feFuncG type="table" tableValues="0 1" />
              <feFuncB type="table" tableValues="0.44705882352941 0.4" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-blue-orange">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0.098039215686275 1" />
              <feFuncG type="table" tableValues="0 0.66274509803922" />
              <feFuncB
                type="table"
                tableValues="0.84705882352941 0.41960784313725"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs>
      </svg>
      <div id="pxl-wapper" className="pxl-wapper">
        {/* <div
        id="pxl-loadding"
        className="pxl-loader pxl-loader-gradient style-default"
      >
        <div className="pxl-loader-effect">
          <div className="pxl-circle-1"></div>
          <div className="pxl-circle-2"></div>
        </div>
      </div> */}
        {/* <header id="pxl-header-elementor" className="is-sticky">
        <div className="pxl-header-elementor-main px-header--default">
          <div className="pxl-header-content">
            <div className="row">
              <div className="col-12">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id="88"
                  className="elementor elementor-88"
                >
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-61b2df2 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                    data-id="61b2df2"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                  >
                    <div
                      className="elementor-container elementor-column-gap-default"
                    >
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5f261da pxl-column-none"
                        data-id="5f261da"
                        data-element_type="column"
                      >
                        <div
                          className="elementor-widget-wrap elementor-element-populated"
                        >
                          <div
                            className="elementor-element elementor-element-d18e86c elementor-widget__width-auto pxl-flex-grow elementor-widget elementor-widget-pxl_icon"
                            data-id="d18e86c"
                            data-element_type="widget"
                            data-widget_type="pxl_icon.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="pxl-icon-list pxl-icon1 style-default"
                                data-wow-delay="ms"
                              >
                                <a
                                  className="elementor-repeater-item-923dd4c ps-top"
                                  href="#"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-facebook-f"
                                  ></i>
                                </a>
                                <a
                                  className="elementor-repeater-item-ab0a4b9 ps-top"
                                  href="%24.html"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-behance"
                                  ></i>
                                </a>
                                <a
                                  className="elementor-repeater-item-7e3ed51 ps-top"
                                  href="#"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-twitter"
                                  ></i>
                                </a>
                                <a
                                  className="elementor-repeater-item-07a1746 ps-top"
                                  href="#"
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-linkedin-in"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-96efc15 elementor-widget__width-auto elementor-widget elementor-widget-pxl_link"
                            data-id="96efc15"
                            data-element_type="widget"
                            data-widget_type="pxl_link.default"
                          >
                            <div className="elementor-widget-container">
                              <ul
                                id="pxl-link-pxl_link-96efc15-2207"
                                className="pxl-link pxl-link-l1 style-default type-vertical"
                                data-wow-delay="ms"
                              >
                                <li>
                                  <a href="mailto:contact@jackcerra.com">
                                    <i
                                      aria-hidden="true"
                                      className="flaticon flaticon-mail"
                                    ></i>
                                    <span>contact@jackcerra.com</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-8b18ddb elementor-widget__width-auto elementor-widget elementor-widget-pxl_link"
                            data-id="8b18ddb"
                            data-element_type="widget"
                            data-widget_type="pxl_link.default"
                          >
                            <div className="elementor-widget-container">
                              <ul
                                id="pxl-link-pxl_link-8b18ddb-5104"
                                className="pxl-link pxl-link-l1 style-default type-vertical"
                                data-wow-delay="ms"
                              >
                                <li>
                                  <a href="#">
                                    <i
                                      aria-hidden="true"
                                      className="fas fa-map-marker-alt"
                                    ></i>
                                    <span>231 Madison Street, NewYork,USA</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-0077d87 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                    data-id="0077d87"
                    data-element_type="section"
                    data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                  >
                    <div
                      className="elementor-container elementor-column-gap-extended"
                    >
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3d24db8 pxl-column-none"
                        data-id="3d24db8"
                        data-element_type="column"
                      >
                        <div
                          className="elementor-widget-wrap elementor-element-populated"
                        >
                          <div
                            className="elementor-element elementor-element-42c5304 elementor-widget__width-auto pxl-flex-grow elementor-widget elementor-widget-pxl_logo"
                            data-id="42c5304"
                            data-element_type="widget"
                            data-widget_type="pxl_logo.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="pxl-logo" data-wow-delay="ms">
                                <a href="../index.html">
                                  <img
                                    width="666"
                                    height="114"
                                    src="../wp-content/uploads/2023/03/logo-dark.png"
                                    className="attachment-full"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-7e424a7 elementor-widget__width-auto elementor-widget elementor-widget-pxl_menu"
                            data-id="7e424a7"
                            data-element_type="widget"
                            data-widget_type="pxl_menu.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="pxl-nav-menu pxl-nav-menu1 pxl-mega-full-width fr-style-default show-effect-slidedown sub-style-default"
                              >
                                <div className="menu-main-menu-container">
                                  <ul
                                    id="menu-main-menu"
                                    className="pxl-menu-primary clearfix"
                                  >
                                    <li
                                      id="menu-item-33"
                                      className="menu-item menu-item-type-custom menu-item-object-custom pxl-megamenu menu-item-has-children menu-item-33"
                                    >
                                      <a href="#"
                                        ><span
                                          >Home<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <div className="sub-menu pxl-mega-menu">
                                        <div className="pxl-mega-menu-elementor">
                                          <div
                                            data-elementor-type="wp-post"
                                            data-elementor-id="202"
                                            className="elementor elementor-202"
                                          >
                                            <section
                                              className="elementor-section elementor-top-section elementor-element elementor-element-40b4409 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                              data-id="40b4409"
                                              data-element_type="section"
                                            >
                                              <div
                                                className="elementor-container elementor-column-gap-extended"
                                              >
                                                <div
                                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6586e8b pxl-column-none"
                                                  data-id="6586e8b"
                                                  data-element_type="column"
                                                >
                                                  <div
                                                    className="elementor-widget-wrap elementor-element-populated"
                                                  >
                                                    <div
                                                      className="elementor-element elementor-element-33be938 elementor-widget elementor-widget-pxl_showcase_grid"
                                                      data-id="33be938"
                                                      data-element_type="widget"
                                                      data-widget_type="pxl_showcase_grid.default"
                                                    >
                                                      <div
                                                        className="elementor-widget-container"
                                                      >
                                                        <div
                                                          className="pxl-grid pxl-showcase-grid pxl-showcase-grid1"
                                                        >
                                                          <div
                                                            className="pxl-grid-inner pxl-grid-masonry row"
                                                            data-gutter="15"
                                                          >
                                                            <div
                                                              className="grid-sizer col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            ></div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-01.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../index.html"
                                                                    >Consultancy</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-02.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../home-2/index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../home-2/index.html"
                                                                    >Business</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-03.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../home-3/index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../home-3/index.html"
                                                                    >Investment</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-04.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../home-4/index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../home-4/index.html"
                                                                    >Insurance</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      id="menu-item-61"
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61"
                                    >
                                      <a href="#"
                                        ><span
                                          >Pages<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          id="menu-item-67"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-67"
                                        >
                                          <a href="../about-us/index.html"
                                            ><span
                                              >About Us<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-70"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-70"
                                        >
                                          <a href="../our-team/index.html"
                                            ><span
                                              >Our Team<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-1422"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1422"
                                        >
                                          <a href="../team-details/index.html"
                                            ><span
                                              >Team Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-64"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64"
                                        >
                                          <a href="../contact-us/index.html"
                                            ><span
                                              >Contact Us<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-69"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-69"
                                        >
                                          <a href="../testimonials/index.html"
                                            ><span
                                              >Testimonials<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-71"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-71"
                                        >
                                          <a href="../pricing-plan/index.html"
                                            ><span
                                              >Pricing Plan<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-68"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-68"
                                        >
                                          <a href="../coming-soon/index.html"
                                            ><span
                                              >Coming Soon<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-72"
                                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-72"
                                        >
                                          <a href="../404-error.html"
                                            ><span
                                              >404 Error<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-66"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-66"
                                        >
                                          <a href="../faq/index.html"
                                            ><span
                                              >FAQ<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-5413"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5413"
                                        >
                                          <a href="../landing/index.html"
                                            ><span
                                              >Landing<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      id="menu-item-62"
                                      className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-62"
                                    >
                                      <a href="#"
                                        ><span
                                          >Services<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          id="menu-item-78"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-78"
                                        >
                                          <a href="../services-1/index.html"
                                            ><span
                                              >Our Services 1<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-85"
                                          className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-81 current_page_item menu-item-85"
                                        >
                                          <a
                                            href="index.html"
                                            aria-current="page"
                                            ><span
                                              >Our Services 2<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-907"
                                          className="menu-item menu-item-type-post_type menu-item-object-service menu-item-907"
                                        >
                                          <a
                                            href="../service/market-research/index.html"
                                            ><span
                                              >Service Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      id="menu-item-63"
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-63"
                                    >
                                      <a href="#"
                                        ><span
                                          >Portfolio<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          id="menu-item-79"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-79"
                                        >
                                          <a href="../portfolio-grid/index.html"
                                            ><span
                                              >Portfolio Grid<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              id="menu-item-396"
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-396"
                                            >
                                              <a
                                                href="../portfolio-grid-2-columns/index.html"
                                                ><span
                                                  >2 Columns<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-390"
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"
                                            >
                                              <a
                                                href="../portfolio-grid/index.html"
                                                ><span
                                                  >3 Columns<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-395"
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-395"
                                            >
                                              <a
                                                href="../portfolio-grid-4-columns/index.html"
                                                ><span
                                                  >4 Columns<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          id="menu-item-389"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-389"
                                        >
                                          <a
                                            href="../portfolio-masonry/index.html"
                                            ><span
                                              >Portfolio Masonry<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-388"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-388"
                                        >
                                          <a
                                            href="../portfolio-style-1/index.html"
                                            ><span
                                              >Portfolio Style 1<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-387"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-387"
                                        >
                                          <a
                                            href="../portfolio-style-2/index.html"
                                            ><span
                                              >Portfolio Style 2<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-476"
                                          className="menu-item menu-item-type-post_type menu-item-object-portfolio menu-item-476"
                                        >
                                          <a
                                            href="../portfolio/business-growth/index.html"
                                            ><span
                                              >Portfolio Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      id="menu-item-34"
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34"
                                    >
                                      <a href="#"
                                        ><span
                                          >Blog<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          id="menu-item-65"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-65"
                                        >
                                          <a href="../blog-grid/index.html"
                                            ><span
                                              >Blog Grid<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              id="menu-item-351"
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-351"
                                            >
                                              <a
                                                href="../blog-grid-left-sidebar/index.html"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-352"
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352"
                                            >
                                              <a
                                                href="../blog-grid-right-sidebar/index.html"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-350"
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-350"
                                            >
                                              <a href="../blog-grid/index.html"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          id="menu-item-36"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-36"
                                        >
                                          <a href="../blog-standard/index.html"
                                            ><span
                                              >Blog Standard<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              id="menu-item-284"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-284"
                                            >
                                              <a
                                                href="../blog-standard/index2072.html?sidebar-blog=left"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-285"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-285"
                                            >
                                              <a
                                                href="../blog-standard/index1f29.html?sidebar-blog=right"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-286"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-286"
                                            >
                                              <a
                                                href="../blog-standard/index0639.html?sidebar-blog=none"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          id="menu-item-291"
                                          className="menu-item menu-item-type-post_type menu-item-object-post menu-item-has-children menu-item-291"
                                        >
                                          <a
                                            href="../challanges-of-consultating-new-business-firms/index.html"
                                            ><span
                                              >Blog Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              id="menu-item-292"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-292"
                                            >
                                              <a
                                                href="../challanges-of-consultating-new-business-firms/index2072.html?sidebar-blog=left"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-293"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-293"
                                            >
                                              <a
                                                href="../challanges-of-consultating-new-business-firms/index1f29.html?sidebar-blog=right"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-294"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-294"
                                            >
                                              <a
                                                href="../challanges-of-consultating-new-business-firms/index0639.html?sidebar-blog=none"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      id="menu-item-74"
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-74"
                                    >
                                      <a href="#"
                                        ><span
                                          >Shop<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          id="menu-item-75"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-75"
                                        >
                                          <a href="../shop/index.html"
                                            ><span
                                              >Our Products<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              id="menu-item-1003"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1003"
                                            >
                                              <a
                                                href="../shop/indexa36f.html?sidebar-shop=left"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-1004"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1004"
                                            >
                                              <a
                                                href="../shop/indexf81a.html?sidebar-shop=right"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              id="menu-item-1005"
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1005"
                                            >
                                              <a
                                                href="../shop/index28de.html?sidebar-shop=none"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          id="menu-item-1002"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1002"
                                        >
                                          <a href="../cart/index.html"
                                            ><span
                                              >Shop Cart<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          id="menu-item-1042"
                                          className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1042"
                                        >
                                          <a
                                            href="../product/red-tee-shirt/index.html"
                                            ><span
                                              >Product Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-91a697a elementor-widget__width-auto elementor-widget elementor-widget-pxl_button"
                            data-id="91a697a"
                            data-element_type="widget"
                            data-widget_type="pxl_button.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                id="pxl-pxl_button-91a697a-2646"
                                className="pxl-button"
                                data-wow-delay="ms"
                              >
                                <a
                                  href="../contact-us/index.html"
                                  className="btn pxl-icon-active btn-default pxl-icon--left"
                                  data-wow-delay="ms"
                                >
                                  <span
                                    className="pxl--btn-text"
                                    data-text="Get Free Quote"
                                  >
                                    Get Free Quote
                                  </span>
                                  <span className="pxl--text-wrap">
                                    <span className="pxl--btn-text1"
                                      >Get Free Quote</span
                                    >
                                    <span className="pxl--btn-text2"
                                      >Get Free Quote</span
                                    >
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="pxl-header-elementor-sticky pxl-onepage-sticky pxl-sticky-stt"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id="90"
                  className="elementor elementor-90"
                >
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-f71ca31 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                    data-id="f71ca31"
                    data-element_type="section"
                    data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                  >
                    <div
                      className="elementor-container elementor-column-gap-extended"
                    >
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7cbdc7c pxl-column-none"
                        data-id="7cbdc7c"
                        data-element_type="column"
                      >
                        <div
                          className="elementor-widget-wrap elementor-element-populated"
                        >
                          <div
                            className="elementor-element elementor-element-f3e47b7 elementor-widget__width-auto pxl-flex-grow elementor-widget elementor-widget-pxl_logo"
                            data-id="f3e47b7"
                            data-element_type="widget"
                            data-widget_type="pxl_logo.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="pxl-logo" data-wow-delay="ms">
                                <a href="../index.html">
                                  <img
                                    width="666"
                                    height="114"
                                    src="../wp-content/uploads/2023/03/logo-dark.png"
                                    className="attachment-full"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-9d46cbc elementor-widget__width-auto elementor-widget elementor-widget-pxl_menu"
                            data-id="9d46cbc"
                            data-element_type="widget"
                            data-widget_type="pxl_menu.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="pxl-nav-menu pxl-nav-menu1 pxl-mega-full-width fr-style-default show-effect-slidedown sub-style-default"
                              >
                                <div className="menu-main-menu-container">
                                  <ul
                                    id="menu-main-menu-1"
                                    className="pxl-menu-primary clearfix"
                                  >
                                    <li
                                      className="menu-item menu-item-type-custom menu-item-object-custom pxl-megamenu menu-item-has-children menu-item-33"
                                    >
                                      <a href="#"
                                        ><span
                                          >Home<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <div className="sub-menu pxl-mega-menu">
                                        <div className="pxl-mega-menu-elementor">
                                          <div
                                            data-elementor-type="wp-post"
                                            data-elementor-id="202"
                                            className="elementor elementor-202"
                                          >
                                            <section
                                              className="elementor-section elementor-top-section elementor-element elementor-element-40b4409 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                              data-id="40b4409"
                                              data-element_type="section"
                                            >
                                              <div
                                                className="elementor-container elementor-column-gap-extended"
                                              >
                                                <div
                                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6586e8b pxl-column-none"
                                                  data-id="6586e8b"
                                                  data-element_type="column"
                                                >
                                                  <div
                                                    className="elementor-widget-wrap elementor-element-populated"
                                                  >
                                                    <div
                                                      className="elementor-element elementor-element-33be938 elementor-widget elementor-widget-pxl_showcase_grid"
                                                      data-id="33be938"
                                                      data-element_type="widget"
                                                      data-widget_type="pxl_showcase_grid.default"
                                                    >
                                                      <div
                                                        className="elementor-widget-container"
                                                      >
                                                        <div
                                                          className="pxl-grid pxl-showcase-grid pxl-showcase-grid1"
                                                        >
                                                          <div
                                                            className="pxl-grid-inner pxl-grid-masonry row"
                                                            data-gutter="15"
                                                          >
                                                            <div
                                                              className="grid-sizer col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            ></div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-01.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../index.html"
                                                                    >Consultancy</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-02.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../home-2/index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../home-2/index.html"
                                                                    >Business</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-03.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../home-3/index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../home-3/index.html"
                                                                    >Investment</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                            >
                                                              <div
                                                                className="pxl-item--inner"
                                                              >
                                                                <div
                                                                  className="pxl-item--dots"
                                                                >
                                                                  <span></span>
                                                                  <span></span>
                                                                  <span></span>
                                                                </div>
                                                                <div
                                                                  className="pxl-item--image"
                                                                >
                                                                  <img
                                                                    width="470"
                                                                    height="480"
                                                                    src="../wp-content/uploads/2023/03/demo-04.jpg"
                                                                    className="no-lazyload attachment-full"
                                                                    alt=""
                                                                  />
                                                                  <div
                                                                    className="pxl-item--buttons"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    >
                                                                      <a
                                                                        className="btn btn-primary"
                                                                        href="../home-4/index.html"
                                                                      >
                                                                        View
                                                                        Demo
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      className="pxl-item--button"
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                                <h5
                                                                  className="pxl-item--title"
                                                                >
                                                                  <a
                                                                    href="../home-4/index.html"
                                                                    >Insurance</a
                                                                  >
                                                                </h5>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61"
                                    >
                                      <a href="#"
                                        ><span
                                          >Pages<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-67"
                                        >
                                          <a href="../about-us/index.html"
                                            ><span
                                              >About Us<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-70"
                                        >
                                          <a href="../our-team/index.html"
                                            ><span
                                              >Our Team<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1422"
                                        >
                                          <a href="../team-details/index.html"
                                            ><span
                                              >Team Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64"
                                        >
                                          <a href="../contact-us/index.html"
                                            ><span
                                              >Contact Us<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-69"
                                        >
                                          <a href="../testimonials/index.html"
                                            ><span
                                              >Testimonials<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-71"
                                        >
                                          <a href="../pricing-plan/index.html"
                                            ><span
                                              >Pricing Plan<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-68"
                                        >
                                          <a href="../coming-soon/index.html"
                                            ><span
                                              >Coming Soon<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-72"
                                        >
                                          <a href="../404-error.html"
                                            ><span
                                              >404 Error<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-66"
                                        >
                                          <a href="../faq/index.html"
                                            ><span
                                              >FAQ<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5413"
                                        >
                                          <a href="../landing/index.html"
                                            ><span
                                              >Landing<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-62"
                                    >
                                      <a href="#"
                                        ><span
                                          >Services<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-78"
                                        >
                                          <a href="../services-1/index.html"
                                            ><span
                                              >Our Services 1<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-81 current_page_item menu-item-85"
                                        >
                                          <a
                                            href="index.html"
                                            aria-current="page"
                                            ><span
                                              >Our Services 2<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-service menu-item-907"
                                        >
                                          <a
                                            href="../service/market-research/index.html"
                                            ><span
                                              >Service Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-63"
                                    >
                                      <a href="#"
                                        ><span
                                          >Portfolio<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-79"
                                        >
                                          <a href="../portfolio-grid/index.html"
                                            ><span
                                              >Portfolio Grid<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-396"
                                            >
                                              <a
                                                href="../portfolio-grid-2-columns/index.html"
                                                ><span
                                                  >2 Columns<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"
                                            >
                                              <a
                                                href="../portfolio-grid/index.html"
                                                ><span
                                                  >3 Columns<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-395"
                                            >
                                              <a
                                                href="../portfolio-grid-4-columns/index.html"
                                                ><span
                                                  >4 Columns<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-389"
                                        >
                                          <a
                                            href="../portfolio-masonry/index.html"
                                            ><span
                                              >Portfolio Masonry<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-388"
                                        >
                                          <a
                                            href="../portfolio-style-1/index.html"
                                            ><span
                                              >Portfolio Style 1<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-387"
                                        >
                                          <a
                                            href="../portfolio-style-2/index.html"
                                            ><span
                                              >Portfolio Style 2<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-portfolio menu-item-476"
                                        >
                                          <a
                                            href="../portfolio/business-growth/index.html"
                                            ><span
                                              >Portfolio Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34"
                                    >
                                      <a href="#"
                                        ><span
                                          >Blog<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-65"
                                        >
                                          <a href="../blog-grid/index.html"
                                            ><span
                                              >Blog Grid<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-351"
                                            >
                                              <a
                                                href="../blog-grid-left-sidebar/index.html"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352"
                                            >
                                              <a
                                                href="../blog-grid-right-sidebar/index.html"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-350"
                                            >
                                              <a href="../blog-grid/index.html"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-36"
                                        >
                                          <a href="../blog-standard/index.html"
                                            ><span
                                              >Blog Standard<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-284"
                                            >
                                              <a
                                                href="../blog-standard/index2072.html?sidebar-blog=left"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-285"
                                            >
                                              <a
                                                href="../blog-standard/index1f29.html?sidebar-blog=right"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-286"
                                            >
                                              <a
                                                href="../blog-standard/index0639.html?sidebar-blog=none"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-post menu-item-has-children menu-item-291"
                                        >
                                          <a
                                            href="../challanges-of-consultating-new-business-firms/index.html"
                                            ><span
                                              >Blog Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-292"
                                            >
                                              <a
                                                href="../challanges-of-consultating-new-business-firms/index2072.html?sidebar-blog=left"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-293"
                                            >
                                              <a
                                                href="../challanges-of-consultating-new-business-firms/index1f29.html?sidebar-blog=right"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-294"
                                            >
                                              <a
                                                href="../challanges-of-consultating-new-business-firms/index0639.html?sidebar-blog=none"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-74"
                                    >
                                      <a href="#"
                                        ><span
                                          >Shop<i
                                            className="caseicon-angle-arrow-down pxl-hide"
                                          ></i></span
                                      ></a>
                                      <ul className="sub-menu">
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-75"
                                        >
                                          <a href="../shop/index.html"
                                            ><span
                                              >Our Products<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                          <ul className="sub-menu">
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1003"
                                            >
                                              <a
                                                href="../shop/indexa36f.html?sidebar-shop=left"
                                                ><span
                                                  >Left Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1004"
                                            >
                                              <a
                                                href="../shop/indexf81a.html?sidebar-shop=right"
                                                ><span
                                                  >Right Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                            <li
                                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1005"
                                            >
                                              <a
                                                href="../shop/index28de.html?sidebar-shop=none"
                                                ><span
                                                  >Without Sidebar<i
                                                    className="caseicon-angle-arrow-down pxl-hide"
                                                  ></i></span
                                              ></a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1002"
                                        >
                                          <a href="../cart/index.html"
                                            ><span
                                              >Shop Cart<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                        <li
                                          className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1042"
                                        >
                                          <a
                                            href="../product/red-tee-shirt/index.html"
                                            ><span
                                              >Product Details<i
                                                className="caseicon-angle-arrow-down pxl-hide"
                                              ></i></span
                                          ></a>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-6dc58b5 elementor-widget__width-auto elementor-widget elementor-widget-pxl_button"
                            data-id="6dc58b5"
                            data-element_type="widget"
                            data-widget_type="pxl_button.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                id="pxl-pxl_button-6dc58b5-6119"
                                className="pxl-button"
                                data-wow-delay="ms"
                              >
                                <a
                                  href="../contact-us/index.html"
                                  className="btn pxl-icon-active btn-default pxl-icon--left"
                                  data-wow-delay="ms"
                                >
                                  <span
                                    className="pxl--btn-text"
                                    data-text="Get Free Quote"
                                  >
                                    Get Free Quote
                                  </span>
                                  <span className="pxl--text-wrap">
                                    <span className="pxl--btn-text1"
                                      >Get Free Quote</span
                                    >
                                    <span className="pxl--btn-text2"
                                      >Get Free Quote</span
                                    >
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="pxl-header-mobile" className="style-light">
          <div id="pxl-header-main" className="pxl-header-main">
            <div className="container">
              <div className="row">
                <div className="pxl-header-branding">
                  <a href="../index.html" title="Jackcerra" rel="home"
                    ><img
                      src="../wp-content/themes/jackcerra/assets/img/logo.png"
                      alt="Jackcerra"
                  /></a>
                </div>
                <div className="pxl-header-menu">
                  <div className="pxl-header-menu-scroll">
                    <div className="pxl-menu-close pxl-hide-xl pxl-close"></div>
                    <div className="pxl-logo-mobile pxl-hide-xl">
                      <a href="../index.html" title="Jackcerra" rel="home"
                        ><img
                          src="../wp-content/themes/jackcerra/assets/img/logo.png"
                          alt="Jackcerra"
                      /></a>
                    </div>
                    <div className="pxl-header-mobile-search pxl-hide-xl">
                      <form
                        role="search"
                        method="get"
                        action="https://demo.bravisthemes.com/jackcerra/"
                      >
                        <input
                          type="text"
                          placeholder="Search..."
                          name="s"
                          className="search-field"
                        />
                        <button type="submit" className="search-submit">
                          <i className="caseicon-search"></i>
                        </button>
                      </form>
                    </div>
                    <nav className="pxl-header-nav">
                      <ul
                        id="menu-main-menu-2"
                        className="pxl-menu-primary clearfix"
                      >
                        <li
                          className="menu-item menu-item-type-custom menu-item-object-custom pxl-megamenu menu-item-has-children menu-item-33"
                        >
                          <a href="#"><span>Home</span></a>
                          <div className="sub-menu pxl-mega-menu">
                            <div className="pxl-mega-menu-elementor">
                              <div
                                data-elementor-type="wp-post"
                                data-elementor-id="202"
                                className="elementor elementor-202"
                              >
                                <section
                                  className="elementor-section elementor-top-section elementor-element elementor-element-40b4409 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                  data-id="40b4409"
                                  data-element_type="section"
                                >
                                  <div
                                    className="elementor-container elementor-column-gap-extended"
                                  >
                                    <div
                                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6586e8b pxl-column-none"
                                      data-id="6586e8b"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-33be938 elementor-widget elementor-widget-pxl_showcase_grid"
                                          data-id="33be938"
                                          data-element_type="widget"
                                          data-widget_type="pxl_showcase_grid.default"
                                        >
                                          <div
                                            className="elementor-widget-container"
                                          >
                                            <div
                                              className="pxl-grid pxl-showcase-grid pxl-showcase-grid1"
                                            >
                                              <div
                                                className="pxl-grid-inner pxl-grid-masonry row"
                                                data-gutter="15"
                                              >
                                                <div
                                                  className="grid-sizer col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                ></div>
                                                <div
                                                  className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                >
                                                  <div className="pxl-item--inner">
                                                    <div className="pxl-item--dots">
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                    </div>
                                                    <div
                                                      className="pxl-item--image"
                                                    >
                                                      <img
                                                        width="470"
                                                        height="480"
                                                        src="../wp-content/uploads/2023/03/demo-01.jpg"
                                                        className="no-lazyload attachment-full"
                                                        alt=""
                                                      />
                                                      <div
                                                        className="pxl-item--buttons"
                                                      >
                                                        <div
                                                          className="pxl-item--button"
                                                        >
                                                          <a
                                                            className="btn btn-primary"
                                                            href="../index.html"
                                                          >
                                                            View Demo
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="pxl-item--button"
                                                        ></div>
                                                      </div>
                                                    </div>
                                                    <h5 className="pxl-item--title">
                                                      <a href="../index.html"
                                                        >Consultancy</a
                                                      >
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                >
                                                  <div className="pxl-item--inner">
                                                    <div className="pxl-item--dots">
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                    </div>
                                                    <div
                                                      className="pxl-item--image"
                                                    >
                                                      <img
                                                        width="470"
                                                        height="480"
                                                        src="../wp-content/uploads/2023/03/demo-02.jpg"
                                                        className="no-lazyload attachment-full"
                                                        alt=""
                                                      />
                                                      <div
                                                        className="pxl-item--buttons"
                                                      >
                                                        <div
                                                          className="pxl-item--button"
                                                        >
                                                          <a
                                                            className="btn btn-primary"
                                                            href="../home-2/index.html"
                                                          >
                                                            View Demo
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="pxl-item--button"
                                                        ></div>
                                                      </div>
                                                    </div>
                                                    <h5 className="pxl-item--title">
                                                      <a
                                                        href="../home-2/index.html"
                                                        >Business</a
                                                      >
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                >
                                                  <div className="pxl-item--inner">
                                                    <div className="pxl-item--dots">
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                    </div>
                                                    <div
                                                      className="pxl-item--image"
                                                    >
                                                      <img
                                                        width="470"
                                                        height="480"
                                                        src="../wp-content/uploads/2023/03/demo-03.jpg"
                                                        className="no-lazyload attachment-full"
                                                        alt=""
                                                      />
                                                      <div
                                                        className="pxl-item--buttons"
                                                      >
                                                        <div
                                                          className="pxl-item--button"
                                                        >
                                                          <a
                                                            className="btn btn-primary"
                                                            href="../home-3/index.html"
                                                          >
                                                            View Demo
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="pxl-item--button"
                                                        ></div>
                                                      </div>
                                                    </div>
                                                    <h5 className="pxl-item--title">
                                                      <a
                                                        href="../home-3/index.html"
                                                        >Investment</a
                                                      >
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="pxl-grid-item col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12"
                                                >
                                                  <div className="pxl-item--inner">
                                                    <div className="pxl-item--dots">
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                    </div>
                                                    <div
                                                      className="pxl-item--image"
                                                    >
                                                      <img
                                                        width="470"
                                                        height="480"
                                                        src="../wp-content/uploads/2023/03/demo-04.jpg"
                                                        className="no-lazyload attachment-full"
                                                        alt=""
                                                      />
                                                      <div
                                                        className="pxl-item--buttons"
                                                      >
                                                        <div
                                                          className="pxl-item--button"
                                                        >
                                                          <a
                                                            className="btn btn-primary"
                                                            href="../home-4/index.html"
                                                          >
                                                            View Demo
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="pxl-item--button"
                                                        ></div>
                                                      </div>
                                                    </div>
                                                    <h5 className="pxl-item--title">
                                                      <a
                                                        href="../home-4/index.html"
                                                        >Insurance</a
                                                      >
                                                    </h5>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61"
                        >
                          <a href="#"><span>Pages</span></a>
                          <ul className="sub-menu">
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-67"
                            >
                              <a href="../about-us/index.html"
                                ><span>About Us</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-70"
                            >
                              <a href="../our-team/index.html"
                                ><span>Our Team</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1422"
                            >
                              <a href="../team-details/index.html"
                                ><span>Team Details</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64"
                            >
                              <a href="../contact-us/index.html"
                                ><span>Contact Us</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-69"
                            >
                              <a href="../testimonials/index.html"
                                ><span>Testimonials</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-71"
                            >
                              <a href="../pricing-plan/index.html"
                                ><span>Pricing Plan</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-68"
                            >
                              <a href="../coming-soon/index.html"
                                ><span>Coming Soon</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-72"
                            >
                              <a href="../404-error.html"
                                ><span>404 Error</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-66"
                            >
                              <a href="../faq/index.html"><span>FAQ</span></a>
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5413"
                            >
                              <a href="../landing/index.html"
                                ><span>Landing</span></a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-62"
                        >
                          <a href="#"><span>Services</span></a>
                          <ul className="sub-menu">
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-78"
                            >
                              <a href="../services-1/index.html"
                                ><span>Our Services 1</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-81 current_page_item menu-item-85"
                            >
                              <a href="index.html" aria-current="page"
                                ><span>Our Services 2</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-service menu-item-907"
                            >
                              <a href="../service/market-research/index.html"
                                ><span>Service Details</span></a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-63"
                        >
                          <a href="#"><span>Portfolio</span></a>
                          <ul className="sub-menu">
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-79"
                            >
                              <a href="../portfolio-grid/index.html"
                                ><span>Portfolio Grid</span></a
                              >
                              <ul className="sub-menu">
                                <li
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-396"
                                >
                                  <a
                                    href="../portfolio-grid-2-columns/index.html"
                                    ><span>2 Columns</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"
                                >
                                  <a href="../portfolio-grid/index.html"
                                    ><span>3 Columns</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-395"
                                >
                                  <a
                                    href="../portfolio-grid-4-columns/index.html"
                                    ><span>4 Columns</span></a
                                  >
                                </li>
                              </ul>
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-389"
                            >
                              <a href="../portfolio-masonry/index.html"
                                ><span>Portfolio Masonry</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-388"
                            >
                              <a href="../portfolio-style-1/index.html"
                                ><span>Portfolio Style 1</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-387"
                            >
                              <a href="../portfolio-style-2/index.html"
                                ><span>Portfolio Style 2</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-portfolio menu-item-476"
                            >
                              <a href="../portfolio/business-growth/index.html"
                                ><span>Portfolio Details</span></a
                              >
                            </li>
                          </ul>
                        </li>
                        <li
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34"
                        >
                          <a href="#"><span>Blog</span></a>
                          <ul className="sub-menu">
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-65"
                            >
                              <a href="../blog-grid/index.html"
                                ><span>Blog Grid</span></a
                              >
                              <ul className="sub-menu">
                                <li
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-351"
                                >
                                  <a href="../blog-grid-left-sidebar/index.html"
                                    ><span>Left Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352"
                                >
                                  <a
                                    href="../blog-grid-right-sidebar/index.html"
                                    ><span>Right Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-350"
                                >
                                  <a href="../blog-grid/index.html"
                                    ><span>Without Sidebar</span></a
                                  >
                                </li>
                              </ul>
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-36"
                            >
                              <a href="../blog-standard/index.html"
                                ><span>Blog Standard</span></a
                              >
                              <ul className="sub-menu">
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-284"
                                >
                                  <a
                                    href="../blog-standard/index2072.html?sidebar-blog=left"
                                    ><span>Left Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-285"
                                >
                                  <a
                                    href="../blog-standard/index1f29.html?sidebar-blog=right"
                                    ><span>Right Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-286"
                                >
                                  <a
                                    href="../blog-standard/index0639.html?sidebar-blog=none"
                                    ><span>Without Sidebar</span></a
                                  >
                                </li>
                              </ul>
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-post menu-item-has-children menu-item-291"
                            >
                              <a
                                href="../challanges-of-consultating-new-business-firms/index.html"
                                ><span>Blog Details</span></a
                              >
                              <ul className="sub-menu">
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-292"
                                >
                                  <a
                                    href="../challanges-of-consultating-new-business-firms/index2072.html?sidebar-blog=left"
                                    ><span>Left Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-293"
                                >
                                  <a
                                    href="../challanges-of-consultating-new-business-firms/index1f29.html?sidebar-blog=right"
                                    ><span>Right Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-294"
                                >
                                  <a
                                    href="../challanges-of-consultating-new-business-firms/index0639.html?sidebar-blog=none"
                                    ><span>Without Sidebar</span></a
                                  >
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-74"
                        >
                          <a href="#"><span>Shop</span></a>
                          <ul className="sub-menu">
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-75"
                            >
                              <a href="../shop/index.html"
                                ><span>Our Products</span></a
                              >
                              <ul className="sub-menu">
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1003"
                                >
                                  <a
                                    href="../shop/indexa36f.html?sidebar-shop=left"
                                    ><span>Left Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1004"
                                >
                                  <a
                                    href="../shop/indexf81a.html?sidebar-shop=right"
                                    ><span>Right Sidebar</span></a
                                  >
                                </li>
                                <li
                                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1005"
                                >
                                  <a
                                    href="../shop/index28de.html?sidebar-shop=none"
                                    ><span>Without Sidebar</span></a
                                  >
                                </li>
                              </ul>
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1002"
                            >
                              <a href="../cart/index.html"
                                ><span>Shop Cart</span></a
                              >
                            </li>
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1042"
                            >
                              <a href="../product/red-tee-shirt/index.html"
                                ><span>Product Details</span></a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="pxl-header-menu-backdrop"></div>
              </div>
            </div>
            <div id="pxl-nav-mobile">
              <div className="pxl-nav-mobile-button"><span></span></div>
            </div>
          </div>
        </div>
      </header> */}
        <div id="pxl-page-title-elementor">
          <div
            data-elementor-type="wp-post"
            data-elementor-id="94"
            className="elementor elementor-94"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-fa5168c elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
              data-id="fa5168c"
              data-element_type="section"
              data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-439d52b pxl-column-none"
                  data-id="439d52b"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-4055df0 pxl-page--title elementor-widget elementor-widget-pxl_heading"
                      data-id="4055df0"
                      data-element_type="widget"
                      data-widget_type="pxl_heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div
                          id="pxl-pxl_heading-4055df0-6701"
                          className="pxl-heading px-sub-title-default-style"
                        >
                          <div className="pxl-heading--inner">
                            <h1 className="pxl-item--title" data-wow-delay="ms">
                              Our Services
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-937e8b3 elementor-widget elementor-widget-pxl_breadcrumb"
                      data-id="937e8b3"
                      data-element_type="widget"
                      data-widget_type="pxl_breadcrumb.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="pxl-breadcrumb-wrap">
                          <ul className="pxl-breadcrumb">
                            <li>
                              <a className="breadcrumb-entry" href='/'
                              >Home</a
                              >
                            </li>
                            <li>
                              <span className="breadcrumb-entry">Services 2</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* <div id="pxl-main"> */}
        <div className="elementor-container">
          <div className="row pxl-content-wrap no-sidebar">
            <div
              id="pxl-content-area"
              className="pxl-content-area pxl-content-page col-12"
            >
              <main id="pxl-content-main">
                <article
                  id="pxl-post-81"
                  className="post-81 page type-page status-publish hentry"
                >
                  <div className="pxl-entry-content clearfix">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id="81"
                      className="elementor elementor-81"
                    >
                      {/* <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-67812a6f elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                        data-id="67812a6f"
                        data-element_type="section"
                      >
                        <div
                          className="elementor-container elementor-column-gap-extended"
                        >
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6f05b827 pxl-column-none"
                            data-id="6f05b827"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-48dbf61b elementor-widget elementor-widget-pxl_heading"
                                data-id="48dbf61b"
                                data-element_type="widget"
                                data-widget_type="pxl_heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    id="pxl-pxl_heading-48dbf61b-9480"
                                    className="pxl-heading px-sub-title-default-style"
                                  >
                                    <div className="pxl-heading--inner">
                                      <div
                                        className="pxl-item--subtitle px-sub-title-default"
                                        data-wow-delay="ms"
                                      >
                                        <span className="pxl-item--subtext">
                                          OUR SERVICES
                                          <span
                                            className="pxl-item--subdivider"
                                          ></span>
                                        </span>
                                      </div>
                                      <h3
                                        className="pxl-item--title"
                                        data-wow-delay="ms"
                                      >
                                        Providing Best Services
                                      </h3>
                                      <div className="px-divider--wrap">
                                        <div
                                          className="px-title--divider px-title--divider1"
                                        >
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-252637b5 pxl-post-layout-service-1 elementor-widget elementor-widget-pxl_post_grid"
                                data-id="252637b5"
                                data-element_type="widget"
                                data-widget_type="pxl_post_grid.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    id="pxl_post_grid-252637b5-8571"
                                    className="pxl-grid pxl-service-grid pxl-service-grid-layout1 pxl-service-style1 pxl-grid--6items style-2"
                                    data-layout="masonry"
                                    data-start-page="1"
                                    data-max-pages="2"
                                    data-total="11"
                                    data-perpage="6"
                                    data-next-link="page/2/index.html"
                                  >
                                    <div
                                      className="pxl-grid-inner pxl-grid-masonry row"
                                      data-gutter="15"
                                    >
                                      <div
                                        className="pxl-grid-item col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 consulting"
                                      >
                                        <div
                                          className="pxl-item--inner wow fadeInUp"
                                          data-wow-duration="1.2s"
                                        >
                                          <div className="pxl-item--main">
                                            <div
                                              className="pxl-item--featured bg-image"
                                              style={{
                                                backgroundImage: "url(../wp-content/uploads/2023/03/service-01-380x380.jpg)"
                                              }}
                                            >
                                              <div
                                                className="pxl-item--shape2 pxl-item--shape-secondary"
                                              >
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider1"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider2"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider3"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="pxl-item--holder">
                                              <div className="pxl-item--icon">
                                                <i
                                                  className="flaticon flaticon-financial"
                                                ></i>
                                              </div>
                                              <h3 className="pxl-item--title">
                                                <a
                                                  href="../service/financial-consultation/index.html"
                                                  >Financial Consultation</a
                                                >
                                              </h3>
                                              <div className="pxl-item--content">
                                                We are providing growth
                                                consultancies & financial
                                                services for companies who want
                                                to excel their business.
                                              </div>
                                              <div className="pxl-item--readmore">
                                                <a
                                                  className="btn--readmore2"
                                                  href="../service/financial-consultation/index.html"
                                                >
                                                  <span
                                                    className="btn-readmore--icon pxl-mr-10"
                                                    ><i
                                                      className="flaticon-right rtl-reverse"
                                                    ></i
                                                  ></span>
                                                  <span
                                                    className="btn-readmore--text"
                                                    >Read more</span
                                                  >
                                                </a>
                                              </div>
                                            </div>
                                            <div className="pxl-item--shape">
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider1"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider2"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider3"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="pxl-grid-item col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 consulting"
                                      >
                                        <div
                                          className="pxl-item--inner wow fadeInUp"
                                          data-wow-duration="1.2s"
                                        >
                                          <div className="pxl-item--main">
                                            <div
                                              className="pxl-item--featured bg-image"
                                              style={{
                                                backgroundImage: "url(../wp-content/uploads/2023/03/service-02-380x380.jpg)"
                                              }}
                                            >
                                              <div
                                                className="pxl-item--shape2 pxl-item--shape-secondary"
                                              >
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider1"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider2"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider3"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="pxl-item--holder">
                                              <div className="pxl-item--icon">
                                                <i
                                                  className="flaticon flaticon-presentation-1"
                                                ></i>
                                              </div>
                                              <h3 className="pxl-item--title">
                                                <a
                                                  href="../service/leadership-training/index.html"
                                                  >Leadership Training</a
                                                >
                                              </h3>
                                              <div className="pxl-item--content">
                                                We try to provide new project
                                                Ideas for new entrepreneurs who
                                                already have investor & looking
                                                for ideas.
                                              </div>
                                              <div className="pxl-item--readmore">
                                                <a
                                                  className="btn--readmore2"
                                                  href="../service/leadership-training/index.html"
                                                >
                                                  <span
                                                    className="btn-readmore--icon pxl-mr-10"
                                                    ><i
                                                      className="flaticon-right rtl-reverse"
                                                    ></i
                                                  ></span>
                                                  <span
                                                    className="btn-readmore--text"
                                                    >Read more</span
                                                  >
                                                </a>
                                              </div>
                                            </div>
                                            <div className="pxl-item--shape">
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider1"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider2"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider3"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="pxl-grid-item col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 consulting"
                                      >
                                        <div
                                          className="pxl-item--inner wow fadeInUp"
                                          data-wow-duration="1.2s"
                                        >
                                          <div className="pxl-item--main">
                                            <div
                                              className="pxl-item--featured bg-image"
                                              style=
                                              {{  backgroundImage: "url(../wp-content/uploads/2023/03/service-03-380x380.jpg)"}}
                                              
                                            >
                                              <div
                                                className="pxl-item--shape2 pxl-item--shape-secondary"
                                              >
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider1"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider2"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider3"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="pxl-item--holder">
                                              <div className="pxl-item--icon">
                                                <i
                                                  className="flaticon flaticon-market-research-1"
                                                ></i>
                                              </div>
                                              <h3 className="pxl-item--title">
                                                <a
                                                  href="../service/market-research/index.html"
                                                  >Market Research</a
                                                >
                                              </h3>
                                              <div className="pxl-item--content">
                                                For a growing business firm we
                                                provide market research &
                                                competitor analysis before a
                                                product launch in market.
                                              </div>
                                              <div className="pxl-item--readmore">
                                                <a
                                                  className="btn--readmore2"
                                                  href="../service/market-research/index.html"
                                                >
                                                  <span
                                                    className="btn-readmore--icon pxl-mr-10"
                                                    ><i
                                                      className="flaticon-right rtl-reverse"
                                                    ></i
                                                  ></span>
                                                  <span
                                                    className="btn-readmore--text"
                                                    >Read more</span
                                                  >
                                                </a>
                                              </div>
                                            </div>
                                            <div className="pxl-item--shape">
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider1"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider2"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider3"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="pxl-grid-item col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 consulting"
                                      >
                                        <div
                                          className="pxl-item--inner wow fadeInUp"
                                          data-wow-duration="1.2s"
                                        >
                                          <div className="pxl-item--main">
                                            <div
                                              className="pxl-item--featured bg-image"
                                              style={{
                                                backgroundImage: "url(../wp-content/uploads/2023/03/service-04-380x380.jpg)"
                                              }}
                                            >
                                              <div
                                                className="pxl-item--shape2 pxl-item--shape-secondary"
                                              >
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider1"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider2"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider3"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="pxl-item--holder">
                                              <div className="pxl-item--icon">
                                                <i
                                                  className="flaticon flaticon-economic"
                                                ></i>
                                              </div>
                                              <h3 className="pxl-item--title">
                                                <a
                                                  href="../service/global-operation-plan/index.html"
                                                  >Global Operation Plan</a
                                                >
                                              </h3>
                                              <div className="pxl-item--content">
                                                Do you have dream to expand your
                                                business globally? we are
                                                providing the best planning
                                                proposal for this.
                                              </div>
                                              <div className="pxl-item--readmore">
                                                <a
                                                  className="btn--readmore2"
                                                  href="../service/global-operation-plan/index.html"
                                                >
                                                  <span
                                                    className="btn-readmore--icon pxl-mr-10"
                                                    ><i
                                                      className="flaticon-right rtl-reverse"
                                                    ></i
                                                  ></span>
                                                  <span
                                                    className="btn-readmore--text"
                                                    >Read more</span
                                                  >
                                                </a>
                                              </div>
                                            </div>
                                            <div className="pxl-item--shape">
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider1"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider2"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider3"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="pxl-grid-item col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 consulting"
                                      >
                                        <div
                                          className="pxl-item--inner wow fadeInUp"
                                          data-wow-duration="1.2s"
                                        >
                                          <div className="pxl-item--main">
                                            <div
                                              className="pxl-item--featured bg-image"
                                              style={{
                                                backgroundImage: "url(../wp-content/uploads/2023/03/service-05-380x380.jpg)"
                                              }}
                                            >
                                              <div
                                                className="pxl-item--shape2 pxl-item--shape-secondary"
                                              >
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider1"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider2"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider3"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="pxl-item--holder">
                                              <div className="pxl-item--icon">
                                                <i
                                                  className="flaticon flaticon-income"
                                                ></i>
                                              </div>
                                              <h3 className="pxl-item--title">
                                                <a
                                                  href="../service/leadership-evaluation/index.html"
                                                  >Leadership Evaluation</a
                                                >
                                              </h3>
                                              <div className="pxl-item--content">
                                                We are providing growth
                                                consultancies & financial
                                                services for companies who want
                                                to excel their business.
                                              </div>
                                              <div className="pxl-item--readmore">
                                                <a
                                                  className="btn--readmore2"
                                                  href="../service/leadership-evaluation/index.html"
                                                >
                                                  <span
                                                    className="btn-readmore--icon pxl-mr-10"
                                                    ><i
                                                      className="flaticon-right rtl-reverse"
                                                    ></i
                                                  ></span>
                                                  <span
                                                    className="btn-readmore--text"
                                                    >Read more</span
                                                  >
                                                </a>
                                              </div>
                                            </div>
                                            <div className="pxl-item--shape">
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider1"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider2"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider3"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="pxl-grid-item col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 consulting"
                                      >
                                        <div
                                          className="pxl-item--inner wow fadeInUp"
                                          data-wow-duration="1.2s"
                                        >
                                          <div className="pxl-item--main">
                                            <div
                                              className="pxl-item--featured bg-image"
                                              style={{
                                                backgroundImage: "url(../wp-content/uploads/2023/03/service-06-380x380.jpg)"
                                              }}
                                            >
                                              <div
                                                className="pxl-item--shape2 pxl-item--shape-secondary"
                                              >
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider1"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider2"
                                                ></div>
                                                <div
                                                  className="pxl-shape--divider pxl-shape--divider3"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="pxl-item--holder">
                                              <div className="pxl-item--icon">
                                                <i
                                                  className="flaticon flaticon-online-marketing"
                                                ></i>
                                              </div>
                                              <h3 className="pxl-item--title">
                                                <a
                                                  href="../service/digital-marketing/index.html"
                                                  >Digital Marketing</a
                                                >
                                              </h3>
                                              <div className="pxl-item--content">
                                                No one can deny the importance
                                                of digital marketing now a days
                                                so we are providing this service
                                                also.
                                              </div>
                                              <div className="pxl-item--readmore">
                                                <a
                                                  className="btn--readmore2"
                                                  href="../service/digital-marketing/index.html"
                                                >
                                                  <span
                                                    className="btn-readmore--icon pxl-mr-10"
                                                    ><i
                                                      className="flaticon-right rtl-reverse"
                                                    ></i
                                                  ></span>
                                                  <span
                                                    className="btn-readmore--text"
                                                    >Read more</span
                                                  >
                                                </a>
                                              </div>
                                            </div>
                                            <div className="pxl-item--shape">
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider1"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider2"
                                              ></div>
                                              <div
                                                className="pxl-shape--divider pxl-shape--divider3"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="grid-sizer col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* New services  */}
        <div
          className=" single single-post" style={{ padding: "105px 20px" }}>

          <div id="pxl-main" >
            <div className="container">
              <div className="row pxl-content-wrap no-sidebar">
                <div
                  id="pxl-content-area"
                  className="pxl-content-area pxl-content-post col-12"
                >
                  <main id="pxl-content-main">
                    <article
                      id="pxl-post-261"
                      className="pxl---post post-261 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-consultancy tag-marketing tag-process"
                    >
                      <div className="pxl-item--holder">
                        <div className="pxl-item--meta"></div>
                        <div className="pxl-item--content clearfix">

                          <h3
                            className="color-primary"
                            style={{ paddingTop: "4px", marginBottom: "19px" }}
                          >
                            SERVICE WE OFFERS
                          </h3>

                          <p style={{ textAlign: 'left' }}>
                            Our tailored quantitative studies typically enhance qualitative exploration and
                            provide valuable measures of how well companies are tracking and/ or
                            evidence needed for strong commercial outcomes. Increasingly quantitative
                            surveys are now conducted online.
                          </p>
                          <p style={{ marginBottom: "58px", textAlign: 'left' }}>
                            Our  capabilities and wide reach attract top consulting and global
                            brands. Our aim is not just to provide encapsulated data as most of the
                            market research companies do, rather we study and understand your core
                            business needs and work in accordance to deliver the solutions that meet
                            your most critical challenges. Our value driven and highly customized services
                            are aimed to completely transform your business.
                          </p>
                          <h3
                            className="color-primary"
                            style={{ paddingTop: "4px", marginBottom: "19px" }}
                          >
                            Industry experience and market knowledge.
                          </h3>

                          <p style={{ textAlign: 'left' }}>

                            Our industry expertise and specialist market knowledge empower us to offer valuable
                            insights and solutions. With a deep understanding of your industry, we help drive your
                            success and stay competitive.

                          </p>
                          <h3
                            className="color-primary"
                            style={{ paddingTop: "4px", marginBottom: "19px" }}
                          >
                            Quantitative
                          </h3>

                          <p style={{ textAlign: 'left' }}>

                            We are a trusted provider of quantitative research services to a diverse client base. Our
                            comprehensive range of quantitative research offerings allows for informed decision-making
                            and the acquisition of reliable statistical results. With our expertise and understanding of
                            your research objectives, we can assist you in determining the most appropriate approach
                            for your needs.


                          </p>
                          <h3
                            className="color-primary"
                            style={{ paddingTop: "4px", marginBottom: "19px" }}
                          >
                            Qualitative
                          </h3>

                          <p style={{ textAlign: 'left' }}>

                            Qualitative research is an exceptionally valuable tool for in-depth exploration of a particular
                            issue, delving into its fundamental aspects and revealing underlying perceptions and
                            justifications that influence diverse attitudes and behaviors. At Calc Insights, we offer both
                            focus groups and in-depth interviews as effective means to achieve this objective.

                          </p>
                          <h3 className="color-primary">Research We Provide</h3>
                          <ul >

                            <li style={{ textAlign: 'left', fontWeight: 700 }}> Available across device – Smartphones, PCs, and tablets</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Localisation of language to suit geographical location</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>
                              Communication Reaction Assessment
                            </li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Optimum survey design built in consultation with experience and experts</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Outreach strategy</li> <br />
                          </ul>
                          <h3 className="color-primary">Our Quality Control</h3>
                          <p style={{ textAlign: 'left' }}>
                            At Calc insights we attach great importance to quality assurance of the deliverables from this
                            project. Our approach to quality assurance aims to develop and deliver work of the highest
                            quality.

                          </p>
                          <ul >

                            <li style={{ textAlign: 'left', fontWeight: 700 }}> Ensuring we understand fully the requirements of the research and of the target audience</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}> Providing executive interviewers who are well qualified and have appropriate experience with the research and the audience</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Establishing close and harmonious working relationships with key project stakeholders</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Secondary checking of all verbatim responses collected to ensure these are full and complete</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Validation, error checking and sense checks on the interviewing deliverables to ensure robust survey data</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}>Validation, error checking and sense checks on the interviewing deliverables to ensure
                              robust survey data</li> <br />
                            <li style={{ textAlign: 'left', fontWeight: 700 }}> Regularly reviewing and refining quality assurance to continually improve processes and
                              ensure quality
                            </li> <br />
                            <p style={{ textAlign: 'left' }}>We implement rigorous internal processes to ensure robust, high quality data.</p>
                          </ul>
                          {/* <h5 className="single-heading">Research We Provide</h5> */}
                       


                        </div>
                      </div>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* New services  */}
        <a className="pxl-scroll-top" href="#"
        ><i className="caseicon-long-arrow-right-three"></i
        ></a>
        <div className="pxl-cursor pxl-js-cursor style-default">
          <div className="pxl-cursor-wrapper">
            <div className="pxl-cursor--follower pxl-js-follower"></div>
            <div className="pxl-cursor--label pxl-js-label"></div>
            <div className="pxl-cursor--drap pxl-js-drap"></div>
            <div className="pxl-cursor--icon pxl-js-icon"></div>
          </div>
        </div>
        <div className="pxl-cursor-section"></div>
        <div id="pxl-page-popup" className="">
          <div className="pxl-popup-overlay"></div>
          <div className="pxl-page-popup-content"></div>
        </div>
      </div>




    </div>

  )
}

export default Service