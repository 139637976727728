import React, { useState } from "react";
import SubmitPopUp from "./SubmitPopUp";
import axios from "axios";

export default function Home() {
  const [name,setName] = useState("")
  const [phone,setPhone] = useState("")
  const [email,setEmail] = useState("")
  const [subject,setSubject] = useState("")
  const [message,setMessage] = useState("")
  const [open,setOpen] = useState(false)

  const handleSubmit = e =>{
    e.preventDefault()
    if(!name || !phone || !email || !subject || !message)
    {
      alert("Please fill all fields")
    }else{
      axios.post("https://cms.calcinsights.com/api/appointment-lists",{
        "data": {
            "name": name,
            "phone":parseInt(phone),
            "email": email,
            "subject":subject,
            "message":message
        }
    }).then((e)=>{
      setName("")
      setEmail("")
      setPhone("")
      setSubject("")
      setMessage("")
      setOpen(true);
    }).catch((e)=>{
      alert("Error occurred, please try again")
    })
    }
    console.log(name,phone,email,subject,message)
  }
  return (
    <div className="page-template-default page page-id-25 theme-jackcerra woocommerce-no-js pxl-redux-page pxl-footer-fixed body-default-font heading-default-font bd-px-header--default site-color-gradient woocommerce-layout-grid elementor-default elementor-kit-6 elementor-page elementor-page-25">

      <div id="pxl-wapper" className="pxl-wapper">

        <div id="pxl-main">
          <div className="elementor-container">
            <div className="row pxl-content-wrap no-sidebar">
              <div
                id="pxl-content-area"
                className="pxl-content-area pxl-content-page col-12"
              >
                <main id="pxl-content-main" >
                  <article
                    id="pxl-post-25"
                    className="post-25 page type-page status-publish hentry"
                  >
                    <div className="pxl-entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="25"
                        className="elementor elementor-25"
                      >
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-276c531 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-full-content-with-space-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="276c531"
                          data-element_type="section"
                          data-settings='{"stretch_section":"section-stretched"}'
                        >
                          <div className="elementor-container elementor-column-gap-no">
                            <div
                              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e02e50b pxl-column-none"
                              data-id="e02e50b"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-a12d958 elementor-widget elementor-widget-pxl_slider"
                                  data-id="a12d958"
                                  data-element_type="widget"
                                  data-widget_type="pxl_slider.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      style={{ opacity: 1 }}
                                      className="pxl-swiper-sliders pxl-element-slider pxl-swiper-nogap pxl-swiper-show-arrow home-opacity"

                                      data-slider-mode="fade"
                                    >
                                      <div className="pxl-carousel-inner">
                                        <div
                                          className="pxl-swiper-container"
                                          dir="ltr"
                                          data-settings='{"slide_direction":"horizontal","slide_percolumn":"1","slide_mode":"fade","slides_to_show":"1","slides_to_show_xxl":"1","slides_to_show_lg":"1","slides_to_show_md":"1","slides_to_show_sm":"1","slides_to_show_xs":"1","slides_to_scroll":"1","arrow":"true","pagination":"false","pagination_type":"bullets","autoplay":"true","pause_on_hover":"","pause_on_interaction":"true","delay":8000,"loop":"true"}'
                                        >
                                          <div className="pxl-swiper-wrapper">
                                            <div className="pxl-swiper-slide">
                                              <div className="pxl-slider--inner elementor-repeater-item-23bbbfd">
                                                <div className="pxl-slider--mainimage pxl-image--kenburns wow">
                                                  <div
                                                    className="pxl-slider--image bg-image"
                                                    style={{
                                                      backgroundImage:
                                                        "url(assets/wp-content/uploads/2023/03/h2-bg-slider.jpg)",
                                                    }}
                                                  ></div>
                                                </div>
                                                <div
                                                  className="pxl-slider--overlay bg-image elementor-repeater-item-23bbbfd"
                                                  style={{
                                                    backgroundImage:
                                                      "url(assets/wp-content/uploads/2023/03/h2-slider-chart.png)",
                                                  }}
                                                ></div>
                                                <div className="pxl-slider--content content-align-flex-end">
                                                  <div
                                                    data-elementor-type="wp-post"
                                                    data-elementor-id="2807"
                                                    className="elementor elementor-2807"
                                                  >
                                                    <section
                                                      className="elementor-section elementor-top-section elementor-element elementor-element-1831e0d elementor-section-content-bottom elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                                      data-id="1831e0d"
                                                      data-element_type="section"
                                                    >
                                                      <div className="elementor-container elementor-column-gap-extended">
                                                        <div
                                                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-57366a3 pxl-column-none"
                                                          data-id="57366a3"
                                                          data-element_type="column"
                                                        >
                                                          <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                              className="elementor-element elementor-element-b0a0d21 elementor-widget elementor-widget-pxl_heading"
                                                              data-id="b0a0d21"
                                                              data-element_type="widget"
                                                              data-widget_type="pxl_heading.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div
                                                                  id="pxl-pxl_heading-b0a0d21-4708"
                                                                  className="pxl-heading px-sub-title-default-style"
                                                                >
                                                                  <div className="pxl-heading--inner">
                                                                    <div
                                                                      className="pxl-item--subtitle px-sub-title-default wow fadeInRight"
                                                                      data-wow-delay="300ms"
                                                                    >
                                                                      <span className="pxl-item--subtext">
                                                                        INTELLIGENT
                                                                        DECISION
                                                                        MAKING
                                                                        <span className="pxl-item--subdivider"></span>
                                                                      </span>
                                                                    </div>
                                                                    <h3
                                                                      className="pxl-item--title wow fadeInRight"
                                                                      data-wow-delay="500ms"
                                                                    >
                                                                      As a &nbsp;
                                                                      <span className="pxl-title--highlight">
                                                                        <span className="pxl-item--text active">
                                                                          Business
                                                                          Firm
                                                                        </span>
                                                                      </span>
                                                                      &nbsp; We are
                                                                      ready to
                                                                      set Big
                                                                      Milestones
                                                                    </h3>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="elementor-element elementor-element-00a51d6 elementor-widget__width-auto elementor-widget elementor-widget-pxl_button"
                                                              data-id="00a51d6"
                                                              data-element_type="widget"
                                                              data-widget_type="pxl_button.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div
                                                                  id="pxl-pxl_button-00a51d6-6072"
                                                                  className="pxl-button wow fadeInRight"
                                                                  data-wow-delay="700ms"
                                                                >
                                                                  <a
                                                                    href="/about-us"
                                                                    className="btn pxl-icon-active btn-nina wow fadeInRight pxl-icon--left"
                                                                    data-wow-delay="700ms"
                                                                  >
                                                                    <span
                                                                      className="pxl--btn-text"
                                                                      data-text="Learn More"
                                                                    >
                                                                      <span>
                                                                        L
                                                                      </span>
                                                                      <span>
                                                                        e
                                                                      </span>
                                                                      <span>
                                                                        a
                                                                      </span>
                                                                      <span>
                                                                        r
                                                                      </span>
                                                                      <span>
                                                                        n
                                                                      </span>
                                                                      <span className="spacer">
                                                                        &nbsp;
                                                                      </span>
                                                                      <span>
                                                                        M
                                                                      </span>
                                                                      <span>
                                                                        o
                                                                      </span>
                                                                      <span>
                                                                        r
                                                                      </span>
                                                                      <span>
                                                                        e
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="elementor-element elementor-element-cd5e40e elementor-widget elementor-widget-spacer"
                                                              data-id="cd5e40e"
                                                              data-element_type="widget"
                                                              data-widget_type="spacer.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div className="elementor-spacer">
                                                                  <div className="elementor-spacer-inner"></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7b36a5 elementor-hidden-mobile pxl-column-none"
                                                          data-id="f7b36a5"
                                                          data-element_type="column"
                                                        >
                                                          <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                              className="elementor-element elementor-element-4224a92 elementor-widget elementor-widget-pxl_image"
                                                              data-id="4224a92"
                                                              data-element_type="widget"
                                                              data-widget_type="pxl_image.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div
                                                                  id="pxl_image-4224a92-3856"
                                                                  className="pxl-image-single style-default wow fadeInRight"
                                                                  data-wow-delay="700ms"
                                                                >
                                                                  <div
                                                                    className="pxl-item--inner"
                                                                    data-wow-delay="120ms"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--image"
                                                                      data-parallax-value=""
                                                                    >
                                                                      <img
                                                                        decoding="async"
                                                                        width="434"
                                                                        height="645"
                                                                        src="assets/wp-content/uploads/2023/03/h2-slider-banner1.png"
                                                                        className="no-lazyload attachment-full"
                                                                        alt=""
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </section>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pxl-swiper-slide">
                                              <div className="pxl-slider--inner elementor-repeater-item-93401d6">
                                                <div className="pxl-slider--mainimage pxl-image--kenburns wow">
                                                  <div
                                                    className="pxl-slider--image bg-image"
                                                    style={{
                                                      backgroundImage:
                                                        "url(assets/wp-content/uploads/2023/03/h2-bg-slider.jpg)",
                                                    }}
                                                  ></div>
                                                </div>
                                                <div
                                                  className="pxl-slider--overlay bg-image elementor-repeater-item-93401d6"
                                                  style={{
                                                    backgroundImage:
                                                      "url(assets/wp-content/uploads/2023/03/h2-slider-chart.png)",
                                                  }}
                                                ></div>
                                                <div className="pxl-slider--content content-align-flex-end">
                                                  <div
                                                    data-elementor-type="wp-post"
                                                    data-elementor-id="2809"
                                                    className="elementor elementor-2809"
                                                  >
                                                    <section
                                                      className="elementor-section elementor-top-section elementor-element elementor-element-a6fa896 elementor-section-content-bottom elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                                      data-id="a6fa896"
                                                      data-element_type="section"
                                                    >
                                                      <div className="elementor-container elementor-column-gap-extended">
                                                        <div
                                                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8978da8 pxl-column-none"
                                                          data-id="8978da8"
                                                          data-element_type="column"
                                                        >
                                                          <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                              className="elementor-element elementor-element-f6194ca elementor-widget elementor-widget-pxl_heading"
                                                              data-id="f6194ca"
                                                              data-element_type="widget"
                                                              data-widget_type="pxl_heading.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div
                                                                  id="pxl-pxl_heading-f6194ca-7731"
                                                                  className="pxl-heading px-sub-title-default-style"
                                                                >
                                                                  <div className="pxl-heading--inner">
                                                                    <div
                                                                      className="pxl-item--subtitle px-sub-title-default wow fadeInRight"
                                                                      data-wow-delay="300ms"
                                                                    >
                                                                      <span className="pxl-item--subtext">
                                                                        INTELLIGENT
                                                                        DECISION
                                                                        MAKING
                                                                        <span className="pxl-item--subdivider"></span>
                                                                      </span>
                                                                    </div>
                                                                    <h3
                                                                      className="pxl-item--title wow fadeInRight"
                                                                      data-wow-delay="500ms"
                                                                    >
                                                                      Convert &nbsp;
                                                                      <span className="pxl-title--highlight">
                                                                        <span className="pxl-item--text active">
                                                                          Business
                                                                        </span>
                                                                      </span>
                                                                      &nbsp; ideas into
                                                                      Great
                                                                      Opportunities
                                                                    </h3>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="elementor-element elementor-element-63cd34b elementor-widget__width-auto elementor-widget elementor-widget-pxl_button"
                                                              data-id="63cd34b"
                                                              data-element_type="widget"
                                                              data-widget_type="pxl_button.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div
                                                                  id="pxl-pxl_button-63cd34b-9353"
                                                                  className="pxl-button wow fadeInRight"
                                                                  data-wow-delay="700ms"
                                                                >
                                                                  <a
                                                                    href="../about-us/index.html"
                                                                    className="btn pxl-icon-active btn-nina wow fadeInRight pxl-icon--left"
                                                                    data-wow-delay="700ms"
                                                                  >
                                                                    <span
                                                                      className="pxl--btn-text"
                                                                      data-text="Learn More"
                                                                    >
                                                                      <span>
                                                                        L
                                                                      </span>
                                                                      <span>
                                                                        e
                                                                      </span>
                                                                      <span>
                                                                        a
                                                                      </span>
                                                                      <span>
                                                                        r
                                                                      </span>
                                                                      <span>
                                                                        n
                                                                      </span>
                                                                      <span className="spacer">
                                                                        &nbsp;
                                                                      </span>
                                                                      <span>
                                                                        M
                                                                      </span>
                                                                      <span>
                                                                        o
                                                                      </span>
                                                                      <span>
                                                                        r
                                                                      </span>
                                                                      <span>
                                                                        e
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="elementor-element elementor-element-d40224b elementor-widget elementor-widget-spacer"
                                                              data-id="d40224b"
                                                              data-element_type="widget"
                                                              data-widget_type="spacer.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div className="elementor-spacer">
                                                                  <div className="elementor-spacer-inner"></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-11c1110 elementor-hidden-mobile pxl-column-none"
                                                          data-id="11c1110"
                                                          data-element_type="column"
                                                        >
                                                          <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                              className="elementor-element elementor-element-5706742 elementor-widget elementor-widget-pxl_image"
                                                              data-id="5706742"
                                                              data-element_type="widget"
                                                              data-widget_type="pxl_image.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div
                                                                  id="pxl_image-5706742-2584"
                                                                  className="pxl-image-single style-default wow fadeInRight"
                                                                  data-wow-delay="700ms"
                                                                >
                                                                  <div
                                                                    className="pxl-item--inner"
                                                                    data-wow-delay="120ms"
                                                                  >
                                                                    <div
                                                                      className="pxl-item--image"
                                                                      data-parallax-value=""
                                                                    >
                                                                      <img
                                                                        decoding="async"
                                                                        width="619"
                                                                        height="654"
                                                                        src="assets/wp-content/uploads/2023/03/h2-slider-banner2.png"
                                                                        className="no-lazyload attachment-full"
                                                                        alt=""
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </section>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pxl-swiper-arrow-wrap">
                                        <div className="pxl-swiper-arrow pxl-swiper-arrow-prev">
                                          <i className="caseicon-angle-arrow-left rtl-icon"></i>
                                        </div>
                                        <div className="pxl-swiper-arrow pxl-swiper-arrow-next">
                                          <i className="caseicon-angle-arrow-right rtl-icon"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div id="pxl-main">
                          <div className="container">
                            <div className="row pxl-content-wrap no-sidebar">
                              <div
                                id="pxl-content-area"
                                style={{ padding: "15px" }}
                                className="pxl-content-area pxl-content-post col-12"
                              >
                                <main id="pxl-content-main">
                                  <article
                                    id="pxl-post-261"
                                    className="pxl---post post-261 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-consultancy tag-marketing tag-process"
                                  >
                                    <div className="pxl-item--holder">
                                      <div className="pxl-item--meta"></div>
                                      <div className="pxl-item--content clearfix">

                                        <h3
                                          className="color-primary"
                                          style={{ paddingTop: "4px", marginBottom: "19px", marginTop: '35px' }}
                                        >
                                          Calc Insights
                                        </h3>

                                        <p style={{marginBottom: "58px",textAlign:"left"}}>
                                          We are committed to going above and beyond in our efforts to locate the necessary data for our
                                          clients. We believe in building and nurturing long-term partnerships based on innovation,
                                          honesty, and industry knowledge. From data-only services to full-service consultancy, we offer a
                                          wide range of quantitative methodologies to ensure effective results and outcomes.
                                          We are a comprehensive market research agency that conducts projects worldwide. Our
                                          expertise lies in consumer and business-to-business markets, and we provide a wide range
                                          of qualitative and quantitative research services.

                                        </p>
                                        <h3 className="color-primary">Why choose Us?</h3>
                                        <p style={{ marginBottom: "58px",textAlign:"left" }}>
                                          We always go beyond to locate the necessary data by creating and fostering long-term
                                          partnerships based on innovation, honesty, and industry knowledge.
                                        </p>
                                        <h3 className="color-primary">We provide devoted, high-quality, and timely market research services</h3>
                                        <p style={{ marginBottom: "58px",textAlign:"left" }}>
                                          We employ rigorous methodologies to gather accurate data, conduct in-depth analysis, and
                                          deliver actionable insights. Our focus is on helping businesses make informed decisions,
                                          understand market trends, identify opportunities, and mitigate risks. With our comprehensive
                                          market research solutions, we empower organizations to stay ahead in their industries and
                                          drive strategic growth.

                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </main>
                              </div>
                            </div>
                          </div>
                        </div>


                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-cf9038d elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-full-content-with-space-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="cf9038d"
                          data-element_type="section"
                          data-settings='{"stretch_section":"section-stretched"}'
                        >
                          <div className="elementor-container elementor-column-gap-extended">
                            <div
                              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-207e770 pxl-column-none"
                              data-id="207e770"
                              data-element_type="column"
                              data-settings='{"background_background":"classNameic"}'
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-e6d4d1c elementor-widget elementor-widget-spacer"
                                  data-id="e6d4d1c"
                                  data-element_type="widget"
                                  data-widget_type="spacer.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f8cfe3f pxl-column-none"
                              data-id="f8cfe3f"
                              data-element_type="column"
                              data-settings='{"background_background":"classNameic"}'
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-9114ca0 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-pxl_image"
                                  data-id="9114ca0"
                                  data-element_type="widget"
                                  data-settings='{"_position":"absolute"}'
                                  data-widget_type="pxl_image.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      id="pxl_image-9114ca0-6340"
                                      className="pxl-image-single style-default wow fadeInDown"
                                      data-wow-delay="ms"
                                    >
                                      <div
                                        className="pxl-item--inner"
                                        data-wow-delay="120ms"
                                      >
                                        <div
                                          className="pxl-item--image"
                                          data-parallax-value=""
                                        >
                                          <img
                                            decoding="async"
                                            width="374"
                                            height="127"
                                            src="assets/wp-content/uploads/2023/03/h2-shape3.png"
                                            className="no-lazyload attachment-full"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-f33e287 elementor-widget elementor-widget-pxl_heading"
                                  data-id="f33e287"
                                  data-element_type="widget"
                                  data-widget_type="pxl_heading.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      id="pxl-pxl_heading-f33e287-5011"
                                      className="pxl-heading px-sub-title-default-style"
                                    >
                                      <div className="pxl-heading--inner">

                                        <h3
                                          className="pxl-item--title"
                                          data-wow-delay="200ms"
                                        >
                                          <span className="pxl-item--text">
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                Meet &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                Us &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                for &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                Best &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                projects &nbsp;
                                              </span>
                                            </span>

                                          </span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-f4629d9 elementor-widget elementor-widget-pxl_contact_form"
                                  data-id="f4629d9"
                                  data-element_type="widget"
                                  data-widget_type="pxl_contact_form.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      className="pxl-contact-form pxl-contact-form1 btn-w-auto wow fadeInUp"
                                      data-wow-delay="ms"
                                    >
                                      <div
                                        className="wpcf7 no-js"
                                        id="wpcf7-f2632-p25-o1"
                                        lang="en-US"
                                        dir="ltr"
                                      >
                                        <div className="screen-reader-response">
                                          <p
                                            role="status"
                                            aria-live="polite"
                                            aria-atomic="true"
                                          ></p>
                                          <ul></ul>
                                        </div>
                                        <form
                                          // action="/"
                                          // method="post"
                                          className="wpcf7-form init"
                                          aria-label="Contact form"
                                          noValidate
                                          data-status="init"
                                          onSubmit={e=>{handleSubmit(e)}}
                                        >
                                          <div style={{ display: "none" }}>
                                            <input
                                              type="hidden"
                                              name="_wpcf7"
                                              value="2632"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_version"
                                              value="5.7.5.1"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_locale"
                                              value="en_US"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_unit_tag"
                                              value="wpcf7-f2632-p25-o1"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_container_post"
                                              value="25"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_posted_data_hash"
                                              value=""
                                            />
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="pxl--item">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="your-name"
                                                  >
                                                    <input
                                                      size="40"
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Name*"
                                                      // value=""
                                                      type="text"
                                                      name="your-name"
                                                      value={name}
                                                      onChange={e=>{setName(e.target.value)}}
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="pxl--item">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="your-phone"
                                                  >
                                                    <input
                                                      size="40"
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Phone No*"
                                                      // value=""
                                                      type="number"
                                                      name="your-phone"
                                                      value={phone}
                                                      onChange={e=>{setPhone(e.target.value)}}
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                              <div className="pxl--item">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="your-email"
                                                  >
                                                    <input
                                                      size="40"
                                                      className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Company's mail*"
                                                      // value=""
                                                      type="email"
                                                      name="your-email"
                                                      value={email}
                                                      onChange={e=>{setEmail(e.target.value)}}
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              className="col-lg-6 col-md-6 col-sm-12"
                                            >
                                              <div className="pxl--item">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="your-subject"
                                                  ><input
                                                      size="40"
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Subject*"
                                                      // value=""
                                                      type="text"
                                                      name="your-subject"
                                                      value={subject}
                                                      onChange={e=>{setSubject(e.target.value)}}
                                                    /></span>
                                                </p>
                                              </div>
                                            </div>

                                          </div>
                                          <div className="pxl--item">
                                            <p>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="your-message"
                                              >
                                                <textarea
                                                  cols="40"
                                                  rows="10"
                                                  className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  placeholder="Message*"
                                                  name="your-message"
                                                  value={message}
                                                  onChange={e=>{setMessage(e.target.value)}}
                                                ></textarea>
                                              </span>
                                            </p>
                                          </div>
                                          <SubmitPopUp open={open} setOpen={setOpen} />

                                          <div
                                            className="wpcf7-response-output"
                                            aria-hidden="true"
                                          ></div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-567e682 elementor-widget__width-auto elementor-absolute elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-pxl_image"
                                  data-id="567e682"
                                  data-element_type="widget"
                                  data-settings='{"_position":"absolute"}'
                                  data-widget_type="pxl_image.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      id="pxl_image-567e682-1623"
                                      className="pxl-image-single slide-top-to-bottom style-default"
                                      data-wow-delay="ms"
                                    >
                                      <div
                                        className="pxl-item--inner"
                                        data-wow-delay="120ms"
                                      >
                                        <div
                                          className="pxl-item--image"
                                          data-parallax-value=""
                                        >
                                          <img
                                            decoding="async"
                                            width="207"
                                            height="265"
                                            src="assets/wp-content/uploads/2023/03/h2-shape2.png"
                                            className="no-lazyload attachment-full"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-5db4be4 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="5db4be4"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-extended">
                            <div
                              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1c18ff7 pxl-column-none"
                              data-id="1c18ff7"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-d9be965 elementor-widget elementor-widget-pxl_heading"
                                  data-id="d9be965"
                                  data-element_type="widget"
                                  data-widget_type="pxl_heading.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      id="pxl-pxl_heading-d9be965-8381"
                                      className="pxl-heading px-sub-title-default-style"
                                    >
                                      <div className="pxl-heading--inner">
                                        <div
                                          className="pxl-item--subtitle px-sub-title-default wow fadeInUp"
                                          data-wow-delay="ms"
                                        >
                                          <span className="pxl-item--subtext">
                                            CLIENTS TESTIMNIAL
                                            <span className="pxl-item--subdivider"></span>
                                          </span>
                                        </div>
                                        <h3
                                          className="pxl-item--title"
                                          data-wow-delay="200ms"
                                        >
                                          <span className="pxl-item--text">
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                What &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                our &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                Clients &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                say &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                about &nbsp;
                                              </span>
                                            </span>
                                            <span className="pxl-text--slide">
                                              <span className="wow letter">
                                                Us
                                              </span>
                                            </span>
                                          </span>
                                        </h3>
                                        <div className="px-divider--wrap">
                                          <div className="px-title--divider px-title--divider1">
                                            <span></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-7323efa pxl-column-none"
                              data-id="7323efa"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-4da9463 elementor-widget elementor-widget-pxl_testimonial_carousel"
                                  data-id="4da9463"
                                  data-element_type="widget"
                                  data-widget_type="pxl_testimonial_carousel.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      className="pxl-swiper-sliders pxl-testimonial-carousel pxl-testimonial-carousel1 pxl-swiper-boxshadow pxl-testimonial-style1"
                                      data-cursor-drap="DRAG"
                                    >
                                      <div className="pxl-carousel-inner">
                                        <div
                                          className="pxl-swiper-container"
                                          dir="ltr"
                                          data-settings='{"slide_direction":"horizontal","slide_percolumn":"1","slide_mode":"slide","slides_to_show":"2","slides_to_show_xxl":"2","slides_to_show_lg":"2","slides_to_show_md":"2","slides_to_show_sm":"1","slides_to_show_xs":"1","slides_to_scroll":"2","arrow":"false","pagination":"true","pagination_type":"bullets","pagination_number":"true","autoplay":"true","pause_on_hover":"","pause_on_interaction":"true","delay":8000,"loop":"true","speed":500}'
                                        >
                                          <div className="pxl-swiper-wrapper">
                                            <div className="pxl-swiper-slide">
                                              <div
                                                className="pxl-item--inner wow fadeInRight"
                                                data-wow-delay="ms"
                                              >
                                                <div className="pxl-item--top">
                                                  <div className="pxl-item--star pxl-flex">
                                                    <label className="pxl-label pxl-mr-18">
                                                      Rating:
                                                    </label>
                                                    <div className="pxl-star--icon">
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--desc el-empty">
                                                    “Most designers live in some
                                                    sort of alternate reality,
                                                    creating unnecessary things
                                                    for each other. Then they
                                                    become trends.”
                                                  </div>
                                                </div>
                                                <div className="pxl-item--holder pxl-flex">
                                                  <div className="pxl-item--image pxl-mr-22">
                                                    <img
                                                      decoding="async"
                                                      className="no-lazyload"
                                                      src="assets/wp-content/uploads/2023/03/testimonial-01.png"
                                                      width="79"
                                                      height="79"
                                                      alt="testimonial-01"
                                                      title="testimonial-01"
                                                    />
                                                    <div className="pxl-item--icon">
                                                      <i className="flaticon-double-quotes"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--meta">
                                                    <h5 className="pxl-item--title el-empty">
                                                      Rachel Ballinger
                                                    </h5>
                                                    <div className="pxl-item--position el-empty">
                                                      CEO, Bizzbreak Inc.
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pxl-swiper-slide">
                                              <div
                                                className="pxl-item--inner wow fadeInRight"
                                                data-wow-delay="ms"
                                              >
                                                <div className="pxl-item--top">
                                                  <div className="pxl-item--star pxl-flex">
                                                    <label className="pxl-label pxl-mr-18">
                                                      Rating:
                                                    </label>
                                                    <div className="pxl-star--icon">
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--desc el-empty">
                                                    “Calc Insights specialised
                                                    divisions for personalised
                                                    service and access to the
                                                    most qualified talent. The
                                                    extra bells and whistles
                                                    added to it are amazing.”
                                                  </div>
                                                </div>
                                                <div className="pxl-item--holder pxl-flex">
                                                  <div className="pxl-item--image pxl-mr-22">
                                                    <img
                                                      decoding="async"
                                                      className="no-lazyload"
                                                      src="assets/wp-content/uploads/2023/03/testimonial-02.png"
                                                      width="79"
                                                      height="79"
                                                      alt="testimonial-02"
                                                      title="testimonial-02"
                                                    />
                                                    <div className="pxl-item--icon">
                                                      <i className="flaticon-double-quotes"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--meta">
                                                    <h5 className="pxl-item--title el-empty">
                                                      Kevin Miller
                                                    </h5>
                                                    <div className="pxl-item--position el-empty">
                                                      Manager, PPS
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pxl-swiper-slide">
                                              <div
                                                className="pxl-item--inner wow fadeInRight"
                                                data-wow-delay="ms"
                                              >
                                                <div className="pxl-item--top">
                                                  <div className="pxl-item--star pxl-flex">
                                                    <label className="pxl-label pxl-mr-18">
                                                      Rating:
                                                    </label>
                                                    <div className="pxl-star--icon">
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--desc el-empty">
                                                    “This is one of the BEST
                                                    THEMES I have ever worked
                                                    with. The extra bells and
                                                    whistles added to it are
                                                    amazing. Elementor features
                                                    add extra flavor.”
                                                  </div>
                                                </div>
                                                <div className="pxl-item--holder pxl-flex">
                                                  <div className="pxl-item--image pxl-mr-22">
                                                    <img
                                                      decoding="async"
                                                      className="no-lazyload"
                                                      src="assets/wp-content/uploads/2023/03/testimonial-03.png"
                                                      width="79"
                                                      height="79"
                                                      alt="testimonial-03"
                                                      title="testimonial-03"
                                                    />
                                                    <div className="pxl-item--icon">
                                                      <i className="flaticon-double-quotes"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--meta">
                                                    <h5 className="pxl-item--title el-empty">
                                                      Oakland Miller
                                                    </h5>
                                                    <div className="pxl-item--position el-empty">
                                                      Consultant
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pxl-swiper-slide">
                                              <div
                                                className="pxl-item--inner wow fadeInRight"
                                                data-wow-delay="ms"
                                              >
                                                <div className="pxl-item--top">
                                                  <div className="pxl-item--star pxl-flex">
                                                    <label className="pxl-label pxl-mr-18">
                                                      Rating:
                                                    </label>
                                                    <div className="pxl-star--icon">
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--desc el-empty">
                                                    “The best customer service
                                                    I've ever had. The themes
                                                    and demos are by far the
                                                    best and most modern around.
                                                    Not being an IT expert.”
                                                  </div>
                                                </div>
                                                <div className="pxl-item--holder pxl-flex">
                                                  <div className="pxl-item--image pxl-mr-22">
                                                    <img
                                                      decoding="async"
                                                      className="no-lazyload"
                                                      src="assets/wp-content/uploads/2023/03/author1-79x79.png"
                                                      width="79"
                                                      height="79"
                                                      alt="author1"
                                                      title="author1"
                                                    />
                                                    <div className="pxl-item--icon">
                                                      <i className="flaticon-double-quotes"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--meta">
                                                    <h5 className="pxl-item--title el-empty">
                                                      Laura Chob
                                                    </h5>
                                                    <div className="pxl-item--position el-empty">
                                                      Formar Manager
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pxl-swiper-slide">
                                              <div
                                                className="pxl-item--inner wow fadeInRight"
                                                data-wow-delay="ms"
                                              >
                                                <div className="pxl-item--top">
                                                  <div className="pxl-item--star pxl-flex">
                                                    <label className="pxl-label pxl-mr-18">
                                                      Rating:
                                                    </label>
                                                    <div className="pxl-star--icon">
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                      <i className="caseicon-star pxl-mr-5"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--desc el-empty">
                                                    “A trust is an ideal way for
                                                    individuals to transfer
                                                    assets either during life or
                                                    after their passing. At a
                                                    basic level, a trust is a
                                                    separate.”
                                                  </div>
                                                </div>
                                                <div className="pxl-item--holder pxl-flex">
                                                  <div className="pxl-item--image pxl-mr-22">
                                                    <img
                                                      decoding="async"
                                                      className="no-lazyload"
                                                      src="assets/wp-content/uploads/2023/03/author2-79x79.png"
                                                      width="79"
                                                      height="79"
                                                      alt="author2"
                                                      title="author2"
                                                    />
                                                    <div className="pxl-item--icon">
                                                      <i className="flaticon-double-quotes"></i>
                                                    </div>
                                                  </div>
                                                  <div className="pxl-item--meta">
                                                    <h5 className="pxl-item--title el-empty">
                                                      Julie Sweet
                                                    </h5>
                                                    <div className="pxl-item--position el-empty">
                                                      Finance Consultant
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pxl-swiper-dots style-1"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
        <a className="pxl-scroll-top" href="#">
          <i className="caseicon-long-arrow-right-three"></i>
        </a>
        <div className="pxl-cursor pxl-js-cursor style-default">
          <div className="pxl-cursor-wrapper">
            <div className="pxl-cursor--follower pxl-js-follower"></div>
            <div className="pxl-cursor--label pxl-js-label"></div>
            <div className="pxl-cursor--drap pxl-js-drap"></div>
            <div className="pxl-cursor--icon pxl-js-icon"></div>
          </div>
        </div>
        <div className="pxl-cursor-section"></div>
        <div id="pxl-page-popup" className="">
          <div className="pxl-popup-overlay"></div>
          <div className="pxl-page-popup-content"></div>
        </div>
      </div>
    </div>
  );
}
