import React from 'react'
import styles from "./gdpr.module.css"

function Gdpr() {
    return (
        <div className={styles["container"]}>
            <h1>GDPR Policy</h1>

            <h3>INTRODUCTION</h3>
            <p>
                This document lays out Calc Insights Private Limited’s GDPR policy.
            </p>

            <h3>PURPOSE</h3>
            <p>
                This Policy underscores Calc Insights' unwavering dedication to upholding and safeguarding the
                privacy and Personal Data of its employees, suppliers, customers, business partners, clients,
                and their respective end customers. It outlines the necessary safeguards that will be
                implemented by Calc Insights in the processing of Personal Data
            </p>

            <h3>SCOPE</h3>
            <p>
                This Policy applies to Calc Insights’s parent company, related affiliates/subsidiaries, and third
                parties who process Personal Data on behalf of those entities whenever those entities process
                Personal Data from Data Subjects who reside in the European Economic Area.
            </p>

            <h3>
                DEFINITIONS
            </h3>

            <p>
                Consent of the Data Subject: Any freely given, specific, informed, and
                unambiguous indication of will, whereby the Data Subject agrees to the Processing of Personal
                Data about and/or relating to him or her. Consent shall be evidenced by written, electronic, or
                recorded means. It may also be given on behalf of the Data Subject by an agent specifically
                authorized by the Data Subject to do so.
            </p>

            <p>
                <strong>Controller: </strong>
                A person or organization who alone or jointly with others determines the purposes
                and means of Processing Personal Data. Data Subject: An individual whose Personal Data is
            </p>

            <p>
                <strong>Processed: </strong>
                Filing System: Any structured set of personal data which are accessible according
                to specific criteria, in such a way that specific information relating to a particular person is readily
                accessible.
            </p>

            <p>
                <strong>Information and Communications System: </strong>
                A system for generating, sending, receiving,
                storing or otherwise Processing electronic data messages or electronic documents and includes
                the computer system or other similar device by or which data is recorded, transmitted, or stored
                and any procedure related to the recording, transmission, or storage of electronic data,
                electronic message, or electronic document.
            </p>

            <p>
                <strong>Personal Data: </strong>
                Any information relating to an identified or identifiable data subject who can be
                identified, directly or indirectly, from that information.
            </p>

            <p>
                <strong>Personal Data Breach: </strong>
                A breach of security leading to the accidental or unlawful destruction,
                loss, alteration, unauthorized disclosure of, or access to, Personal Data transmitted, stored, or
                otherwise Processed.
            </p>

            <p>
                <strong>Processor: </strong>
                Any person (other than the staff of the Controller) or organization that Processes
                Personal Data on behalf of a Controller. A group company that Processes Personal Data for the
                Controller will be a Processor.
            </p>

            <p>
                <strong>Process: </strong>
                Any operation or any set of operations performed upon Personal Data including, but
                not limited to, the collection, recording, organization, storage, updating or modification, retrieval,
                consultation, use, consolidation, blocking, erasure, or destruction of data.
            </p>

            <h3>Special Category of Personal Data</h3>

            <ol>
                <li>About an individual’s race, ethnic origin, marital status, age, color, and religious,
                    philosophical, or political affiliations</li>
                <li>
                    About an individual’s health, education, genetic, or sexual life, or to any proceeding for any
                    offense committed or alleged to have been committed by such person, the disposal of such
                    proceedings, or the sentence of any court in such proceedings.
                </li>
            </ol>

            <h3>ENFORCEMENT</h3>

            <p>
                <strong>Policy Compliance: </strong>
                Calc Insights expects all directors, executives, employees, and agents will
                comply with this Policy
            </p>

            <p>
                <strong>Monitoring:: </strong>
                Calc Insights ensures that all requirements contained in this Policy are properly
                implemented by:
                <ol>
                    <li>
                        Providing evidence of compliance on an annual interval to Abhishek Singh;
                    </li>
                    <li>
                        Reviewing submitted evidence to determine whether submitted evidence complies with this
                        Policy; and
                    </li>
                    <li>
                        Auditing Steps 1 and 2 at least once a year
                    </li>
                </ol>
            </p>

            <p>
                <strong>
                    Sanctions:
                </strong>
                Non-compliance is defined as any one or more of the following:
                <ol>
                    <li>
                        Any act that infringes on this Policy whether by negligence or willful misconduct;
                    </li>
                    <li>
                        Unauthorized Processing of Personal Data;
                    </li>
                    <li>
                        Using hardware, software, communication networks and equipment, or Personal Data for illicit
                        purposes which may infringe local laws or regulations; or
                    </li>
                    <li>
                        Acts exposing Calc Insights to actual or potential monetary loss, regulatory censure, or
                        reputational damage.
                    </li>
                </ol>
            </p>

            <p>
                Any infringement of this Policy may be treated as serious misconduct. Sanctions may include
                termination of employment or other contractual arrangement, and civil or criminal prosecution in
                accordance with applicable laws and regulation.
            </p>

            <h3>
                TRAINING
            </h3>

            <p>
                Calc Insights will provide regular privacy and data protection training to its employees who
                Process Personal Data or develop tools used to Process Personal Data. Such training will raise
                awareness about this Policy and requirements contained herein.
            </p>

            <h3>
                GENERAL PRIVACY PRINCIPLES
            </h3>

            <p>
                Generally, the requirements in this section will apply to Calc Insights whenever it Processes
                Personal Data.
            </p>

            <p>
                <strong>Principles Related toCalc Insights’s Processing of Personal Data: </strong>
                Whenever GCalc Insights
                Processes Personal Data, Personal Data will be:
                <ol>
                    <li>
                        Processed lawfully, fairly, and in a transparent manner in relation to the Data Subject;
                    </li>
                    <li>
                        Collected for specified, explicit, and legitimate purposes and not further processed in a
                        manner incompatible with those purposes;
                    </li>
                    <li>
                        Adequate, relevant, and limited to what is necessary in relation to the purposes for which the
                        Personal Data is Processed;
                    </li>
                    <li>
                        Accurate and kept up to date when necessary. If Calc Insights retains Personal Data that is
                        inaccurate, and the purpose for gathering the document requires accuracy, Calc Insights will
                        take timely and reasonable steps to erase or rectify the inaccurate Personal Data;
                    </li>
                    <li>
                        Kept in a form which permits identification of Data Subjects for no longer than is necessary
                        when taking into consideration the purpose for which the Personal Data is Processed; and
                    </li>
                    <li>
                        Processed in a manner that ensures appropriate security of the Personal Data, including
                        protection against unauthorized or unlawful Processing and against accidental loss, destruction,
                        or damage, using appropriate technical or organizational measures.
                    </li>
                </ol>
            </p>

            <p>
                Calc Insights must be able to demonstrate compliance with the six principles above.
            </p>

            <p>
                <strong>
                    Lawfulness of Processing:
                </strong>
                Calc Insights will only Process Personal Data lawfully. The
                following lays out the scenarios wherein Processing is lawful:
                <ol>
                    <li>
                        The Data Subject has given consent to the Processing of their Personal Data for one or more
                        specific purposes.
                    </li>
                    <li>
                        Processing is necessary for the performance of a contract to which the Data Subject is party
                        or in order to take steps at the request of the Data Subject prior to entering into a contract;
                    </li>
                    <li>
                        Processing is necessary for compliance with a legal obligation to which Calc Insights is
                        subject
                    </li>
                    <li>
                        Processing is necessary in order to protect the vital interests of the Data Subject or of another
                        natural person;
                    </li>
                    <li>
                        Processing is necessary for the performance of a task carried out in the public interest or in
                        the exercise of official authority vested in Calc Insights; and
                    </li>
                    <li>
                        Processing is necessary for Calc Insights to pursue its legitimate interest. Calc Insights will
                        not rely on this lawful basis for Processing if a Data Subject’s interests or fundamental rights
                        and freedoms override Calc Insights’s legitimate interests.
                    </li>
                </ol>
            </p>

            <p>
                <strong>
                    Determining Whether Processing Exceeds the Initial Purpose for Processing Personal
                    Data:
                </strong>
                When Calc Insights needs to determine whether additional Processing exceeds the
                purpose of initial Processing, Calc Insights will weigh the following:
                <ol>
                    <li>
                        Any link between the purpose for collecting Personal Data and the purpose for additional
                        Processing;
                    </li>
                    <li>
                        The context in which the Personal Data has been collected while weighing the relationship
                        between Data Subjects and Calc Insights;
                    </li>
                    <li>
                        The nature of the Personal Data; including, whether the Personal Data is a Special Category
                        of Personal Data or Personal Data regarding criminal convictions;
                    </li>
                    <li>
                        The consequences of additional Processing; and
                    </li>
                    <li>
                        The existence of appropriate safeguards such as encryption or pseudonymization
                    </li>
                </ol>
            </p>

            <p>
                <strong>
                    Consent:
                </strong>
                When the purpose for Processing is based on consent, Calc Insights will demonstrate
                that the Data Subject consented to Processing his or her Personal Data. The consent must be
                presented by Calc Insights to the Data Subject separate from other matters, in an easily
                accessible form, and in clear and plain language. Pre-ticked boxes or silence do not constitute
                valid consent. Calc Insights will allow consenting Data Subjects to withdraw their consent to
                Process Personal Data at any time in a manner that is as easy to exercise as it was to give
                consent in the first place. Consent should only be used as a basis for Processing where no
                other basis is applicable
            </p>

            <p>
                <strong>
                    Consent from Children:
                </strong>
                Whenever Calc Insights Processes Personal Data from a Data
                Subject who is below the age of 18 on the basis of consent, Calc Insights will get consent to
                Process from such Data Subject’s parent or legal guardian. Calc Insights will take reasonable
                efforts to verify such parent or legal guardian has or holds parental responsibility over the Data
                Subject.
            </p>

            <p>
                <strong>
                    Processing Special Categories of Personal Data:
                </strong>
                Calc Insights may Process Special
                Categories of Personal Data when:
                <ol>
                    <li>
                        The Data Subject has given explicit consent for one or more specified purposes;
                    </li>
                    <li>
                        Processing is necessary for the purposes of carrying out Calc Insights’s or Data Subject’s
                        specific rights for employment, social security, or social protection;
                    </li>
                    <li>
                        Processing is necessary to protect the Data Subject’s vital interests or of another natural
                        person where the Data Subject is physically or legally incapable of giving consent;
                    </li>
                    <li>
                        Processing is carried out while engaging in legitimate activities with appropriate safeguards
                        by a foundation, association, or any other not-for-profit body with a political, philosophical,
                        religious, or trade union aim and on condition that the Processing relates solely to the members
                        or to former members of the body or to persons who have regular contact with it in connection
                        with its purposes and that Personal Data will not be disclosed outside that body without consent
                        from the Data Subjects;
                    </li>
                    <li>
                        Processing relates to Personal Data which the Data Subject made public;
                    </li>
                    <li>
                        Processing is necessary for the establishment, exercise, or defense of legal claims or upon
                        an order from a court;
                    </li>
                    <li>
                        Processing is necessary for a substantial public interest and based on laws in the European
                        Economic Area or its member states’ laws;
                    </li>
                    <li>
                        Processing is necessary for preventive or occupational medicine, for assessment of the
                        working capacity of an employee, medical diagnosis, the provision of health or social care or
                        treatment, or the management of health or social care systems and services based on laws inthe European Economic Area or its member states’ laws or pursuant to a contract with a health
                        professional. Processing in this instance may be Processed under the responsibility of a
                        professional subject to the obligation of professional secrecy pursuant to laws in the European
                        Economic Area or its member states’ laws
                    </li>
                    <li>
                        Processing is necessary for reasons of public interest in the area of public health; or
                    </li>
                    <li>
                        Processing is necessary for archiving purposes in the public interest, scientific or historical
                        research purposes, or statistical purposes pursuant to laws in the European Economic Area or
                        its member states’ laws.
                    </li>
                </ol>
            </p>

            <p>
                These conditions for Processing Special Categories of Personal Data are generally set out in
                more detail in each EU member state’s national laws.
            </p>

            <p>
                <strong>Processing Personal Data Relating to Criminal Convictions and Offences:</strong>
                Calc Insights
                will only Process Personal Data relating to criminal convictions and offences under the control of
                an official authority or when Processing is authorized by the laws of the European Union or its
                member states’.
            </p>

            <p>
                <strong>Processing Which does not Require Identification: </strong>
                If Calc Insights Processes information
                which does not require the identification of a Data Subject, Calc Insights does not need to
                maintain, acquire, or Process additional information to identify a Data Subject. If Calc Insights
                cannot identify a Data Subject, Calc Insights will inform the Data Subject accordingly. In such
                instances, a Data Subject’s right to access, rectification, erasure, restriction of Procession,
                notification regarding rectification or erasures, and data portability do not apply.
            </p>

            <h3>CONTROLLER REQUIREMENTS</h3>

            <p>
                Whenever Calc Insights acts as a Controller of Personal Data, the requirements in this section
                will apply.
            </p>

            <p>
                <strong>
                    General Responsibilities:
                </strong>
                Taking into account the nature, scope, context, and purposes of
                Processing Personal Data as well as the risks of varying likelihood and severity for the rights
                and freedoms of natural persons, Calc Insights will implement appropriate technical and
                organizational measures to ensure and demonstrate that Processing is performed in
                accordance with the GDPR. Calc Insights will review and update those measures when
                necessary.
            </p>

            <p>
                <h3>Notice and Transparency Requirement: </h3>
                Whenever Calc Insights Processes Personal Data, it
                will provide Data Subjects with a notice. Calc Insights will provide this notice at the time they
                obtain the Personal Data from the Data Subject. If Calc Insights obtains the data from a source
                other than the Data Subject, Calc Insights will provide this notice to the Data Subject no later
                than one month after obtaining the Personal Data. Such notice will include:
                <ol>
                    <li>Calc Insights’s identity and contact details and where applicable, its representative;</li>
                    <li>The contact details for Calc Insights’s data protection officer, where applicable;</li>
                    <li>The purpose and legal basis for Processing the Personal Data;</li>
                    <li>Where applicable, the legitimate interests pursued by Calc Insights or a third party;</li>
                    <li>Where the Personal Data was obtained from a source other than the Data Subject, the categories of Personal Data;</li>
                    <li>A list of recipients or categories of recipients who receive Personal Data, if any;</li>
                    <li>Where applicable, Calc Insights’s intent to transfer Personal Data to a third country or international organization and the following: <br />
                        <ol type="a">
                            <li>The existence of an adequacy decision by the European Commission, or</li>
                            <li>References to appropriate or suitable safeguards and the means by which to obtain a copy of them or where they have been made available;</li>
                        </ol>
                    </li>
                    <li>The period for which the personal data will be stored, or if that is not possible the criteria used to determine that period;</li>
                    <li>The existence of the right to request access to and rectify or erase Personal Data, or restrict Calc Insights’s Processing of Personal Data;</li>
                    <li>Where Processing is based on consent, the existence of the right to withdraw consent at any time;</li>
                    <li>The right to lodge a complaint with a supervisory authority;</li>
                    <li>Whether the provision of Personal Data is statutory or a contractual requirement, or a requirement necessary to enter into a contract, as well as whether the Data Subject is obliged to provide the Personal Data and of the possible consequence of failure to provide such data;</li>
                    <li>If Calc Insights intends to Process Personal Data for purposes that extend beyond the initial purpose for Processing Calc Insights will provide the information above before additional Processing takes place.</li>
                </ol>
            </p>

            <p>
                Where the Data Subject is a child, the notice should be written in clear and plain language that a
                child will understand.
            </p>

            <p><strong>Data Protection by Design and Default:</strong> Calc Insights will, at the time of determining how it will Process Personal Data and at the time of Processing such data implement appropriate technical and organizational measures designed to implement data-protection principles.</p>

            <p><strong>Data Protection Impact Assessments:</strong> Calc Insights will carry out a Data Protection Impact Assessment prior to any new Processing. Calc Insights will implement appropriate technical and organizational measures to ensure that, by default, only the Personal Data that are necessary for each specific purpose are Processed.</p>

            <p><strong>Joint Controllers:</strong> When Calc Insights is a Controller with one or more other Controllers, they will determine and document their respective responsibilities for compliance under the GDPR, including when a Data Subject wishes to exercise their privacy rights. Calc Insights must be able to explain the above</p>

            <p><strong>Purpose Limitation and Data Minimization Requirement:</strong> Personal Data must be collected for specific and legitimate purposes determined and declared before, or as soon as reasonably practicable after collection, and later Processed in a way compatible with such declared, specified, and legitimate purposes only.</p>

            <p>
                <strong>Personal Data must be Processed fairly and lawfully.
                </strong> Personal Data must be accurate,
                relevant, and—where necessary for purposes for which it is to be used—kept up to date.
                Calc Insights must rectify, restrict Processing, supplement, or destroy inaccurate or incomplete
                Personal Data.
            </p>

            <p>
                Calc Insights will only keep Data Subjects’ Personal Data so long as it is necessary for the
                purpose for which the data was originally Processed.
            </p>

            <p>
                Processing Procedures Requirement: Calc Insights will implement and review:
                <ol>
                    <li>A procedure for the collection of Personal Data, including procedures for obtaining consent, when applicable;</li>
                    <li>Procedures that limit the Processing of Personal Data to ensure Processing is only to the extent necessary for the declared, specified, and legitimate purpose;</li>
                    <li>Policies for access management, system monitoring, and protocols to follow during security incidents or technical problems;</li>
                    <li>Policies and procedures for Data Subjects to exercise their rights under local law or regulation; and</li>
                    <li>A data retention schedule, including timeline or conditions for erasure or disposal of records</li>
                </ol>
            </p>

            <p><strong>Data Quality and Proportionality Requirement:</strong> Calc Insights will Process Personal Data in a manner ensuring data quality and appropriate privacy and security safeguards. Inaccurate or incomplete Personal Data must be rectified, restricted from further Processing, supplemented, or destroyed.</p>
            <p><strong>Data Processing Contracts:</strong> Calc Insights may engage Processors to Process Personal Data on its behalf if:
                <ol>
                    <li>The Processor provides sufficient guarantees to implement appropriate technical and organizational measures to protect the rights of Data Subjects and comply with the GDPR.</li>
                    <li>The Processor agrees not to engage another Processor without prior specific or general written authorization from Calc Insights. In such instances, the Processor must tell Calc Insights about any changes and give Calc Insights an opportunity to object to such changes.</li>
                    <li>Calc Insights and Processor enter into a contract setting out:
                        <ol type="a">
                            <li>What categories of Personal Data will be Processed;</li>
                            <li>The duration of Processing;</li>
                            <li>The nature and purpose of Processing;</li>
                            <li>The type of Personal Data;</li>
                            <li>The categories of Data Subjects involved;</li>
                        </ol>
                    </li>
                </ol>
            </p>

            <p><strong>Processing under the Authority of Calc Insights:</strong> Processors and any person acting under the authority of the Calc Insights or the Processor, who have access to Personal Data, can only Process data with instructions from Calc Insights.</p>
            <p><strong>Record of Processing Activities:</strong> Calc Insights and, where applicable, its representative in the European Economic Area, will maintain records describing its Processing activities. Records will include:</p>
            <p><strong>Cooperation with Supervisory Authorities:</strong> Calc Insights will cooperate, on request and when applicable, with supervisory authorities while administering this policy.</p>
            <p><strong>Security of Processing:</strong> Calc Insights’s security program will evaluate, where appropriate, the following:
                <ol>
                    <li>The nature, scope, context, and purpose of Processing; and</li>
                    <li>Risks (such as unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data transmitted, stored, or otherwise Processed), varying likelihoods, and severity of an impact on the freedoms of natural persons residing in the European Economic Area should a risk occur.</li>
                </ol>
            </p>
            <p><strong>Communication of a Personal Data Breach to the Data Subject:</strong> When a Personal Data Breach is likely to result in a high risk to the rights and freedoms of a natural person residing in the European Economic Area, Calc Insights will communicate the Personal Data Breach to the Data Subject without undue delay.</p>

            <p><strong>Data Protection Officer Tasks:</strong> The DPO will be Calc Insights’s point of contact for all Data Subject issues related to the Processing of Personal Data or the exercise of rights under the GDPR. The DPO will, at a minimum, perform the following tasks, taking into account the risk of Processing operations and the nature, scope, context, and purposes of Processing:
                <ol>
                    <li>Inform and advise Calc Insights and its employees of their Processing obligations under the GDPR and other European Union or member state data protection provisions;</li>
                    <li>Monitor Calc Insights's compliance with the GDPR, EU or member states data protection provisions, and Calc Insights’s policies in relation to protection of Personal Data. Under this monitoring, the DPO will assign data protection responsibilities, train and raise awareness for the staff involved in Processing operations, and conduct the necessary audits;</li>
                    <li>Provide advice related to Data Protection Impact Assessments, as requested, and monitor the performance of Data Protection Impact Assessments;</li>
                    <li>Cooperate with the supervisory authority(ies); and</li>
                    <li>Act as the contact point for the supervisory authority on issues relating to Processing, and consult with the supervisory authority as required on any other matter.</li>
                </ol>
            </p>

            <p><strong>International Data Transfers Requirements:</strong> Calc Insights will ensure that any transfer of Personal Data to a third country outside the European Economic Area or an international organization does not undermine the level of protection guaranteed by the GDPR. The transfer of Personal Data to a third country or an international organization may only take place where:
                <ol>
                    <li>The European Commission has decided that a third country or organization’s protections provide an adequate level of protection (including Privacy Shield);</li>
                    <li>Calc Insights has provided appropriate safeguards and enforceable rights and effective legal remedies are available to Data Subjects;</li>
                    <li>The supervisory authority has approved binding corporate rules; or</li>
                    <li>The GDPR permits a specific exemption. Specific exemptions include:
                        <ol type="a">
                            <li>The data subject has explicitly consented to the transfer;</li>
                            <li>The transfer is necessary for the performance of a contract between the Data Subject and Controller;</li>
                            <li>The transfer is necessary for important reasons of public interest; or</li>
                            <li>The transfer is not repetitive, concerns only a limited number of Data Subjects, is necessary for the purposes of compelling legitimate interests pursued by the Controller, and the Controller has provided suitable safeguards with regard to the protection of Personal Data.</li>
                        </ol>
                    </li>
                </ol>
            </p>

            <p><strong>International Data Transfers via Appropriate Safeguards:</strong> Calc Insights may only transfer Personal Data to a third country or international organization under this article if Calc Insights provides appropriate safeguards and on the condition that enforceable rights and effective legal remedies are available to Data Subjects.</p>
            <p>Appropriate safeguards may be provided by contractual clauses between Calc Insights and the recipient of the Personal Data in the third country or international organization, subject to authorization from the competent supervisory authority.</p>
            <p>Appropriate safeguards may also be provided via one of the following:
                <ol>
                    <li>A binding, enforceable instrument between public authorities or parties;</li>
                    <li>Binding corporate rules;</li>
                    <li>The use of standard data protection clauses adopted by the European Commission or the supervisory authority;</li>
                    <li>Use of a code of conduct approved by associations or other bodies representing Processors; or</li>
                    <li>Use of an approved data protection certification mechanisms.</li>
                </ol>
            </p>

            <h3>
                PROCESSOR REQUIREMENTS
            </h3>

            <p><strong>Whenever Calc Insights acts as a Processor, the following Requirements will apply:</strong></p>
            <ul>
                <li><strong>Controller-Processor Relationship Requirement:</strong> Calc Insights will comply with local privacy laws and regulations, contractual privacy obligations with the Controller, or other legal acts with a Controller.</li>
                <li><strong>Processing under the Authority of a Data Controller:</strong> Any person acting under the authority of the Calc Insights can only Process data with instructions from the Controller.</li>
            </ul>

            <p><strong>Data Processing Contracts:</strong> Calc Insights may engage Processors to Process Personal Data on its behalf if:
                <ol>
                    <li>The Processor provides sufficient guarantees to implement appropriate technical and organizational measures to protect the rights of Data Subjects and comply with the GDPR.</li>
                    <li>The Processor agrees not to engage another Processor without prior specific or general written authorization from Calc Insights. In such instances, the Processor must tell Calc Insights about any changes and give Calc Insights an opportunity to object to such changes.</li>
                    <li>Calc Insights and Processor enter into a contract setting out:
                        <ol type="a">
                            <li>What categories of Personal Data will be Processed;</li>
                            <li>The duration of Processing;</li>
                            <li>The nature and purpose of Processing;</li>
                            <li>The type of Personal Data;</li>
                            <li>The categories of Data Subjects involved; and</li>
                            <li>Calc Insights’s rights.</li>
                        </ol>
                    </li>
                </ol>
            </p>

            <p><strong>Cooperation with Supervisory Authorities:</strong> Calc Insights will cooperate, on request and when applicable, with supervisory authorities while administering this Policy.</p>
            <p><strong>Security of Processing:</strong> Calc Insights’s security program will evaluate, where appropriate, the following:
                <ol>
                    <li>The nature, scope, context, and purpose of Processing; and</li>
                    <li>Risks (such as unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data transmitted, stored, or otherwise Processed), varying likelihoods, and severity of an impact on the freedoms of natural persons residing in the European Economic Area should a risk occur.</li>
                </ol>
            </p>
            <p>Calc Insights’s technical and organizational measures will implement, where appropriate to the risk, the following:
                <ol>
                    <li>Pseudonymization and encryption of Personal Data;</li>
                    <li>The ability to protect the ongoing confidentiality, integrity, availability, and resilience of Processing systems and services;</li>
                    <li>The ability to restore the availability and access to Personal Data in a timely manner in the event of a physical or technical incident; and</li>
                    <li>A Process for regularly testing, assessing, and evaluating the effectiveness of technical and organizational measures for ensuring the security of Processing.</li>
                </ol>
            </p>

            <p><strong>Record of Processing Activities:</strong> Calc Insights and, where applicable, its representative in the European Economic Area, will maintain records describing its Processing activities. Records will include:
                <ol>
                    <li>Calc Insights's name, Calc Insights’s representative, and Calc Insights’s data protection officer;</li>
                    <li>The name, representative and data protection officer of each Controller on behalf of which Calc Insights is Processing Personal Data.</li>
                    <li>Categories of Personal Data being Processed on behalf of each Controller;</li>
                    <li>Whether the Personal Data transfers to a third country or an entity outside the European Economic Area) that will be involved in the Processing and if so the country in question and the safeguards in place; and</li>
                    <li>A general description of the organizational, physical, or technical security measures in Place.</li>
                </ol>
            </p>

            <p><strong>DATA SUBJECTS’ RIGHTS</strong></p>
            <p>After verifying a Data Subject’s identity, Calc Insights will take appropriate measures to provide any information referred to in this section using concise, transparent, intelligible, and easily accessible form, using clear and plain language.</p>
            <p>Calc Insights will provide information when a Data Subject requests to exercise their rights listed in this section without undue delay and in any event within one month of receipt of the request. That period may be extended by two further months where necessary, taking into account the complexity and number of the requests. Calc Insights will inform the Data Subject of any such extension within one month of receipt of the request.</p>
            <p>If Calc Insights does not take action on the request of the Data Subject, Calc Insights will inform the Data Subject without delay and at the latest within one month of receiving the Data Subject’s request of the reasons for not taking action and inform the Data Subject that they may lodge a complaint with a supervisory authority.</p>
            <p>Calc Insights will not charge Data Subjects for receiving information for exercising their rights in this section, but when a Data Subject makes manifestly unfounded or excessive requests, Calc Insights may charge a reasonable fee; or refuse to act on the request.</p>

            <p><strong>Right of Access Requirement:</strong> Upon the request of a Data Subject, Calc Insights will confirm whether Personal Data concerning the Data Subject is being Processed, and where that is the case, Calc Insights shall give the Data Subject access to their Personal Data and the following information:</p>


            <p><strong>Rectification Requirement:</strong> Calc Insights will allow Data Subjects to rectify inaccuracies or errors in the Data Subject’s Personal Data.</p>
            <p>The Data Subject also has the right, taking into account the purposes for Processing, to have incomplete Personal Data completed, including by means of providing a supplementary statement.</p>
            <p>Calc Insights will suspend, withdraw, or order the blocking, removal, or destruction of the Data Subject’s Personal Data from Calc Insights’s filing system upon discovery and substantial proof that the Personal Data is incomplete, outdated, false, unlawfully obtained, used for unauthorized purposes, or are no longer necessary for the purposes for which they were collected.</p>
            <p>When the Data Subject’s Personal Data is corrected, Calc Insights will allow the Data Subject to access the new and retracted Personal Data and provide the Data Subject with receipts of the new and retracted Personal Data. Calc Insights will communicate any rectification of Personal Data to each third-party recipient to whom the Personal Data has been disclosed. Calc Insights will inform the Data Subject of these third-party recipients if the Data Subject requests it.</p>


            <p><strong>Erasure or Blocking Requirement:</strong> Data Subjects will have the right to obtain from the Controller the erasure of their Personal Data from Calc Insights’s filing system without undue delay if one of the following applies:
                <ol>
                    <li>A Data Subject’s Personal Data is no longer necessary for the purposes for which that Personal Data was collected;</li>
                    <li>A Data Subject withdraws consent, where Processing is on the basis of consent, or objects to Calc Insights’s Processing of the Data Subject’s Personal Data and where there is no other legal ground for Processing;</li>
                    <li>The Data Subject objects to the Processing on the basis of automated decision making or direct marketing purposes and there are no overriding legitimate grounds for Processing;</li>
                    <li>Calc Insights’s Processing of a Data Subject’s Personal Data is unlawful;</li>
                    <li>The Personal Data has to be erased for compliance with a legal obligation in the European Economic Area or the laws of one if its member states to which the controller is subject;</li>
                    <li>The Personal Data has been collected in relation to the offer of information society services to an individual under the age of 18.</li>
                </ol>
            </p>
            <p>If Calc Insights has made the Personal Data subject to erasure public, Calc Insights shall take reasonable steps, including technical measures, to inform controllers which are Processing the Personal Data that the Data Subject has requested the erasure of any links to or copies of their Personal Data.</p>
            <p>Calc Insights will communicate any erasure of Personal Data to each third-party recipient to whom the Personal Data has been disclosed. Calc Insights will inform the Data Subject of these third-party recipients if the Data Subject requests it.</p>

            <p><strong>Right to Restrict Processing:</strong> A Data Subject has the right to restrict Calc Insights’s Processing if one of the following applies:
                <ol>
                    <li>The accuracy of the Personal Data is contested by the Data Subject, for a period enabling Calc Insights to verify the accuracy of the Personal Data;</li>
                    <li>The Processing is unlawful and the Data Subject opposes the erasure of the Personal Data and requests the restriction of its use instead;</li>
                    <li>The controller no longer needs the Personal Data for the purposes of the Processing; or</li>
                    <li>The Data Subject has objected to Processing on the basis of automated decision making, and the determination of whether the legitimate grounds of the controller override those of the Data Subject is pending.</li>
                </ol>
            </p>

            <p><strong>Data Portability Requirement:</strong> Data Subjects will have the right to obtain from Calc Insights the Personal Data that they have provided to Calc Insights in a structured, commonly used, and machine-readable format. The Data Subjects will also have the right to transmit this data to another controller without hindrance from Calc Insights.</p>
            <p>This data portability requirement only applies if the Processing is based on consent or a contractual obligation and the Processing is carried out by automated means.</p>
            <p><strong>Right to Object Requirement:</strong> When the legal basis for Processing is for the public interest or necessary for Calc Insights’s legitimate interests, a Data Subject can object to Calc Insights’s Processing. Calc Insights will notify Data Subjects of this right to object, at the latest, at the time of their first communication with the Data Subject.</p>
            <p>If the Data Subject exercises their right to object, Calc Insights will no longer Process the Personal Data unless they demonstrate compelling legitimate grounds for Processing which override the rights of the Data Subject.</p>
            <p>Where the Personal Data is Processed for direct marketing purposes, the Data Subject can object at any time. Where the Data Subject objects to Processing for direct marketing purposes, Calc Insights will no longer Process their Personal Data for such a purpose.</p>

            <p><strong>Automated Individual Decision-Making, Including Profiling:</strong> Data Subjects have the right not to be subject to a decision based solely on automated Processing, including profiling, which produces legal effects concerning the Data Subject. The foregoing does not apply if the decision based solely on automated processing:
                <ol>
                    <li>Is necessary for entering into, or performance of, a contract between Calc Insights and the Data Subject;</li>
                    <li>Is authorized by the European Union or the laws of one of its member states; or</li>
                    <li>Is based on the Data Subject’s explicit consent.</li>
                </ol>
            </p>
            <p>When Calc Insights uses automated processing to make a decision, it will implement suitable measures to safeguard the Data Subject’s rights and freedoms and preserve the right to obtain human intervention so that Data Subject may contest the decision.</p>
            <p>Calc Insights will not use automated processing on special categories unless the Data Subject has provided explicit consent or there is a substantial public interest.</p>

            <h3>
                EFFECTIVE DATE
            </h3>

            <p>
                This Policy will be in effect from 21/02/2024. Any prior releases of this Policy conflicting with the
                entirety or any portion of this policy are hereby annulled and replaced.
            </p>


        </div>
    )
}

export default Gdpr