import React from 'react'
import styles from "./privacy.module.css"

function Privacy() {
    return (
        <div className={styles["container"]}>
            <h1>Privacy Policy</h1>
            <h3>Calc Insights Market Research</h3>
            <p>
                At Calc Insights Market Research, we are dedicated to protecting the privacy and security of
                the personal information we collect from our clients, participants, and visitors to our website.
                This Privacy Policy provides detailed information about how we collect, use, disclose, and
                protect your personal information. By accessing our services or providing us with your
                personal information, you consent to the practices described in this policy.
            </p>

            <h3>PURPOSE</h3>
            <p>
                This policy underscores Calc Insights Private Limited's unwavering commitment to uphold
                and safeguard the privacy and Personal Data of its employees, suppliers, customers,
                business partners, clients, and their respective end customers. The objective is to establish
                suitable safeguards for the processing of Personal Data by Calc Insights Private Limited.
            </p>

            <h3>SCOPE</h3>
            <p>
                This Policy applies to Calc Insights Private Limited, including its parent company, related
                affiliates/subsidiaries, and third parties processing Personal Data on behalf of these entities.
                It specifically pertains to instances where Personal Data is processed from Data Subjects
                residing in the European Economic Area.
            </p>

            <h3>
                DEFINITIONS
            </h3>

            <p>
                Consent of the Data Subject: Freely given, specific, informed, and unambiguous indication of
                will, whereby the Data Subject agrees to the Processing of Personal Data. It can be
                evidenced in writing, electronic form, or recorded means and may be given by an authorized
                agent on behalf of the Data Subject.
            </p>

            <p>
                <strong>Controller: </strong>
                An individual or organization that, alone or jointly with others, determines the
                purposes and means of Processing Personal Data
            </p>

            <p>
                <strong>Data Subject: </strong>
                An individual whose Personal Data is being Processed
            </p>

            <p>
                <strong>Filing System: </strong>
                A structured set of personal data accessible according to specific criteria,
                facilitating ready access to specific information about a particular person.
            </p>

            <h3>Introduction</h3>

            <p>
                <strong>1.1 Purpose: </strong>
                This Privacy Policy outlines our commitment to safeguarding your personal information and
                explains how we collect, use, disclose, and protect it in accordance with applicable privacy
                laws and regulations.
            </p>

            <p>
                <strong>1.2 Scope: </strong>
                This Privacy Policy applies to all personal information collected by Calc Insights Market
                Research, whether through our website, surveys, interviews, or other means. It also applies
                to all individuals who interact with us, including clients, participants, visitors, and employees.
            </p>

            <h3>
                Information We Collect
            </h3>

            <p>
                <strong>2.1 Personal Information: </strong>
                We may collect various types of personal information, including but not limited to:<br></br>
                Contact information (e.g., name, email address, phone number)<br></br>
                Demographic information (e.g., age, gender, location)<br></br>
                Professional details (e.g., job title, company information)<br></br>
                Communication preferences<br></br>
                We collect this personal information when you voluntarily provide it to us, such as when you
                register for our services, participate in surveys, request information, or communicate with us.
            </p>

            <p>
                <strong>2.2 Research Data: </strong>
                As a market research company, we collect and process research data to fulfill our research
                objectives. Research data may include responses to surveys, interview transcripts, focus
                group discussions, and publicly available information. We handle research data with the
                utmost care, ensuring confidentiality and anonymity whenever possible.
            </p>

            <h3>Use of Information</h3>

            <p>
                <strong>
                    3.1 Personal Information:
                </strong>
                We may use the personal information we collect for various purposes, including but not
                limited to:<br></br>
                <strong>Communication:</strong> To communicate with you regarding our services, surveys, research
                studies, and relevant updates.<br></br>
                <strong>Service Delivery:</strong> To provide you with requested information, reports, or updates related to
                our services.<br></br>
                <strong>Personalization:</strong> To customize and improve our services based on your preferences,
                feedback, and needs.<br></br>
                <strong>Account Management:</strong> To manage your account, including user authentication, account
                verification, and user support.
            </p>

            <p>
                <strong>3.2 Research Data: </strong>
                The research data we collect is primarily used for analysis, market research reports, and
                generating insights for our clients. We analyze this data to understand market trends,
                consumer behavior, and other research objectives. When using research data, we ensure
                that it is aggregated and anonymized to protect your privacy.
            </p>

            <h3>Disclosure of Information</h3>

            <p>
                <strong>
                    4.1 Third-Party Service Providers:
                </strong>
                We may disclose personal information to trusted third-party service providers who assist us
                in delivering our services. These service providers include but are not limited to:<br></br>
                <ol>
                    <li>Data storage and hosting providers</li>
                    <li>Data analysis and processing partners</li>
                    <li>Survey platform providers</li>
                    <li>Research support and consulting firms</li>
                </ol>
                We require these service providers to maintain the confidentiality and security of your
                personal information and use it solely for the purposes for which it was disclosed.
            </p>

            <p>
                <strong>4.2 Legal Requirements and Protection: </strong>
                We may disclose personal information if required by law, regulation, legal process, or if
                necessary to protect our rights, property, or safety, or the rights, property, or safety of others.
                This includes sharing information with law enforcement agencies, government authorities, or
                other third parties when required or advisable to comply with legal obligations.
            </p>

            <h3>
                Data Security
            </h3>

            <p>
                <strong>5.1 Security Measures: </strong>
                We implement reasonable security measures to protect your personal information from
                unauthorized access, use, disclosure, alteration, or destruction. We employ administrative,
                technical, and physical safeguards to ensure the confidentiality, integrity, and availability of
                your data.
            </p>

            <p>
                <strong>5.2 Data Transfer: </strong>
                Please note that the transmission of information over the internet or any other electronic
                means is not completely secure. While we strive to protect your personal information, we
                cannot guarantee the absolute security of the information transmitted to us. Any
                transmission is done at your own risk.
            </p>

            <h3>Cookies and Tracking Technologies</h3>

            <p>
                <strong>6.1 Cookies: </strong>
                We may use cookies and similar tracking technologies on our website to enhance your
                browsing experience, analyze website usage, and improve our services. You can manage
                your cookie preferences through your browser settings.
            </p>

            <p>
                <strong>6.2 Third-Party Analytics: </strong>
                We may use third-party analytics services to collect and analyze website usage data. These
                analytics providers may use cookies and similar technologies to gather information about
                your interactions with our website.
            </p>

            <h3>Links to Third-Party Websites</h3>

            <p>
                Our website may contain links to third-party websites. Please note that we are not
                responsible for the privacy practices or content of those websites. We encourage you to
                review the privacy policies of those websites before providing any personal information.
            </p>

            <h3>Children's Privacy</h3>
            <p>
                Based on the information you provided, there may be an interest in inviting any individuals under the age of 18 residing in your household to partake in a survey. In such instances, obtaining prior consent from a parent or legal guardian, who holds legal responsibility for the child, will always be mandatory before extending an invitation for the child's participation in the research.
            </p>

            <h3>
                Your Rights and Choices
            </h3>

            <p>
                <strong>7.1 Access and Update: </strong>
                You have the right to access, update, or correct your personal information held by us. You
                can do this by contacting us using the provided contact information. We will make
                reasonable efforts to fulfill your request, subject to any legal or contractual requirements
            </p>

            <p>
                <strong>7.2 Opt-Out: </strong>
                You have the right to opt out of receiving marketing communications from us. You can
                exercise this right by following the unsubscribe instructions provided in our communications
                or by contacting us directly.
            </p>

            <p>
                <strong>7.3 Data Retention: </strong>
                We retain your personal information for as long as necessary to fulfill the purposes for which
                it was collected, as required by law, or as necessary to resolve disputes, enforce
                agreements, and protect our rights.
            </p>

            <p>
                <strong>7.4 Request erasure of your personal data: </strong>
                This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
            </p>

            <p>
                <strong>7.5 Object to processing of your personal data: </strong>
                where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
            </p>

            <p>
                <strong>7.6 Request restriction of processing of your personal data: </strong>
                This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                <ol>
                    <li>if you want us to establish the data's accuracy;</li>
                    <li>where our use of the data is unlawful but you do not want us to erase it;</li>
                    <li>where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or</li>
                    <li>you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                </ol>
            </p>

            <p>
                <strong>7.7 Request the transfer of your personal data to you or to a third party: </strong>
                We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
            </p>

            <h3>Changes to the Privacy Policy</h3>
            <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or
                applicable laws. We will post the revised policy on our website with the updated effective
                date. Please review this Privacy Policy periodically for any changes.
            </p>

            <h3>Contact Us</h3>

            <p>
                if you have any questions, concerns, or requests regarding this Privacy Policy or our data
                practices, please contact us using the following information:<br>
                </br>
                <strong>info@calcinsights.com</strong>
                <br>
                </br>
                <p>
                    By using our services or providing us with your personal information, you acknowledge that
                    you have read and understood this Privacy Policy and agree to the collection, use, and
                    disclosure of your information as described herein.
                </p>
            </p>
        </div>
    )
}

export default Privacy