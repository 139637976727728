
import React from 'react'

function Testimonials() {
  return (

    <div
    className="page-template-default page page-id-44 theme-jackcerra woocommerce-no-js pxl-redux-page body-default-font heading-default-font bd-px-header--default site-color-gradient woocommerce-layout-grid elementor-default elementor-kit-6 elementor-page elementor-page-44"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0" 
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-dark-grayscale">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 0.49803921568627" />
            <feFuncG type="table" tableValues="0 0.49803921568627" />
            <feFuncB type="table" tableValues="0 0.49803921568627" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-grayscale">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 1" />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB type="table" tableValues="0 1" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-purple-yellow">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR
              type="table"
              tableValues="0.54901960784314 0.98823529411765"
            />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB
              type="table"
              tableValues="0.71764705882353 0.25490196078431"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-blue-red">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 1" />
            <feFuncG type="table" tableValues="0 0.27843137254902" />
            <feFuncB
              type="table"
              tableValues="0.5921568627451 0.27843137254902"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-midnight">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 0" />
            <feFuncG type="table" tableValues="0 0.64705882352941" />
            <feFuncB type="table" tableValues="0 1" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-magenta-yellow">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0.78039215686275 1" />
            <feFuncG type="table" tableValues="0 0.94901960784314" />
            <feFuncB
              type="table"
              tableValues="0.35294117647059 0.47058823529412"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-purple-green">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR
              type="table"
              tableValues="0.65098039215686 0.40392156862745"
            />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB type="table" tableValues="0.44705882352941 0.4" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-blue-orange">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0.098039215686275 1" />
            <feFuncG type="table" tableValues="0 0.66274509803922" />
            <feFuncB
              type="table"
              tableValues="0.84705882352941 0.41960784313725"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs>
    </svg>
    <div id="pxl-wapper" className="pxl-wapper">
      {/* <div
        id="pxl-loadding"
        className="pxl-loader pxl-loader-gradient style-default"
      >
        <div className="pxl-loader-effect">
          <div className="pxl-circle-1"></div>
          <div className="pxl-circle-2"></div>
        </div>
      </div> */}
    
      <div id="pxl-page-title-elementor">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="94"
          className="elementor elementor-94"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-fa5168c elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
            data-id="fa5168c"
            data-element_type="section"
            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-extended">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-439d52b pxl-column-none"
                data-id="439d52b"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-4055df0 pxl-page--title elementor-widget elementor-widget-pxl_heading"
                    data-id="4055df0"
                    data-element_type="widget"
                    data-widget_type="pxl_heading.default"
                  >
                    <div className="elementor-widget-container">
                      <div
                        id="pxl-pxl_heading-4055df0-1077"
                        className="pxl-heading px-sub-title-default-style"
                      >
                        <div className="pxl-heading--inner">
                          <h1 className="pxl-item--title" data-wow-delay="ms">
                            Join Our Panel
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-937e8b3 elementor-widget elementor-widget-pxl_breadcrumb"
                    data-id="937e8b3"
                    data-element_type="widget"
                    data-widget_type="pxl_breadcrumb.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="pxl-breadcrumb-wrap">
                        <ul className="pxl-breadcrumb">
                          <li>
                            <a className="breadcrumb-entry" href='/'
                              >Home</a
                            >
                          </li>
                          <li>
                            <span className="breadcrumb-entry ">Join Our Panel</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div id="pxl-main">
        <div className="elementor-container">
          <div className="row pxl-content-wrap no-sidebar">
            <div
              id="pxl-content-area"
              className="pxl-content-area pxl-content-page col-12"
            >
              <main id="pxl-content-main">
                <article
                  id="pxl-post-44"
                  className="post-44 page type-page status-publish hentry"
                >
                  <div className="pxl-entry-content clearfix">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id="44"
                      className="elementor elementor-44"
                    >
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-41e5ad3 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                        data-id="41e5ad3"
                        data-element_type="section"
                      >
                        <div
                          className="elementor-container elementor-column-gap-extended"
                        >
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffef4f7 pxl-column-none"
                            data-id="ffef4f7"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-fd85c86 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                data-id="fd85c86"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                              >
                                <div
                                  className="elementor-container elementor-column-gap-extended"
                                >
                                  <div
                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77b09bc pxl-column-none"
                                    data-id="77b09bc"
                                    data-element_type="column"
                                  >
                                    <div
                                      className="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        className="elementor-element elementor-element-269bf31b elementor-widget elementor-widget-pxl_heading"
                                        data-id="269bf31b"
                                        data-element_type="widget"
                                        data-widget_type="pxl_heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div
                                            id="pxl-pxl_heading-269bf31b-1991"
                                            className="pxl-heading px-sub-title-under-style"
                                          >
                                            <div className="pxl-heading--inner">
                                              <h3
                                                className="pxl-item--title color-primary"
                                              
                                                data-wow-delay="ms"
                                              >
                                                Join Our Panel
                                              </h3>
                                    
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-7ca8de1 elementor-widget__width-auto elementor-widget elementor-widget-pxl_contact_form"
                                        data-id="7ca8de1"
                                        data-element_type="widget"
                                        data-widget_type="pxl_contact_form.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div
                                            className="pxl-contact-form pxl-contact-form1 btn-w-auto"
                                            data-wow-delay="ms"
                                          >
                                            <div
                                              className="wpcf7 no-js"
                                              id="wpcf7-f1356-p56-o1"
                                              lang="en-US"
                                              dir="ltr"
                                            >
                                              <div
                                                className="screen-reader-response"
                                              >
                                                <p
                                                  role="status"
                                                  aria-live="polite"
                                                  aria-atomic="true"
                                                ></p>
                                                <ul></ul>
                                              </div>
                                              <form
                                                action="https://demo.bravisthemes.com/jackcerra/contact-us/#wpcf7-f1356-p56-o1"
                                                method="post"
                                                className="wpcf7-form init"
                                                aria-label="Contact form"
                                                novalidate="novalidate"
                                                data-status="init"
                                              >
                                                <div style={{display: "none"}}>
                                                  <input
                                                    type="hidden"
                                                    name="_wpcf7"
                                                    value="1356"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="_wpcf7_version"
                                                    value="5.7.5.1"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="_wpcf7_locale"
                                                    value="en_US"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="_wpcf7_unit_tag"
                                                    value="wpcf7-f1356-p56-o1"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="_wpcf7_container_post"
                                                    value="56"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="_wpcf7_posted_data_hash"
                                                    value=""
                                                  />
                                                </div>
                                                <div className="row">
                                                  <div
                                                    className="col-lg-6 col-md-6 col-sm-12"
                                                  >
                                                    <div className="pxl--item">
                                                      <p>
                                                        <span
                                                          className="wpcf7-form-control-wrap"
                                                          data-name="your-name"
                                                          ><input
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            placeholder="Name*"
                                                            value=""
                                                            type="text"
                                                            name="your-name"
                                                        /></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="col-lg-6 col-md-6 col-sm-12"
                                                  >
                                                    <div className="pxl--item">
                                                      <p>
                                                        <span
                                                          className="wpcf7-form-control-wrap"
                                                          data-name="your-phone"
                                                          ><input
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            placeholder="Phone No*"
                                                            value=""
                                                            type="text"
                                                            name="your-phone"
                                                        /></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div
                                                    className="col-lg-6 col-md-6 col-sm-12"
                                                  >
                                                    <div className="pxl--item">
                                                      <p>
                                                        <span
                                                          className="wpcf7-form-control-wrap"
                                                          data-name="your-email"
                                                          ><input
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            placeholder="Email*"
                                                            value=""
                                                            type="email"
                                                            name="your-email"
                                                        /></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="col-lg-6 col-md-6 col-sm-12"
                                                  >
                                                    <div className="pxl--item">
                                                      <p>
                                                        <span
                                                          className="wpcf7-form-control-wrap"
                                                          data-name="your-subject"
                                                          >
                                                          {/* <input
                                                            size="40"
                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                            placeholder="Gender*"
                                                            value=""
                                                            type="text"
                                                            name="your-subject"
                                                        /> */}
                                                        <select name="gender" id="gender" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"  style={{height: "58px"}}>
                                                        <option>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </select>
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* <div className="pxl--item">
                                                  <p>
                                                    <span
                                                      className="wpcf7-form-control-wrap"
                                                      data-name="your-message"
                                                    >
                                                      <textarea
                                                        cols="40"
                                                        rows="10"
                                                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                        aria-required="true"
                                                        aria-invalid="false"
                                                        placeholder="Message*"
                                                        name="your-message"
                                                      ></textarea>
                                                    </span>
                                                  </p>
                                                </div> */}
                                                <div
                                                  className="pxl--item pxl-text-center"
                                                >
                                                  <p>
                                                    <button
                                                      type="submit"
                                                      className="wpcf7-submit btn btn-skew"
                                                    >
                                                      <span>Join</span>
                                                    </button>
                                                  </p>
                                                </div>
                                                <div
                                                  className="wpcf7-response-output"
                                                  aria-hidden="true"
                                                ></div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
      </div>

   
      <a className="pxl-scroll-top" href="#"
        ><i className="caseicon-long-arrow-right-three"></i
      ></a>
      <div className="pxl-cursor pxl-js-cursor style-default">
        <div className="pxl-cursor-wrapper">
          <div className="pxl-cursor--follower pxl-js-follower"></div>
          <div className="pxl-cursor--label pxl-js-label"></div>
          <div className="pxl-cursor--drap pxl-js-drap"></div>
          <div className="pxl-cursor--icon pxl-js-icon"></div>
        </div>
      </div>
      <div className="pxl-cursor-section"></div>
      <div id="pxl-page-popup" className="">
        <div className="pxl-popup-overlay"></div>
        <div className="pxl-page-popup-content"></div>
      </div>
    </div>
  
 
    
   

  </div>
  )
}

export default Testimonials