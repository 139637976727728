import React from 'react'
import styles from "./terms.module.css"

function Terms() {
    return (
        <div className={styles["container"]}>
            <h1>Member Terms of Use</h1>
            <p>
                By signing up and actively using the Calc Insights Survey program, you agree to all
                terms and conditions outlined in this agreement. Calc Insights Survey may, at any
                time, choose to edit, add, and/or delete portions of this agreement and impose
                changes without prior notification of its members. We will endeavor to send out a
                notice to all our members regarding the new terms and/or terms that have changed.
                If any modification is unacceptable to you, your only recourse is to terminate this
                agreement or file an appeal. Your continued participation in the Calc Insights Survey
                program following our posting of a change in policy notice or a new agreement on
                our site will constitute binding acceptance of the change.
            </p>

            <h3>Responsibility of Members</h3>

            <ol>
                <li>You must understand English. You must be at least 18 years old or at least 13
                    years old with parental consent. Requests may be made to verify your age.</li>
                <li>You may be required to verify your identity and address prior to ANY payment
                    or order upon our request or if any fraudulent activity is suspected. Accepted
                    forms of identification include an Official driver's license, birth certificate
                    passport, or your previous year's tax returns. Accepted forms of address
                    include a Utility bill, Property Tax Receipt, Lease Agreement, or Posted Mail
                    with the name of the applicant.
                </li><li>Only one account may be held per individual. Multiple accounts per household
                    are allowed, but ID verification may be requested to verify your identity.
                    Certain areas of the website may only be accessible by one member of the
                    household.
                </li><li>You must NOT sign up or log in from a proxy IP. Login from cell phones and
                    public internet connections (such as a school, Wi-Fi hot-spot, or a library) is
                    allowed, but certain areas may be limited. You may not play games in a
                    browser while connected to www.calcinsights.com via a cell phone.
                    Requesting prizes or withdrawals from public locations is not permitted.
                </li><li>You may only sign up for offers in your own name, and only one person may
                    use the account. Other individuals in the household need to create their own
                    accounts. If you use a credit card for offers, the credit card must be in your
                    name and no one else's!
                </li><li>You are solely responsible for any and all use of your Calc Insights Survey
                    account and you may not authorize another to use or otherwise access your
                    account. All information provided during registration and offer completion must
                    be your own and may not pertain to any other individual. False information
                    including, but not limited to, addresses, phone numbers, and names will
                    constitute an attempt to commit fraud and we reserve the right to suspend
                    your account pending further investigation.
                </li><li>All information provided during registration and offer completion must be your
                    own and may not pertain to any other individual. False information including,
                    but not limited, to addresses, phone numbers, and names will constitute an
                    attempt to commit fraud and we reserve the right to suspend your account
                    pending further investigation.
                </li><li>You are not allowed to use an internet voicemail/internet phone number on
                    your Calc Insights Survey account.
                </li><li>You cannot repeat offers on the Calc Insights Survey that you have completed
                    on other sites. Likewise, offers may only be completed once on the Calc
                    Insights Survey unless indicated otherwise. Categories that allow multiple
                    completions include but are not limited to, daily surveys, daily clicks, and
                    recurring tasks.
                </li><li>You are not permitted to cancel offers right after the credit is received, nor are
                    you permitted to encourage others to do so. You must fulfill all offer
                    requirements to qualify for credit. Early cancellation or incorrect completion is
                    considered fraud and may result in credit reversal or suspension of your
                    account.
                </li><li>You are not permitted to share or use any confidential information you obtain
                    through surveys. Legal action may take place if the unauthorized use of any
                    content is discovered.
                </li><li>Fraudulent members are NOT welcome on the Calc Insights Survey. We rely
                    on the honesty of all parties in order to remain assets to all members. In order
                    to ensure this honesty, to the best of our ability, we closely monitor our site for
                    fraud and abuse.
                </li><li>.Should any instances of fraud, system abuse, or any type of activity deemed
                    inappropriate or illegal by the Calc Insights Survey be detected, it will result in
                    account termination and possible legal action. Any and all earnings (whether
                    reversed by the advertisers or not) will be revoked without warning and any
                    pending orders will be canceled.
                </li><li>Anyone caught cheating and/or committing fraud in any capacity will be held
                    in violation of these terms and will thus no longer be protected by them</li>
            </ol>

            <h3>Redemption</h3>

            <p>
                The participant can register for Calc Insights by either clicking on the Banner Ad or accessing
                the URL provided in the Mail Survey. During registration, entrants will be required to answer
                basic profiling questions. To qualify for the prize drawing, online registration and account
                activation are mandatory. The Sponsor will not verify the receipt of entries. The Sponsor bears
                no responsibility for lost, late, incomplete, non-delivered, misdirected, or illegible entries, as
                well as any computer, cable, network, electronic, or Internet hardware or software malfunctions.
                This includes failures, garbled or jumbled transmissions, service provider/Internet/website/use
                net accessibility or availability, traffic congestion, or unauthorized human intervention.
                Additionally, the Sponsor is not liable for the incorrect or inaccurate capture of entry
                information or the failure to capture any such information.
            </p>

            <p>
                Entrants have the option to select any of the partners affiliated with Calc Insights to redeem
                points based on the milestones outlined in this policy. Calc Insights explicitly disclaims all
                warranties related to the functionality of the websites and any other processes involved in
                redeeming points from its partners.
            </p>

            <p>
                Each panelist can accumulate points by participating in surveys, and these points can be
                redeemed for gift vouchers. The redemption process initiates at 100 points. Redemption
                requests are processed weekly, and participants can expect to receive their chosen redemption
                within 3-4 weeks from the redemption date.
            </p>

            <h3>Paying You for Participating in Online Surveys</h3>

            <p>
                Upon the successful completion of each survey before the stipulated deadline, participants will
                receive points or an alternative reward. Additionally, completion may automatically enter them
                into a prize draw. The nature and magnitude of the reward or prize draw may vary based on
                survey difficulty, length, or similar factors.
            </p>

            <p>
                If you choose to unsubscribe from the Panel, it is advisable to redeem all your bonus points for
                vouchers before doing so. Failure to do this will result in the forfeiture of remaining points, and
                your points account will be automatically deleted. It is explicitly stated that rewards will not be
                provided in cash under any circumstances
            </p>

            <p>
                Participants are solely responsible for the payment of any applicable income tax on the rewards
                earned or redeemed directly, or on the equivalent perquisite value, as per the terms of this
                contract.
            </p>

            <p>
                There might be instances where points for recently completed surveys do not immediately
                reflect in your account. In such cases, the points will be added to your account at a later time.
            </p>

            <h3>Relationship of the Parties</h3>

            <p>
                You and Calc Insights Survey are independent contractors, and nothing in this
                Agreement creates any partnership, joint venture, agency, franchise, sales
                representative, or employment relationship between you and Calc Insights Survey.
                You understand that you do not have the authority to make or accept any offers or
                make any representations on behalf of Calc Insights Survey. You may not make any
                statement, whether, on a site maintained by you or otherwise, that would contradict
                anything in this section. You are solely responsible for the reporting and payment of
                any taxes for money earned while using the Calc Insights Survey program. For
                privacy and for the protection of our marketing strategies, no part of the site, e-mails,
                newsletters, updates, templates or promotions may be reproduced in any way
                without prior consent. This includes but is not limited to, the use of Calc Insights
                Survey images, ideas, text, terms, or any other information we consider as
                copyrighted material. Publishing/posting/quoting part or all of such content online or
                offline by any means or method is prohibited.
            </p>

            <p>
                We do not guarantee that a member will receive credit for offers. We reserve the
                right to refuse credit for an offer for a number of reasons, including, but not limited to,
                fraud, system abuse, and early termination. You are doing all offers on a voluntary
                basis with the understanding that no credit is guaranteed for the time you spend
                completing offers. Payment from offers will only be made after verification that offers
                completed have been validated and that the offer(s) has been paid upon by the
                advertiser. Calc Insights Survey will never be deemed as owing payment to any
                member. There are occasional delays in receiving commissions from the advertisers
                and, by participating in the Calc Insights Survey program, members understand and
                acknowledge that no payment shall be made until Calc Insights Survey has received
                such payment from its advertisers.
            </p>

            <h3>Spam</h3>

            <p>
                We do not believe in spam; Calc Insights Survey may occasionally send you
                periodical e-mails containing information regarding site updates and maintenance.
                We only send these newsletters if you are subscribed as such and you can opt-out
                from your account management page or by clicking the unsubscribe link in any of the
                e-mails sent. You may not promote your referral links through unsolicited emailing
                (i.e. SPAMMING), newsgroup postings, or any other method of mass
                communication. Failure to comply will result in immediate termination of your
                membership with Calc Insights Survey and may result in legal prosecution. Calc
                Insights Survey strictly enforces anti-spamming laws. Spamming is a federal crime;
                Any member caught spamming will not only have their account terminated
                immediately and lose any past, present, and future earnings but shall also be held
                liable for spamming as we shall cooperate with any authorities and investigations
                that may arise from the aforementioned incident.
            </p>

            <h3>Fraud</h3>

            <p>
                Using fraudulent information to complete offers and/or completing offers with
                fraudulent intent will result in the member's account being deleted without notice and
                relinquishment of all monies in the account. Fraud includes but is not limited to, the
                following: using stolen credit cards to complete offers; using false information to
                complete offers; using information that is not one's own to complete offers; using
                prepaid gift and/or credit cards to complete offers; completing the same offer multiple
                times; canceling offers and/or trials prior to receipt of product and/or service.
            </p>

            <p>
                Multiple signups by any individual member shall be grounds for immediate
                termination of all involved accounts. Calc Insights Survey reserves the right to file
                charges against an individual to recover any earnings received. We have a tough
                anti-cheat system in place making it extremely difficult to cheat; however, if someone
                is caught cheating or making attempts to do so, we reserve the right to suspend the
                member’s account, resulting in forfeiture of all earnings.
            </p>

            <h3>Orders</h3>

            <p>
                Calc Insights Survey is not responsible for rewards redeemed via Amazon or other
                merchants through our rewards gallery. Members are responsible for reviewing the
                product and seller information page on Amazon before ordering via our rewards
                gallery. We provide Amazon product links on all product pages in the rewards gallery.
                The estimated delivery date displayed on the orders page is an approximate
                calculation of the time it will take for your order to be delivered based on the
                information we have in our system. It is not a guaranteed delivery date and members
                should refer to the product page on Amazon for specific dates. In the event that an
                item is damaged or delayed, we will contact Amazon on your behalf. We cannot
                issue refunds or an exchange until this is finalized with Amazon
            </p>

            <h3>
                Inactive Member Accounts
            </h3>

            <p>
                Membership at Calc Insights Survey is free: there is no charge to be a member or to
                earn points. If you do not log into your account on the website for more than four (4)
                consecutive months, Calc Insights Survey reserves the right to lock all remaining
                points. The locked points become available again once you log in and earn the same
                amount of points by referring new members or completing offers. You can redeem
                the locked points the moment they become unlocked. Any newly earned points in
                active accounts can be redeemed at any time. Active shall be defined as logging into
                the website at least once in a four (4) month period.
            </p>

            <h3>
                Limitation of Liability
            </h3>

            <p>
                Calc Insights Survey will not be liable for lost profits, lost business opportunities, or
                any other indirect, special, punitive, incidental or consequential damages arising out
                of or related to both this agreement and the Calc Insights Survey program, even ifCalc Insights Survey has been advised of the possibility of such damages. The
                provisions of this section survive termination or expiration of the agreement.
            </p>

            <h3>
                Non-Liability
            </h3>

            <p>
                At no time will Calc Insights Survey be liable for anything resulting from the use of a
                member's account This includes but is not limited to, the following: suspension or
                termination of an account, dissemination of information concerning an account, legal
                proceedings initiated against a member, or any damages (actual or consequential)
                from lawsuits, losses, judgments, litigation costs, attorneys' fees, and any other
                consequences. Calc Insights Survey is solely an intermediary between members and
                advertisers and is neither ensuring nor endorsing products or services advertised on
                Calc Insights Survey. Members are solely responsible for any and all consequences
                of decisions made with regard to any partners' websites.
            </p>

            <h3>
                Non-Responsibility
            </h3>

            <p>
                Calc Insights Survey is not responsible for any user/member and, at its discretion,
                may terminate the service for any user/member at any time, without notice. Upon
                termination, Calc Insights Survey has no further responsibility toward any
                user/member past or present.
            </p>

            <h3>
                Termination
            </h3>

            <p>
                Termination of a member's account constitutes forfeiture of the privilege to create a
                new account. Only one account per individual is allowed; if more than one account is
                created for the same individual, this is considered fraud and both accounts will be
                deleted with all monies forfeited.
            </p>

            <p>
                Members are required to use true and accurate account information when signing up
                and to keep their account information up-to-date at all times. If a member is found to
                have incomplete, inaccurate, or false account information, his/her account is subject
                to termination without notice. Members may cancel their use of the Calc Insights
                Survey at any time by sending a support ticket requesting such. These terminated
                accounts shall lose any and all potential benefits associated with the site, including,
                but not limited to, points earned and prizes requested. Calc Insights Survey will not
                offer or owe anything to members whose accounts have been terminated and/or
                suspended. Calc Insights Survey reserves the right to terminate the account and
                withhold orders from any and all members who are deemed to have fraudulently
                completed offers, no matter how insignificant the degree of fraud.
            </p>

            <h3>
                Changes to this Agreement
            </h3>

            <p style={{ marginBottom: "30px" }}>
                to change this agreement without any prior notice, we will endeavor to send out a
                notice to all members regarding the new terms and/or terms that have changed. If for
                any reason you do not accept the changes, you will need to contact us and your
                account will be removed.
            </p>

        </div>
    )
}

export default Terms