import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import '../../css/header.css'
import { useLocation } from 'react-router-dom';
import { DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';

const JoinPanelPopUp = ({ handleDrawerToggle }) => {
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [submit, setSubmit] = React.useState(false);
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [gender, setGender] = useState("")
  const [age, setAge] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [policyAccepted, setPolicyAccepted] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmitPopup = (e) => {
    e.preventDefault()
    if (!name || !email || !phone || !country || !city || !gender || !password || !confirmPassword || !age) {
      alert("All fields are required")
    } else {
      if (policyAccepted==false) {
        alert("Please Accept Terms & Condition and Privacy Policy")
        return;
      } else {
        if (password == confirmPassword) {
          console.log(name, email, phone, country, city, gender, password, confirmPassword, age)
          axios.post("https://cms.calcinsights.com/api/join-panels", {
            "data": {
              "name": name,
              "city": city,
              "phone": parseInt(phone),
              "password": password,
              "email": email,
              "gender": gender,
              "age": age,
              "country": country
            }
          }).then((e) => {
            setName("")
            setEmail("")
            setPhone("")
            setPassword("")
            setConfirmPassword("")
            setCountry("")
            setCity("")
            setAge("")
            setGender("")
            setSubmit(true);
          }).catch((e) => {
            alert("Error occurred, please try again")
          })
        } else {
          alert("Passoword and Confirm Password does not match")
        }
      }
    }
  };

  const handleSubmitPopupClose = () => {
    setSubmit(false);
  };
  return (
    <>
      {/* <NavLink to="/testimonial"> */}
      <Button variant="contained" onClick={handleClickOpen} sx={{ color: location.pathname === "/" ? "#FFF" : "#000" }} className='joinBtn'>
        Join Our Panel
      </Button>
      {/* </NavLink> */}

      <Dialog open={open} onClose={handleClose} sx={{
        '& .MuiDialog-paperWidthSm': { overflow: "hidden !important" },
      }}>

        <div id="joinPopup">
          <div className="elementor-container">
            <div className="row pxl-content-wrap no-sidebar">
              <div
                id="pxl-content-area"
                className="pxl-content-area pxl-content-page col-12"
              >
                <main id="pxl-content-main">
                  <article
                    id="pxl-post-44"
                    className="post-44 page type-page status-publish hentry"
                  >
                    <div className="pxl-entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="44"
                        className="elementor elementor-44"
                      >
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-41e5ad3 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="41e5ad3"
                          data-element_type="section"
                        >
                          <div
                            className="elementor-container elementor-column-gap-extended"
                          >
                            <div
                              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffef4f7 pxl-column-none"
                              data-id="ffef4f7"
                              data-element_type="column"
                            >
                              <div
                                className="elementor-widget-wrap elementor-element-populated"
                              >
                                <section
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-fd85c86 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                  data-id="fd85c86"
                                  data-element_type="section"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div
                                    className="elementor-container elementor-column-gap-extended"
                                  >
                                    <div
                                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77b09bc pxl-column-none"
                                      data-id="77b09bc"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-269bf31b elementor-widget elementor-widget-pxl_heading"
                                          data-id="269bf31b"
                                          data-element_type="widget"
                                          data-widget_type="pxl_heading.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              id="pxl-pxl_heading-269bf31b-1991"
                                              className="pxl-heading px-sub-title-under-style"
                                            >
                                              <div className="pxl-heading--inner">
                                                <h3
                                                  className="pxl-item--title color-primary"

                                                  data-wow-delay="ms"
                                                >
                                                  Join Our Panel
                                                </h3>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-element elementor-element-7ca8de1 elementor-widget__width-auto elementor-widget elementor-widget-pxl_contact_form"
                                          data-id="7ca8de1"
                                          data-element_type="widget"
                                          data-widget_type="pxl_contact_form.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              className="pxl-contact-form pxl-contact-form1 btn-w-auto"
                                              data-wow-delay="ms"
                                            >
                                              <div
                                                className="wpcf7 no-js"
                                                id="wpcf7-f1356-p56-o1"
                                                lang="en-US"
                                                dir="ltr"
                                              >
                                                <div
                                                  className="screen-reader-response"
                                                >
                                                  <p
                                                    role="status"
                                                    aria-live="polite"
                                                    aria-atomic="true"
                                                  ></p>
                                                  <ul></ul>
                                                </div>
                                                <form
                                                  // action="https://demo.bravisthemes.com/jackcerra/contact-us/#wpcf7-f1356-p56-o1"
                                                  // method="post"
                                                  className="wpcf7-form init"
                                                  aria-label="Contact form"
                                                  novalidate="novalidate"
                                                  data-status="init"
                                                  onSubmit={(e) => { handleSubmitPopup(e) }}
                                                // onSubmit={(e) => {
                                                //   e.preventDefault()
                                                //   const blacklist = [
                                                //     'gmail',
                                                //     'yahoo',
                                                //     'rediff',
                                                //     'hotmail'
                                                //   ]
                                                //   const test = blacklist.some(item => email.includes(item.toLowerCase()))
                                                //   if (test) { alert("Please use organization email"); return; }
                                                //   fetch('CalcInsights.pdf').then(response => {
                                                //     response.blob().then(blob => {
                                                //       // Creating new object of PDF file
                                                //       const fileURL = window.URL.createObjectURL(blob);
                                                //       // Setting various property values
                                                //       let alink = document.createElement('a');
                                                //       alink.href = fileURL;
                                                //       alink.download = 'CalcInsights.pdf';
                                                //       alink.click();
                                                //     })
                                                //   })
                                                // }}
                                                >
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Name*"
                                                              required
                                                              // value=""
                                                              type="text"
                                                              name="your-name"
                                                              value={name}
                                                              onChange={(e) => { setName(e.target.value) }}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-phone"
                                                          ><input
                                                              size="40"
                                                              required
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Phone No*"
                                                              // value=""
                                                              type="number"
                                                              name="your-phone"
                                                              value={phone}
                                                              onChange={(e) => { setPhone(e.target.value) }}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-email"
                                                          ><input
                                                              size="40"
                                                              required
                                                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Email*"
                                                              // value=""
                                                              type="email"
                                                              name="your-email"
                                                              value={email}
                                                              onChange={(e) => { setEmail(e.target.value) }}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap "
                                                            data-name="your-subject"
                                                          >

                                                            <select name="gender" required id="gender" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required "
                                                              value={gender}
                                                              onChange={(e) => { setGender(e.target.value) }}
                                                              style={{ height: "58px" }}>
                                                              <option className='select-gender'>Select Gender</option>
                                                              <option value="male" className='select-gender'>Male</option>
                                                              <option value="female" className='select-gender'>Female</option>
                                                            </select>
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-email"
                                                          ><input type="number" min="18" max="110" name="Age" id="Age" placeholder="Age"
                                                            value={age}
                                                            required
                                                            onChange={(e) => { setAge(e.target.value) }}
                                                            />
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap "
                                                            data-name="your-subject"
                                                          >
                                                            <input type="text" name="Country" id="Country" placeholder="Country"
                                                              value={country}
                                                              required
                                                              onChange={(e) => { setCountry(e.target.value) }}
                                                            />

                                                          </span>
                                                        </p>

                                                      </div>
                                                    </div>
                                                  </div>
                                                  <input type="text" name="City" id="City" placeholder="City"
                                                    value={city}
                                                    onChange={(e) => { setCity(e.target.value) }}
                                                    required
                                                  />

                                                  <div className="row" style={{ marginTop: "30px" }}>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-email"
                                                          ><input type="password" id="pwd" name="pwd" placeholder='  Password'
                                                            value={password}
                                                            onChange={(e) => { setPassword(e.target.value) }}
                                                            required
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap "
                                                            data-name="your-subject"
                                                          >

                                                            <input type="password" id="pwd" name="pwd" placeholder=' Confirm Password'
                                                              value={confirmPassword}
                                                              onChange={(e) => { setConfirmPassword(e.target.value) }}
                                                              required
                                                            />
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='row' style={{ display: "flex", alignItems: "center", color: "#FFF", margin: "0px 20px 20px 20px" }}>
                                                    <div style={{ width: "10%" }}>
                                                      <input type="checkbox"
                                                        value={policyAccepted}
                                                        onChange={(e) => {
                                                          setPolicyAccepted(e.target.checked)
                                                        }}
                                                      />
                                                    </div>
                                                    <div style={{ width: "90%" }}>
                                                      <label>Please click on the check box to agree to our <a href="/terms" target='_blank'>Terms & Conditions</a> and <a href='/privacy' target='_blank'>Privacy Policy</a></label>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="pxl--item pxl-text-center"
                                                  >
                                                    <p>
                                                      <button
                                                        type="submit"
                                                        className="wpcf7-submit btn btn-skew"
                                                      >
                                                        <span ></span>
                                                        Join
                                                      </button>
                                                      {/* <Button variant="contained" type="submit" onClick={handleSubmitPopup} className="wpcf7-submit btn btn-effect2">
                                                      Join
                                                      </Button> */}
                                                      <Dialog open={submit} onClose={handleSubmitPopupClose} sx={{
                                                        '& .MuiDialog-paperWidthSm': { overflow: "hidden !important" },
                                                      }}>
                                                        <div style={{ backgroundColor: "#1c2539", paddingBottom: "20px", width: "400px" }}>
                                                          <DialogTitle id="responsive-dialog-title">

                                                          </DialogTitle>
                                                          <DialogContent>
                                                            <DialogContentText color="white" sx={{ textAlign: "center" }}>
                                                              Thank you for your interest.
                                                              <Typography> We are reviewing your profile and we will get back to you shortly in your Email.</Typography>
                                                            </DialogContentText>
                                                          </DialogContent>
                                                        </div>

                                                      </Dialog>
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="wpcf7-response-output"
                                                    aria-hidden="true"
                                                  ></div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>

      </Dialog>
    </>
  )
}

export default JoinPanelPopUp
