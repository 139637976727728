import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import '../../css/header.css'
import { useLocation } from 'react-router-dom';
import { DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';

const DownloadPanelBook = ({ handleDrawerToggle }) => {
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [submit, setSubmit] = React.useState(false)
  const [data, setData] = useState({
    name: "",
    email: "",
    companyName: "",
    jobTitle: "",
    country: "",
    industry: ""
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmitPopup = (e) => {
    e.preventDefault()
    if (Object.values(data).includes("")) {
      alert("Please fill all fields")
    } else {
      const blacklist = [
        'gmail',
        'yahoo',
        'reduiff',
        'hotmail'
      ]
      const test = blacklist.some(item => data.email.includes(item.toLowerCase()))
      if (test) { alert("Please use organization email"); return; }

      axios.post("https://cms.calcinsights.com/api/panel-books", {
        "data": data
      }).then((e) => {
        fetch('CalcInsights.pdf').then(response => {
          response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'CalcInsights.pdf';
            alink.click();
          })
        })
      }).catch((e) => {
        alert("Error occurred, please try again")
      })
    }
  };

  const handleSubmitPopupClose = () => {
    setSubmit(false);
  };

  const handleChange = (e) => {
    let body = data
    if (e.target.name == "name") body.name = e.target.value
    if (e.target.name == "email") body.email = e.target.value
    if (e.target.name == "company") body.companyName = e.target.value
    if (e.target.name == "job") body.jobTitle = e.target.value
    if (e.target.name == "country") body.country = e.target.value
    if (e.target.name == "industry") body.industry = e.target.value
    setData(body)
  }

  return (
    <>
      {/* <NavLink to="/testimonial"> */}
      <p style={{ color: "#FF0000", cursor: 'pointer' }} onClick={() => { setOpen(true) }}>Download our Panel Book</p>
      {/* </NavLink> */}

      <Dialog open={open} onClose={handleClose} sx={{
        '& .MuiDialog-paperWidthSm': { overflow: "hidden !important" },
      }}>

        <div id="joinPopup">
          <div className="elementor-container">
            <div className="row pxl-content-wrap no-sidebar">
              <div
                id="pxl-content-area"
                className="pxl-content-area pxl-content-page col-12"
              >
                <main id="pxl-content-main">
                  <article
                    id="pxl-post-44"
                    className="post-44 page type-page status-publish hentry"
                  >
                    <div className="pxl-entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="44"
                        className="elementor elementor-44"
                      >
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-41e5ad3 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="41e5ad3"
                          data-element_type="section"
                        >
                          <div
                            className="elementor-container elementor-column-gap-extended"
                          >
                            <div
                              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffef4f7 pxl-column-none"
                              data-id="ffef4f7"
                              data-element_type="column"
                            >
                              <div
                                className="elementor-widget-wrap elementor-element-populated"
                              >
                                <section
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-fd85c86 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                  data-id="fd85c86"
                                  data-element_type="section"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div
                                    className="elementor-container elementor-column-gap-extended"
                                  >
                                    <div
                                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77b09bc pxl-column-none"
                                      data-id="77b09bc"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-269bf31b elementor-widget elementor-widget-pxl_heading"
                                          data-id="269bf31b"
                                          data-element_type="widget"
                                          data-widget_type="pxl_heading.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              id="pxl-pxl_heading-269bf31b-1991"
                                              className="pxl-heading px-sub-title-under-style"
                                            >
                                              <div className="pxl-heading--inner">
                                                <h3
                                                  className="pxl-item--title color-primary"

                                                  data-wow-delay="ms"
                                                >
                                                  Download Our Panel Book
                                                </h3>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-element elementor-element-7ca8de1 elementor-widget__width-auto elementor-widget elementor-widget-pxl_contact_form"
                                          data-id="7ca8de1"
                                          data-element_type="widget"
                                          data-widget_type="pxl_contact_form.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              className="pxl-contact-form pxl-contact-form1 btn-w-auto"
                                              data-wow-delay="ms"
                                            >
                                              <div
                                                className="wpcf7 no-js"
                                                id="wpcf7-f1356-p56-o1"
                                                lang="en-US"
                                                dir="ltr"
                                              >
                                                <div
                                                  className="screen-reader-response"
                                                >
                                                  <p
                                                    role="status"
                                                    aria-live="polite"
                                                    aria-atomic="true"
                                                  ></p>
                                                  <ul></ul>
                                                </div>
                                                <form
                                                  className="wpcf7-form init"
                                                  aria-label="Contact form"
                                                  novalidate="novalidate"
                                                  data-status="init"
                                                  onSubmit={(e) => { handleSubmitPopup(e) }}
                                                // onSubmit={(e) => {
                                                //   e.preventDefault()
                                                //   const blacklist = [
                                                //     'gmail',
                                                //     'yahoo',
                                                //     'rediff',
                                                //     'hotmail'
                                                //   ]
                                                //   const test = blacklist.some(item => email.includes(item.toLowerCase()))
                                                //   if (test) { alert("Please use organization email"); return; }
                                                //   fetch('CalcInsights.pdf').then(response => {
                                                //     response.blob().then(blob => {
                                                //       // Creating new object of PDF file
                                                //       const fileURL = window.URL.createObjectURL(blob);
                                                //       // Setting various property values
                                                //       let alink = document.createElement('a');
                                                //       alink.href = fileURL;
                                                //       alink.download = 'CalcInsights.pdf';
                                                //       alink.click();
                                                //     })
                                                //   })
                                                // }}
                                                >
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-12 col-md-12 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Name"
                                                              // value=""
                                                              type="text"
                                                              name="name"
                                                              required
                                                              onChange={(e) => { handleChange(e) }}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-12 col-md-12 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Company Email"
                                                              // value=""
                                                              type="email"
                                                              name="email"
                                                              onChange={(e) => { handleChange(e) }}
                                                              required
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-12 col-md-12 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Company Name"
                                                              // value=""
                                                              type="text"
                                                              name="company"
                                                              onChange={(e) => { handleChange(e) }}
                                                              required
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-12 col-md-12 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Job Title"
                                                              // value=""
                                                              type="text"
                                                              name="job"
                                                              onChange={(e) => { handleChange(e) }}
                                                              required
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-12 col-md-12 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Industry"
                                                              // value=""
                                                              type="text"
                                                              name="industry"
                                                              onChange={(e) => { handleChange(e) }}
                                                              required
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-12 col-md-12 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Country"
                                                              // value=""
                                                              type="text"
                                                              name="country"
                                                              onChange={(e) => { handleChange(e) }}
                                                              required
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="pxl--item pxl-text-center"
                                                  >
                                                    <p>
                                                      <button
                                                        type="submit"
                                                        className="wpcf7-submit btn btn-skew"
                                                      >
                                                        Join
                                                      </button>
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="wpcf7-response-output"
                                                    aria-hidden="true"
                                                  ></div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>

      </Dialog>
    </>
  )
}

export default DownloadPanelBook
