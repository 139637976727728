import React from 'react'
import styles from "./cookie.module.css"

function Terms() {
    return (
        <div className={styles["container"]}>
            <h1>Cookie Policy</h1>
            <p>
                Calc Insights helps Market Research Organizations to provide prompt and actionable
                insights to their customers, on the back of our international quality Market Research
                services.
            </p>

            <h3>COOKIES, WEB BEACONS, AND SIMILAR TECHNOLOGY</h3>

            <p>
                A cookie is a small data file that is placed on the hard drive of your computer when
                you visit a website. A “session cookie” expires immediately when you end your
                session (i.e., close your browser). A “persistent cookie” stores information on the
                hard drive so when you end your session and return to the same website at a later
                date, the cookie information is still available. A web beacon is a small string of code
                that represents a clear graphic image, a redirect URL, or JavaScript and is used in
                conjunction with a cookie.
            </p>

            <h3>COOKIES FROM Calc Insights</h3>

            <p>
                By accessing the Calc Insights website, including all related pages and subdomains
                you are accepting and agreeing to the practices outlined in this Cookie Policy and
                the Privacy Policy.
            </p>

            <h3>WHAT INFORMATION DOES Calc Insights COLLECT?</h3>

            <p>
                Our primary goal in collecting information is to provide you with a fully functional
                website and efficient experience. We collect the following types of information:
            </p>

            <p>
                We measure visitors to our website using Google’s analytics software. This records
                what pages you view within our site, how you arrived at our site, and some basic
                information about your computer. All of that information is anonymous – so we don’t
                know who you are; just that somebody visited our site. The information we collect
                from analytics helps us understand what parts of our sites are doing well, how
                people arrive at our site, and so on. Like most websites, we use this information to
                make our website better
            </p>

            <p>
                When you visit our website, Calc Insights collects your Internet Protocol (“IP”)
                addresses to track and aggregate non-personal information. For example, Calc
                Insights uses IP addresses to monitor the regions from which you navigate the Site.
                This data is only used in the aggregate.
            </p>

            <p>
                In addition, we receive and store certain types of information whenever you interact
                with us via our website. Calc Insights data” on our server logs (including your IP
                address), Calc Insights cookie information, and the page you requested. Calc
                Insights uses this traffic data to help diagnose problems with its server, analyze
                trends and administer the Service.
            </p>

            <p>
                At times, Calc Insights collects identifying information that you provide to Calc
                Insights, including any pages or landing pages. When you initially express interest in
                obtaining additional information about the Service, or when you register for the
                Service, we may also ask for additional types of personal information, such as title,
                department name, etc. We may also request demographic information and unique
                identifiers in order to provide you with a more personalized experience or verify your
                passwords. You can control and delete cookies from Calc Insights and third parties
                anytime.
            </p>

            <h3>EMAIL COMMUNICATIONS</h3>

            <p>
                We may receive a confirmation when you open an e-mail from Calc Insights if your
                computer supports this type of program, in order to help us make emails more
                interesting and helpful. We may also send out emails with information regarding Calc
                Insights partners including promotions and events. If you complete the request form
                on the Site for additional information about the Service, we may also use your email
                address to send you information.
            </p>

            <h3>UPDATES TO THIS POLICY</h3>

            <p>
                We may update this cookie policy and our privacy policy from time to time,
                particularly as technology changes. You can always check this page for the latest
                version. We may also notify you of changes to our privacy policy by email.
            </p>

            <h3>Calc Insights IS USING THE FOLLOWING ANALYTIC TOOLS</h3>

            <p>
                Google Analytics. Our Site uses Google Analytics, a web analytic service provided
                by Google, Inc. Google Analytics uses javascript ad cookies to help us analyze how
                visitors use the Site. For more information on Google Analytics cookies, visit
                https://policies.google.com/. Calc Insights places a session cookie on your computer
                while you are viewing our site. We may also use cookies to track certain information
                about you based on your activity on our site or on a third party’s site. This
                information may include the URL that you just came from (whether this URL is on our
                site or not), which URL you next go to (whether this URL is on our site or not), what
                browser you are using and your IP address. We use this information to conduct
                internal research to better understand and serve visitors to our site. Calc Insights
                occasionally utilizes e-mail Web beacons to monitor e-mail deliverability. We reserve
                the right to share this information in an anonymous singular summary and aggregate
                form with third parties. From time to time, we may allow a third-party media service
                to display content on our site. These third-party media services may use cookies
                within their content, but we have no access to or control over these cookies. The use
                of cookies by these third parties shall be governed by their respective privacy
                policies.
            </p>

            <h3>FACEBOOK, TWITTER AND OTHER SOCIAL NETWORKS</h3>

            <p>
                These services provide social buttons and similar features that we use on our
                website – such as the “Like” and “Tweet” buttons. To do so we embed code that they
                provide and we do not control ourselves. To function their buttons generally know if
                you’re logged in; for example, Facebook uses this to say “x of your friends like this”.
                We do not have any access to that information, nor can we control how those
                networks use it. Social networks therefore could know that you’re viewing this
                website if you use their services (that isn’t to say they do, but their policies may
                change). As our website is remarkably inoffensive, we imagine this is not a concern
                for most users.
            </p>

            <h3>
                ANY QUESTIONS?
            </h3>

            <p>
                If you have any questions about this cookie policy or your personal data, please write
                to us at info@calcinsights.com
            </p>
            
        </div>
    )
}

export default Terms