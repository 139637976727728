

import React, { useState } from 'react'
import SubmitPopUp from '../SubmitPopUp'
import axios from 'axios'

function ContactUs() {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    if (!name || !phone || !email || !subject || !message) {
      alert("Please fill all fields")
    } else {
      axios.post("https://cms.calcinsights.com/api/appointment-lists", {
        "data": {
          "name": name,
          "phone": parseInt(phone),
          "email": email,
          "subject": subject,
          "message": message
        }
      }).then((e) => {
        setName("")
        setEmail("")
        setPhone("")
        setSubject("")
        setMessage("")
        setOpen(true);
      }).catch((e) => {
        alert("Error occurred, please try again")
      })
    }
    console.log(name, phone, email, subject, message)
  }
  return (
    <div
      className="page-template-default page page-id-56 theme-jackcerra woocommerce-no-js pxl-redux-page body-default-font heading-default-font bd-px-header--default site-color-gradient woocommerce-layout-grid elementor-default elementor-kit-6 elementor-page elementor-page-56"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-dark-grayscale">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 0.49803921568627" />
              <feFuncG type="table" tableValues="0 0.49803921568627" />
              <feFuncB type="table" tableValues="0 0.49803921568627" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-grayscale">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 1" />
              <feFuncG type="table" tableValues="0 1" />
              <feFuncB type="table" tableValues="0 1" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-purple-yellow">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR
                type="table"
                tableValues="0.54901960784314 0.98823529411765"
              />
              <feFuncG type="table" tableValues="0 1" />
              <feFuncB
                type="table"
                tableValues="0.71764705882353 0.25490196078431"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-blue-red">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 1" />
              <feFuncG type="table" tableValues="0 0.27843137254902" />
              <feFuncB
                type="table"
                tableValues="0.5921568627451 0.27843137254902"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-midnight">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0 0" />
              <feFuncG type="table" tableValues="0 0.64705882352941" />
              <feFuncB type="table" tableValues="0 1" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-magenta-yellow">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0.78039215686275 1" />
              <feFuncG type="table" tableValues="0 0.94901960784314" />
              <feFuncB
                type="table"
                tableValues="0.35294117647059 0.47058823529412"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-purple-green">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR
                type="table"
                tableValues="0.65098039215686 0.40392156862745"
              />
              <feFuncG type="table" tableValues="0 1" />
              <feFuncB type="table" tableValues="0.44705882352941 0.4" />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs></svg
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 0 0"
        width="0"
        height="0"
        focusable="false"
        role="none"
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-9999px",
          overflow: "hidden"
        }}
      >
        <defs>
          <filter id="wp-duotone-blue-orange">
            <feColorMatrix
              color-interpolation-filters="sRGB"
              type="matrix"
              values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
            />
            <feComponentTransfer color-interpolation-filters="sRGB">
              <feFuncR type="table" tableValues="0.098039215686275 1" />
              <feFuncG type="table" tableValues="0 0.66274509803922" />
              <feFuncB
                type="table"
                tableValues="0.84705882352941 0.41960784313725"
              />
              <feFuncA type="table" tableValues="1 1" />
            </feComponentTransfer>
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </defs>
      </svg>
      <div id="pxl-wapper" className="pxl-wapper">
        <div id="pxl-page-title-elementor">
          <div
            data-elementor-type="wp-post"
            data-elementor-id="94"
            className="elementor elementor-94"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-fa5168c elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
              data-id="fa5168c"
              data-element_type="section"
              data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
            >

              <div className="elementor-container elementor-column-gap-extended"  >
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-439d52b pxl-column-none"
                  data-id="439d52b"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-4055df0 pxl-page--title elementor-widget elementor-widget-pxl_heading"
                      data-id="4055df0"
                      data-element_type="widget"
                      data-widget_type="pxl_heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div
                          id="pxl-pxl_heading-4055df0-3258"
                          className="pxl-heading px-sub-title-default-style"
                        >
                          <div className="pxl-heading--inner">
                            <h1 className="pxl-item--title" data-wow-delay="ms">
                              Contact Us
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-937e8b3 elementor-widget elementor-widget-pxl_breadcrumb"
                      data-id="937e8b3"
                      data-element_type="widget"
                      data-widget_type="pxl_breadcrumb.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="pxl-breadcrumb-wrap">
                          <ul className="pxl-breadcrumb">
                            <li>
                              <a className="breadcrumb-entry" href='/'
                              >Home</a
                              >
                            </li>
                            <li>
                              <span className="breadcrumb-entry">Contact Us</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div id="pxl-main">
          <div className="elementor-container">
            <div className="row pxl-content-wrap no-sidebar">
              <div
                id="pxl-content-area"
                className="pxl-content-area pxl-content-page col-12"
              >
                <main id="pxl-content-main">
                  <article
                    id="pxl-post-56"
                    className="post-56 page type-page status-publish hentry"
                  >
                    <div className="pxl-entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="56"
                        className="elementor elementor-56"
                      >
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-2bafed8 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="2bafed8"
                          data-element_type="section"
                        >
                          <div style={{ justifyContent: "center" }}
                            className="elementor-container elementor-column-gap-extended"
                          >
                            <div
                              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-eef23f7 pxl-column-none"
                              data-id="eef23f7"
                              data-element_type="column"
                            >
                              <div
                                className="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  className="elementor-element elementor-element-de2f68c elementor-widget elementor-widget-pxl_image_box"
                                  data-id="de2f68c"
                                  data-element_type="widget"
                                  data-widget_type="pxl_image_box.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      className="pxl-image-box pxl-image-box1"
                                      data-wow-delay="ms"
                                    >
                                      <div className="pxl-banner-inner">
                                        <div className="pxl-item--image">
                                          <img
                                            width="740"
                                            height="452"
                                            src="assets/wp-content/uploads/2023/03/contact-banner1.jpg"
                                            className="attachment-full"
                                            alt=""
                                            decoding="async"
                                            srcset="
                                            https://demo.bravisthemes.com/jackcerra/wp-content/uploads/2023/03/contact-banner1.jpg         740w,
                                            https://demo.bravisthemes.com/jackcerra/wp-content/uploads/2023/03/contact-banner1-300x183.jpg 300w
                                          "
                                            sizes="(max-width: 740px) 100vw, 740px"
                                          />
                                        </div>
                                        <div className="pxl-item--holder">
                                          <div className="pxl-item--icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="45"
                                              height="41"
                                              viewBox="0 0 45 41"
                                              fill="none"
                                            >
                                              <g clip-path="url(#clip0_10_2768)">
                                                <path
                                                  d="M39.059 39.6279C38.6406 40.0309 38.0816 40.2557 37.4992 40.2545H2.99922C2.4168 40.2557 1.85664 40.0309 1.43945 39.6279L17.1438 25.3455L17.7063 25.8591C19.1465 27.1637 21.3496 27.1637 22.791 25.8591L23.3535 25.3455L39.059 39.6279Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M39.75 15.0279V38.0182C39.7523 38.6262 39.5027 39.2086 39.0598 39.6279L23.3555 25.3455L34.418 15.2818C35.1703 15.5311 35.9578 15.658 36.7512 15.6545C37.7824 15.6557 38.8043 15.4426 39.75 15.0279Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M37.5 15.6149V38.0182C37.5 38.0776 37.4871 38.1347 37.4824 38.1929L39.0574 39.6279C39.5016 39.2086 39.7523 38.6262 39.75 38.0182V15.0279C39.0363 15.3412 38.277 15.5392 37.5 15.6149Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M17.1445 25.3454L1.44025 39.6279C0.997282 39.2086 0.747673 38.6262 0.750016 38.0182V12.6727C0.747673 12.0647 0.997282 11.4823 1.44025 11.063L17.1445 25.3454Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M8.66955 10.4364H3.75002V8.19999C3.74767 7.59198 3.99611 7.00959 4.44025 6.59027L8.66955 10.4364Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M32.7742 1.88576C31.2578 2.82341 30.1293 4.27005 29.5937 5.96363H11.6699L7.43945 2.11755C7.85781 1.71571 8.4168 1.49091 8.99922 1.49207H31.4992C31.9551 1.49207 32.3992 1.62835 32.7742 1.88576Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M31.4992 1.49091H29.2492C29.7051 1.49091 30.1492 1.62835 30.5242 1.88576C29.0078 2.82341 27.8793 4.27005 27.3438 5.96363H29.5937C30.1293 4.27005 31.2578 2.82341 32.7742 1.88576C32.3992 1.62835 31.9551 1.49207 31.4992 1.49091Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M11.6707 5.96363H6.75002V3.72727C6.74767 3.11926 6.99611 2.53687 7.44025 2.11755L11.6707 5.96363Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M29.5937 10.4363H27.3437C28.0703 12.7298 29.8668 14.534 32.166 15.2818L21.1035 25.3454L20.541 25.8591C20.1332 26.2248 19.6492 26.4962 19.123 26.6535C20.4004 27.0565 21.7973 26.7536 22.791 25.8591L23.3535 25.3454L34.416 15.2818C32.1168 14.534 30.3215 12.7298 29.5937 10.4363Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M29.2504 8.19999C29.2492 8.95942 29.3652 9.71303 29.5949 10.4364H8.66992L4.43945 6.58911C4.85781 6.18726 5.4168 5.96246 5.99922 5.96246H29.5938C29.3652 6.68695 29.2492 7.44173 29.2504 8.19999Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M29.5954 5.96362H27.3454C26.8849 7.41959 26.8849 8.98038 27.3454 10.4363H29.5954C29.136 8.98038 29.136 7.41959 29.5954 5.96362Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M36.7504 0.745483C36.3684 0.746648 35.9863 0.776932 35.609 0.836336C38.9148 1.32438 41.4977 3.92997 41.9359 7.22395C42.3754 10.5179 40.5648 13.7024 37.5004 15.0291C36.8922 15.2923 36.2512 15.4729 35.5938 15.5661C35.977 15.6255 36.3637 15.6546 36.7504 15.6546C37.7828 15.6557 38.8047 15.4426 39.7504 15.0279C43.0012 13.6139 44.8082 10.1277 44.084 6.67651C43.3586 3.22412 40.2988 0.750142 36.7504 0.745483Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M3 40.2545H5.25C4.00664 40.2545 3 39.2528 3 38.0182V12.6727C3 11.4369 4.00664 10.4364 5.25 10.4364H6V8.2C6 6.96534 7.00781 5.96363 8.25 5.96363H9V3.72727C9 2.49145 10.0066 1.49091 11.25 1.49091H9C7.75664 1.49091 6.75 2.49145 6.75 3.72727V5.96363H6C4.75781 5.96363 3.75 6.96534 3.75 8.2V10.4364H3C1.75781 10.4364 0.75 11.4381 0.75 12.6727V38.0182C0.75 39.2528 1.75781 40.2545 3 40.2545Z"
                                                  fill="white"
                                                ></path>
                                                <path
                                                  d="M1.5 12.6727H0V18.6364H1.5V12.6727Z"
                                                  fill="white"
                                                ></path>
                                                <path
                                                  d="M11.25 14.1636H9.75C9.33633 14.1636 9 13.8293 9 13.4182C9 13.007 9.33516 12.6727 9.75 12.6727H11.25C11.6648 12.6727 12 13.0059 12 13.4182C12 13.8293 11.6637 14.1636 11.25 14.1636Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M26.25 14.1636H14.25C13.8352 14.1636 13.5 13.8293 13.5 13.4182C13.5 13.007 13.8363 12.6727 14.25 12.6727H26.25C26.6648 12.6727 27 13.0059 27 13.4182C27 13.8293 26.6648 14.1636 26.25 14.1636Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M1.5 15.6545C1.5 16.0669 1.16367 16.4 0.75 16.4C0.336328 16.4 0 16.0657 0 15.6545C0 15.2434 0.336328 14.9091 0.75 14.9091C1.16367 14.9091 1.5 15.2434 1.5 15.6545Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M36.75 2.86435e-06C35.3543 -0.00116191 33.982 0.352929 32.7621 1.02617C32.3672 0.842134 31.9359 0.745457 31.5 0.745457H9C7.34297 0.745457 6 2.08029 6 3.72728V5.21818C4.34297 5.21818 3 6.55301 3 8.2V9.69091C1.34297 9.69091 0 11.0257 0 12.6727C0 13.0851 0.336328 13.4182 0.75 13.4182C1.16484 13.4182 1.5 13.0851 1.5 12.6727C1.50117 12.512 1.5293 12.3536 1.58203 12.2022L16.0336 25.3455L1.5832 38.4876C1.53047 38.3362 1.50234 38.1778 1.5 38.0182V18.6364C1.5 18.224 1.16367 17.8909 0.75 17.8909C0.336328 17.8909 0 18.224 0 18.6364V38.0182C0 39.6652 1.34297 41 3 41H37.5C39.157 41 40.5 39.6652 40.5 38.0182V15.495C43.8961 13.7734 45.6621 9.9623 44.7703 6.27813C43.8785 2.59395 40.5621 -0.00232668 36.75 2.86435e-06ZM31.1051 2.23637C30.2133 3.06801 29.5195 4.08719 29.073 5.21818H11.9613L8.71289 2.26549C8.80664 2.24685 8.90391 2.23637 9 2.23637H31.1051ZM7.5 3.72728C7.50234 3.56654 7.53047 3.40929 7.5832 3.25787L9.73945 5.21818H7.5V3.72728ZM28.643 6.70909C28.452 7.69333 28.452 8.70551 28.643 9.69091H8.96133L5.71289 6.73821C5.80781 6.71958 5.90391 6.71026 6 6.70909H28.643ZM4.5 8.2C4.50234 8.04043 4.53047 7.88202 4.5832 7.7306L6.73945 9.69091H4.5V8.2ZM29.073 11.1818C29.8184 13.0629 31.2328 14.6063 33.048 15.5171L22.848 24.7969L22.2867 25.3105C21.1312 26.3507 19.3711 26.3507 18.2156 25.3105L2.71289 11.2098C2.80781 11.1911 2.90391 11.1818 3 11.1818H29.073ZM3 39.5091C2.90391 39.5091 2.80781 39.4986 2.71406 39.48L17.1434 26.36L17.2008 26.4124C18.9316 27.9709 21.5684 27.9709 23.2992 26.4124L23.3555 26.3611L37.7848 39.4811C37.691 39.4998 37.5949 39.5091 37.4988 39.5091H3ZM39 38.0182C38.9977 38.1778 38.9695 38.3362 38.9168 38.4876L24.4676 25.3455L34.616 16.1123C36.0516 16.506 37.5691 16.4955 39 16.0832V38.0182ZM36.75 14.9091C33.0223 14.9091 30 11.9051 30 8.2C30 4.49486 33.0223 1.49091 36.75 1.49091C40.4777 1.49091 43.5 4.49486 43.5 8.2C43.4953 11.9028 40.4754 14.9044 36.75 14.9091Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M37.038 3.03888C36.7579 2.92357 36.4356 2.98763 36.22 3.20079L34.72 4.6917C34.4364 4.98406 34.4399 5.4488 34.7294 5.7365C35.0188 6.0242 35.4864 6.02769 35.7805 5.74582L36.0009 5.52684V12.6727C36.0009 13.085 36.3372 13.4182 36.7509 13.4182C37.1645 13.4182 37.5009 13.085 37.5009 12.6727V3.72727C37.5009 3.42559 37.318 3.1542 37.038 3.03888Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_10_2768">
                                                  <rect
                                                    width="45"
                                                    height="41"
                                                    fill="white"
                                                  ></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </div>
                                          <h5 className="pxl-item--title">
                                            Send Us Mail
                                          </h5>
                                          <div className="pxl-item--content">
                                            info@calcinsights.com <br />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-3558e5a pxl-column-none"
                              data-id="3558e5a"
                              data-element_type="column"
                            >
                              <div
                                className="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  className="elementor-element elementor-element-90d4434 elementor-widget elementor-widget-pxl_image_box"
                                  data-id="90d4434"
                                  data-element_type="widget"
                                  data-widget_type="pxl_image_box.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      className="pxl-image-box pxl-image-box1"
                                      data-wow-delay="ms"
                                    >
                                      <div className="pxl-banner-inner">
                                        <div className="pxl-item--image">
                                          <img
                                            width="740"
                                            height="452"
                                            src="assets/wp-content/uploads/2023/03/contact-banner3.jpg"
                                            className="attachment-full"
                                            alt=""
                                            decoding="async"
                                            srcset="
                                            https://demo.bravisthemes.com/jackcerra/wp-content/uploads/2023/03/contact-banner3.jpg         740w,
                                            https://demo.bravisthemes.com/jackcerra/wp-content/uploads/2023/03/contact-banner3-300x183.jpg 300w
                                          "
                                            sizes="(max-width: 740px) 100vw, 740px"
                                          />
                                        </div>
                                        <div className="pxl-item--holder">
                                          <div className="pxl-item--icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="41"
                                              height="41"
                                              viewBox="0 0 41 41"
                                              fill="none"
                                            >
                                              <g clip-path="url(#clip0_10_2835)">
                                                <path
                                                  d="M19.3782 0.718536C11.891 1.14451 5.28662 5.75973 2.31329 12.6436C-0.661117 19.5264 0.50473 27.4992 5.32719 33.2418C5.53324 33.482 5.54605 33.8343 5.35602 34.0895L1.50403 39.2236C1.34922 39.4307 1.3236 39.7082 1.43997 39.9389C1.55527 40.1705 1.79229 40.3168 2.05065 40.3168H20.4992C27.7078 40.3093 34.3442 36.3891 37.8278 30.0785C41.3115 23.768 41.0937 16.062 37.2577 9.95966C33.4228 3.8562 26.5751 0.31712 19.3782 0.718536Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M19.4869 0.716413C30.207 1.28651 38.5227 10.2906 38.2419 21.021C37.9611 31.7525 29.1842 40.3083 18.4492 40.3168H20.4991C27.7087 40.3307 34.3568 36.4286 37.8597 30.1266C41.3615 23.8246 41.1651 16.1186 37.3451 10.0045C33.5262 3.89038 26.6859 0.333146 19.4869 0.716413Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M32.1182 15.7193C32.1182 27.3348 20.5025 36.9005 20.5025 36.9005C20.5025 36.9005 8.88672 27.3348 8.88672 15.7193C8.88672 9.30415 14.0871 4.10388 20.5025 4.10388C26.9178 4.10388 32.1182 9.30522 32.1182 15.7193Z"
                                                  fill="white"
                                                ></path>
                                                <path
                                                  d="M20.5015 4.10388C20.1556 4.10388 19.8182 4.1263 19.4766 4.15619C25.4691 4.68465 30.0663 9.70343 30.0674 15.7193C30.0674 25.2113 22.3122 33.3326 19.4766 35.9845C20.1107 36.577 20.5015 36.9005 20.5015 36.9005C20.5015 36.9005 32.1172 27.3348 32.1172 15.7193C32.1183 9.30522 26.9168 4.10388 20.5015 4.10388Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M25.9637 15.7193C25.9637 18.7385 23.5167 21.1854 20.4975 21.1854C17.4782 21.1854 15.0312 18.7385 15.0312 15.7193C15.0312 12.7002 17.4782 10.2532 20.4975 10.2532C23.5167 10.2532 25.9637 12.7012 25.9637 15.7193Z"
                                                  fill="#FFEEEF"
                                                ></path>
                                                <path
                                                  d="M3.55493 39.2236L7.40586 34.0884C7.59589 33.8343 7.58308 33.482 7.37703 33.2407C2.55564 27.4992 1.38872 19.5264 4.36206 12.6425C7.33646 5.75865 13.9408 1.14343 21.428 0.717455H21.4878C20.828 0.685427 20.1618 0.683292 19.4871 0.717455C19.4508 0.717455 19.4145 0.717455 19.3782 0.717455C11.891 1.14343 5.28662 5.75865 2.31329 12.6425C-0.661117 19.5264 0.50473 27.4992 5.32719 33.2407C5.53324 33.482 5.54605 33.8333 5.35602 34.0884L1.50403 39.2236C1.34922 39.4307 1.3236 39.7082 1.43997 39.9388C1.55527 40.1705 1.79229 40.3168 2.05065 40.3168H4.10049C3.84212 40.3168 3.60511 40.1705 3.48981 39.9388C3.37344 39.7082 3.39906 39.4317 3.55493 39.2236Z"
                                                  fill="white"
                                                ></path>
                                                <path
                                                  d="M0.888264 14.6037L2.19504 15.0019C1.69859 16.6908 1.41994 18.4363 1.36656 20.1957L0 20.1744C0.0565841 18.2869 0.354451 16.4143 0.888264 14.6037Z"
                                                  fill="white"
                                                ></path>
                                                <path
                                                  d="M0.959923 18.146C0.581984 18.1481 0.275576 17.8428 0.27344 17.4659C0.272373 17.0891 0.576646 16.7816 0.953517 16.7794H0.960991C1.33786 16.7794 1.64427 17.0848 1.64427 17.4627C1.64214 17.8396 1.33679 18.146 0.959923 18.146Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M34.5456 5.57291C30.462 1.70287 24.9552 -0.302074 19.3394 0.0374217C17.2554 0.176209 15.2024 0.62033 13.2476 1.35377C11.2949 2.09361 9.47139 3.13772 7.84433 4.4466C4.55071 7.0494 2.12613 10.5917 0.889819 14.6037C0.838573 14.7809 0.859926 14.972 0.950674 15.1332C1.04035 15.2945 1.19089 15.414 1.36811 15.4631C1.72363 15.5635 2.0941 15.3574 2.19659 15.0019C3.35176 11.2557 5.61726 7.94832 8.69309 5.5174C11.7593 3.0758 15.5035 1.63882 19.4163 1.40181C24.6509 1.10395 29.7787 2.96904 33.6008 6.55831C37.4229 10.1486 39.603 15.1493 39.6329 20.3922C39.6862 25.4921 37.6908 30.401 34.094 34.0169C30.4983 37.6339 25.6011 39.657 20.501 39.6335H2.05139L5.90339 34.4973C6.28453 33.9891 6.26318 33.2845 5.85107 32.8009C2.88735 29.2757 1.29552 24.7993 1.36918 20.1947C1.37239 20.0132 1.30299 19.8381 1.17701 19.7079C1.05103 19.5777 0.878075 19.5029 0.696579 19.5008C0.320775 19.4986 0.0111637 19.7976 0.00262274 20.1744C-0.0763815 25.1089 1.63182 29.9045 4.81121 33.6795L0.960282 38.8147C0.649603 39.2289 0.599425 39.783 0.8311 40.2463C1.06277 40.7097 1.53573 41.0011 2.05353 41.0011H20.5021C28.9075 41.0011 36.4599 35.8703 39.5581 28.0576C42.6532 20.2438 40.6674 11.3315 34.5456 5.57291Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M20.4983 37.5837C20.6573 37.5837 20.8111 37.5282 20.9328 37.4278C21.4175 37.0296 32.7973 27.5216 32.7973 15.7193C32.7973 8.92726 27.2916 3.42059 20.4983 3.42059C13.705 3.42059 8.19922 8.92726 8.19922 15.7193C8.19922 27.5216 19.579 37.0296 20.0637 37.4278C20.1865 37.5293 20.3402 37.5848 20.4983 37.5837ZM9.56685 15.7193C9.56685 9.68205 14.4608 4.78712 20.4993 4.78712C26.5378 4.78712 31.4318 9.68098 31.4318 15.7193C31.4318 25.6085 22.5823 34.1268 20.4993 35.994C18.4153 34.1268 9.56685 25.6085 9.56685 15.7193Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                                <path
                                                  d="M26.6506 15.7193C26.6506 12.3233 23.8972 9.56995 20.5011 9.56995C17.105 9.56995 14.3516 12.3233 14.3516 15.7193C14.3516 19.1153 17.105 21.8687 20.5011 21.8687C23.8951 21.8655 26.6463 19.1143 26.6506 15.7193ZM15.7171 15.7193C15.7171 13.0781 17.8587 10.9365 20.5 10.9365C23.1413 10.9365 25.283 13.0781 25.283 15.7193C25.283 18.3605 23.1413 20.5021 20.5 20.5021C17.8598 20.5 15.7203 18.3595 15.7171 15.7193Z"
                                                  fill="#DF0A0A"
                                                ></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_10_2835">
                                                  <rect
                                                    width="41"
                                                    height="41"
                                                    fill="white"
                                                  ></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </div>
                                          <h5 className="pxl-item--title">
                                            Visit Our Office
                                          </h5>
                                          <div className="pxl-item--content">
                                            New Delhi, India
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-41e5ad3 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="41e5ad3"
                          data-element_type="section"
                        >
                          <div
                            className="elementor-container elementor-column-gap-extended"
                          >
                            <div
                              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffef4f7 pxl-column-none"
                              data-id="ffef4f7"
                              data-element_type="column"
                            >
                              <div
                                className="elementor-widget-wrap elementor-element-populated"
                              >
                                <section
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-fd85c86 elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                  data-id="fd85c86"
                                  data-element_type="section"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div
                                    className="elementor-container elementor-column-gap-extended"
                                  >
                                    <div
                                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77b09bc pxl-column-none"
                                      data-id="77b09bc"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-269bf31b elementor-widget elementor-widget-pxl_heading"
                                          data-id="269bf31b"
                                          data-element_type="widget"
                                          data-widget_type="pxl_heading.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              id="pxl-pxl_heading-269bf31b-1991"
                                              className="pxl-heading px-sub-title-under-style"
                                            >
                                              <div className="pxl-heading--inner">
                                                <h3
                                                  className="pxl-item--title"
                                                  data-wow-delay="ms"
                                                >
                                                  Drop Us A Line
                                                </h3>
                                                <div
                                                  className="pxl-item--subtitle px-sub-title-under"
                                                  data-wow-delay="ms"
                                                >
                                                  <span className="pxl-item--subtext">
                                                    Calc Insights will arrange
                                                    your first business
                                                    consultation totally free of
                                                    cost
                                                  </span>
                                                </div>
                                                <div className="px-divider--wrap">
                                                  <div
                                                    className="px-title--divider px-title--divider1"
                                                  >
                                                    <span></span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-element elementor-element-7ca8de1 elementor-widget__width-auto elementor-widget elementor-widget-pxl_contact_form"
                                          data-id="7ca8de1"
                                          data-element_type="widget"
                                          data-widget_type="pxl_contact_form.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              className="pxl-contact-form pxl-contact-form1 btn-w-auto"
                                              data-wow-delay="ms"
                                            >
                                              <div
                                                className="wpcf7 no-js"
                                                id="wpcf7-f1356-p56-o1"
                                                lang="en-US"
                                                dir="ltr"
                                              >
                                                <div
                                                  className="screen-reader-response"
                                                >
                                                  <p
                                                    role="status"
                                                    aria-live="polite"
                                                    aria-atomic="true"
                                                  ></p>
                                                  <ul></ul>
                                                </div>
                                                <form
                                                  className="wpcf7-form init"
                                                  aria-label="Contact form"
                                                  novalidate="novalidate"
                                                  data-status="init"
                                                  onSubmit={(e) => handleSubmit(e)}
                                                >
                                                  <div style={{ display: "none" }}>
                                                    <input
                                                      type="hidden"
                                                      name="_wpcf7"
                                                      value="1356"
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="_wpcf7_version"
                                                      value="5.7.5.1"
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="_wpcf7_locale"
                                                      value="en_US"
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="_wpcf7_unit_tag"
                                                      value="wpcf7-f1356-p56-o1"
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="_wpcf7_container_post"
                                                      value="56"
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="_wpcf7_posted_data_hash"
                                                      value=""
                                                    />
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-name"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Name*"
                                                              type="text"
                                                              name="your-name"
                                                              value={name}
                                                              onChange={(e)=>{setName(e.target.value)}}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-phone"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Phone No*"
                                                              type="text"
                                                              name="your-phone"
                                                              value={phone}
                                                              onChange={(e)=>{setPhone(e.target.value)}}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-email"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Company's mail*"
                                                              type="email"
                                                              name="your-email"
                                                              value={email}
                                                              onChange={(e)=>{setEmail(e.target.value)}}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-lg-6 col-md-6 col-sm-12"
                                                    >
                                                      <div className="pxl--item">
                                                        <p>
                                                          <span
                                                            className="wpcf7-form-control-wrap"
                                                            data-name="your-subject"
                                                          ><input
                                                              size="40"
                                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                              aria-required="true"
                                                              aria-invalid="false"
                                                              placeholder="Subject*"
                                                              type="text"
                                                              name="your-subject"
                                                              value={subject}
                                                              onChange={(e)=>{setSubject(e.target.value)}}
                                                            /></span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="pxl--item">
                                                    <p>
                                                      <span
                                                        className="wpcf7-form-control-wrap"
                                                        data-name="your-message"
                                                      >
                                                        <textarea
                                                          cols="40"
                                                          rows="10"
                                                          className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                          aria-required="true"
                                                          aria-invalid="false"
                                                          placeholder="Message*"
                                                          name="your-message"
                                                          value={message}
                                                          onChange={(e)=>{setMessage(e.target.value)}}
                                                        ></textarea>
                                                      </span>
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="pxl--item pxl-text-center"
                                                  >
                                                    <SubmitPopUp open={open} setOpen={setOpen} />
                                                  </div>
                                                  <div
                                                    className="wpcf7-response-output"
                                                    aria-hidden="true"
                                                  ></div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
        <a className="pxl-scroll-top" href="#"
        ><i className="caseicon-long-arrow-right-three"></i
        ></a>
        <div className="pxl-cursor pxl-js-cursor style-default">
          <div className="pxl-cursor-wrapper">
            <div className="pxl-cursor--follower pxl-js-follower"></div>
            <div className="pxl-cursor--label pxl-js-label"></div>
            <div className="pxl-cursor--drap pxl-js-drap"></div>
            <div className="pxl-cursor--icon pxl-js-icon"></div>
          </div>
        </div>
        <div className="pxl-cursor-section"></div>
        <div id="pxl-page-popup" className="">
          <div className="pxl-popup-overlay"></div>
          <div className="pxl-page-popup-content"></div>
        </div>
      </div>




    </div>

  )
}

export default ContactUs