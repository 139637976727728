import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import '../css/home.css'
import { useLocation } from 'react-router-dom';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

const SubmitPopUp = ({ open, setOpen }) => {
    const location = useLocation();

    return (
        <>
            <Button variant="contained" type="submit" className="wpcf7-submit btn btn-effect2">
                Submit
            </Button>
            <Dialog open={open} onClose={()=>{setOpen(false)}} sx={{
                '& .MuiDialog-paperWidthSm': { overflow: "hidden !important" },
            }}>
                <div style={{ backgroundColor: "#1c2539",paddingBottom:"40px" }}>
                    <DialogTitle id="responsive-dialog-title">
                        {"Use Google's location service?"}
                    </DialogTitle>
                    <DialogContent> 
                        <DialogContentText color="white" sx={{textAlign:"center"}}>
                            Thank you for your interest.
                           <Typography> We are reviewing your profile and we will get back to you shortly in your Email.</Typography>

                        </DialogContentText>
                    </DialogContent>
                </div>

            </Dialog>
        </>
    )
}

export default SubmitPopUp
