
import React from 'react'

function AboutUs() {
  return (
    <div
      className="page-template-default page page-id-50 theme-jackcerra woocommerce-no-js pxl-redux-page body-default-font heading-default-font bd-px-header--default site-color-gradient woocommerce-layout-grid elementor-default elementor-kit-6 elementor-page elementor-page-50"
    >

      {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-dark-grayscale">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 0.49803921568627" />
            <feFuncG type="table" tableValues="0 0.49803921568627" />
            <feFuncB type="table" tableValues="0 0.49803921568627" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-grayscale">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 1" />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB type="table" tableValues="0 1" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-purple-yellow">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR
              type="table"
              tableValues="0.54901960784314 0.98823529411765"
            />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB
              type="table"
              tableValues="0.71764705882353 0.25490196078431"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-blue-red">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 1" />
            <feFuncG type="table" tableValues="0 0.27843137254902" />
            <feFuncB
              type="table"
              tableValues="0.5921568627451 0.27843137254902"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-midnight">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 0" />
            <feFuncG type="table" tableValues="0 0.64705882352941" />
            <feFuncB type="table" tableValues="0 1" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-magenta-yellow">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0.78039215686275 1" />
            <feFuncG type="table" tableValues="0 0.94901960784314" />
            <feFuncB
              type="table"
              tableValues="0.35294117647059 0.47058823529412"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-purple-green">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR
              type="table"
              tableValues="0.65098039215686 0.40392156862745"
            />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB type="table" tableValues="0.44705882352941 0.4" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
    >
      <defs>
        <filter id="wp-duotone-blue-orange">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0.098039215686275 1" />
            <feFuncG type="table" tableValues="0 0.66274509803922" />
            <feFuncB
              type="table"
              tableValues="0.84705882352941 0.41960784313725"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs>
    </svg> */}
      <div id="pxl-wapper" className="pxl-wapper">
        {/* <div
        id="pxl-loadding"
        className="pxl-loader pxl-loader-gradient style-default"
      >
        <div className="pxl-loader-effect">
          <div className="pxl-circle-1"></div>
          <div className="pxl-circle-2"></div>
        </div>
      </div> */}
        <div id="pxl-page-title-elementor">
          <div
            data-elementor-type="wp-post"
            data-elementor-id="94"
            className="elementor elementor-94"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-fa5168c elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
              data-id="fa5168c"
              data-element_type="section"
              data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-439d52b pxl-column-none"
                  data-id="439d52b"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-4055df0 pxl-page--title elementor-widget elementor-widget-pxl_heading"
                      data-id="4055df0"
                      data-element_type="widget"
                      data-widget_type="pxl_heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div
                          id="pxl-pxl_heading-4055df0-9770"
                          className="pxl-heading px-sub-title-default-style"
                        >
                          <div className="pxl-heading--inner">
                            <h1 className="pxl-item--title" data-wow-delay="ms">
                              About Us
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-937e8b3 elementor-widget elementor-widget-pxl_breadcrumb"
                      data-id="937e8b3"
                      data-element_type="widget"
                      data-widget_type="pxl_breadcrumb.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="pxl-breadcrumb-wrap">
                          <ul className="pxl-breadcrumb">
                            <li>
                              <a className="breadcrumb-entry" href='/'
                              >Home</a
                              >
                            </li>
                            <li>
                              <span className="breadcrumb-entry">About Us</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div id="pxl-main">
          <div className="elementor-container">
            <div className="row pxl-content-wrap no-sidebar">
              <div
                id="pxl-content-area"
                className="pxl-content-area pxl-content-page col-12"
              >
                <main id="pxl-content-main">
                  <article
                    id="pxl-post-50"
                    className="post-50 page type-page status-publish hentry"
                  >
                    <div className="pxl-entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="50"
                        className="elementor elementor-50"
                      >
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-f9ab25d elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                          data-id="f9ab25d"
                          data-element_type="section"
                        >
                          <div
                            className="elementor-container elementor-column-gap-extended"
                          >
                            <div
                              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5873d53 pxl-column-none"
                              data-id="5873d53"
                              data-element_type="column"
                            >
                              <div
                                className="elementor-widget-wrap elementor-element-populated"
                              >
                                <section
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-024352c elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                  data-id="024352c"
                                  data-element_type="section"
                                >
                                  <div
                                    className="elementor-container elementor-column-gap-extended"
                                  >
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7064673 pxl-column-none"
                                      data-id="7064673"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-5cfc6d5 elementor-widget elementor-widget-pxl_heading"
                                          data-id="5cfc6d5"
                                          data-element_type="widget"
                                          data-widget_type="pxl_heading.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              id="pxl-pxl_heading-5cfc6d5-3539"
                                              className="pxl-heading px-sub-title-default-style"
                                            >
                                              <div className="pxl-heading--inner">
                                                <div
                                                  className="pxl-item--subtitle px-sub-title-default"
                                                  data-wow-delay="ms"
                                                >
                                                  <span className="pxl-item--subtext">
                                                    ABOUT COMPANY
                                                    <span
                                                      className="pxl-item--subdivider"
                                                    ></span>
                                                  </span>
                                                </div>
                                                <h3
                                                  className="pxl-item--title"
                                                  data-wow-delay="ms"
                                                >
                                                  Welcoming you to
                                                  <span
                                                    className="pxl-title--highlight"
                                                  >
                                                    <span
                                                      className="pxl-item--text active"
                                                    > Consulting </span
                                                    >
                                                  </span>
                                                  Agency
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-54ebb1c pxl-column-none"
                                      data-id="54ebb1c"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-9817bc2 elementor-widget elementor-widget-pxl_text_editor"
                                          data-id="9817bc2"
                                          data-element_type="widget"
                                          data-widget_type="pxl_text_editor.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="pxl-text-editor">
                                              <div
                                                className="pxl-item--inner"
                                                data-wow-delay="ms"
                                                style={{ textAlign: 'left' }}
                                              >
                                                We offer comprehensive end-to-end solutions for market research services across the globe as
                                                an independent market research company. We cover a wide range of services, including B2B
                                                market research and consumer research. In a short period, we have emerged as one of the
                                                leading market research service providers in the region.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-element elementor-element-fba9d94 elementor-widget elementor-widget-pxl_text_editor"
                                          data-id="fba9d94"
                                          data-element_type="widget"
                                          data-widget_type="pxl_text_editor.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="pxl-text-editor">
                                              <div
                                                className="pxl-item--inner"
                                                data-wow-delay="ms"
                                                style={{ textAlign: 'left' }}
                                              >
                                                Our focus is on connecting organizations with market research audiences worldwide to support
                                                informed, data-driven strategies and identify growth opportunities. Our global presence
                                                enhances our overall capabilities to offer customized research solutions.
                                                We believe in full transparency and strive to be your data collection and insights partner,
                                                providing you with the highest level of service possible.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </section>
                                {/* <section
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-024352c elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                                  data-id="024352c"
                                  data-element_type="section"
                                >
                                  <div
                                    className="elementor-container elementor-column-gap-extended"
                                  >
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7064673 pxl-column-none"
                                      data-id="7064673"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-5cfc6d5 elementor-widget elementor-widget-pxl_heading"
                                          data-id="5cfc6d5"
                                          data-element_type="widget"
                                          data-widget_type="pxl_heading.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div
                                              id="pxl-pxl_heading-5cfc6d5-3539"
                                              className="pxl-heading px-sub-title-default-style"
                                            >
                                              <div className="pxl-heading--inner">
                                                <div
                                                  className="pxl-item--subtitle px-sub-title-default"
                                                  data-wow-delay="ms"
                                                >

                                                  <p
                                                    className="pxl-item--title"
                                                    data-wow-delay="ms"
                                                    style={{fontSize:"28px !important"}}
                                                  >
                                                    <span
                                                      className="pxl-title--highlight"
                                                    >
                                                      <span
                                                        className="pxl-item--text active"
                                                      > Game Plan </span
                                                      >
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-54ebb1c pxl-column-none"
                                      data-id="54ebb1c"
                                      data-element_type="column"
                                    >
                                      <div
                                        className="elementor-widget-wrap elementor-element-populated"
                                      >
                                        <div
                                          className="elementor-element elementor-element-9817bc2 elementor-widget elementor-widget-pxl_text_editor"
                                          data-id="9817bc2"
                                          data-element_type="widget"
                                          data-widget_type="pxl_text_editor.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="pxl-text-editor">
                                              <div
                                                className="pxl-item--inner"
                                                data-wow-delay="ms"
                                                style={{ textAlign: 'left' }}
                                              >
                                                Our game plan in market research involves a strategic approach to gathering and analysing
                                                data. We start by clearly defining research objectives and identifying the target audience.
                                                Then, we select the most appropriate research methods and tools, ensuring data accuracy
                                                and reliability. Our team of experts meticulously analyses the findings, extracting meaningful
                                                insights that inform decision-making and drive business growth. With our game plan, we
                                                provide actionable recommendations that help our clients stay ahead in the market and
                                                achieve their goals
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </section> */}
                                <div style={{padding:"15px",marginBottom:"30px"}}>
                                <p
                                  className="color-primary"
                                  style={{textAlign: 'left', paddingTop: "4px", marginBottom: "19px", textAlign: 'center',fontSize:"30px",fontWeight:700 }}
                                >
                                  Game Plan
                                </p>

                                <p style={{ textAlign: 'left' }}>

                                Our game plan in market research involves a strategic approach to gathering and analysing
data. We start by clearly defining research objectives and identifying the target audience.
Then, we select the most appropriate research methods and tools, ensuring data accuracy
and reliability. Our team of experts meticulously analyses the findings, extracting meaningful
insights that inform decision-making and drive business growth. With our game plan, we
provide actionable recommendations that help our clients stay ahead in the market and
achieve their goals.



                                </p>
                                </div>
                                <div style={{padding:"15px",marginBottom:"30px"}}>
                                <p
                                  className="color-primary"
                                  style={{textAlign: 'left', paddingTop: "4px", marginBottom: "19px", textAlign: 'center',fontSize:"30px",fontWeight:700 }}
                                >
                                  At our business, customer satisfaction and loyalty are paramount.
                                </p>

                                <p style={{ textAlign: 'left' }}>

                                  We provide personalised attention, and exceptional service, and continuously improve our
                                  offerings to exceed customer expectations. We are dedicated to fostering customer
                                  satisfaction and loyalty through our customer-centric approach.


                                </p>
                                </div>
                               

                                <div
                                  className="elementor-element elementor-element-b11baa2 elementor-widget elementor-widget-pxl_image"
                                  data-id="b11baa2"
                                  data-element_type="widget"
                                  data-widget_type="pxl_image.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div
                                      id="pxl_image-b11baa2-3060"
                                      className="pxl-image-single style-default wow skewIn"
                                      data-wow-delay="ms"
                                    >
                                      <div
                                        className="pxl-item--inner"
                                        data-wow-delay="120ms"
                                      >
                                        <div
                                          className="pxl-item--image"
                                          data-parallax-value=""
                                        >
                                          <img
                                            decoding="async"
                                            width="1170"
                                            height="640"
                                            src="assets/wp-content/uploads/2023/03/about-banner1.jpg"
                                            className="no-lazyload attachment-full"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
        <a className="pxl-scroll-top" href="#"
        ><i className="caseicon-long-arrow-right-three"></i
        ></a>
        <div className="pxl-cursor pxl-js-cursor style-default">
          <div className="pxl-cursor-wrapper">
            <div className="pxl-cursor--follower pxl-js-follower"></div>
            <div className="pxl-cursor--label pxl-js-label"></div>
            <div className="pxl-cursor--drap pxl-js-drap"></div>
            <div className="pxl-cursor--icon pxl-js-icon"></div>
          </div>
        </div>
        <div className="pxl-cursor-section"></div>
        <div id="pxl-page-popup" className="">
          <div className="pxl-popup-overlay"></div>
          <div className="pxl-page-popup-content"></div>
        </div>
      </div>






    </div>

  )
}

export default AboutUs